export const ImportOverview = () => {
    return (
        <div>
            <div className="font-bold text-lg">Overview </div>
            <div className="my-3">An Importer Exporter Code (IEC) is a key business identification number that is mandatory for export from India or Import to India. No export or import shall be made by any person without obtaining an IEC. For services exports, however, IEC shall not be necessary except when the service provider is taking benefits under the Foreign Trade Policy. An individual or a business entity needs a 10-digit code to import or export products or services. This code is known as Import Export Code or IEC and it is issued by DGFT, Ministry of Commerce and Industries, Govt. of India.</div>
        </div>
    )
}

export const ImportBenefit = () => {

    const importBenefit = [
        "The IEC registration helps traders including online e-commerce operators open the doors to venture into the global market",
        "Businesses can avail of government schemes like the Merchandise Export from India Scheme (MEIS), the Service Export from India Scheme (SEIS), and others from customs and export promotion",
        "No compliance requirements post-registration and hence maintaining the business is relatively simple",
        "The process of obtaining an IEC Code is not tedious and can be obtained with minimal, basic documents."
    ];


    return (
        <div>
            <div className="font-bold text-lg mt-5">Benefits of the IEC Code</div>
            <div className="ml-8 mt-4">
                <ul style={{ listStyle: 'number' }}>
                    {importBenefit?.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
        </div>
    )
}


export const ImportRequirement = () => {

    const content = ['IEC can be obtained by any individual, company, partnership firm, Limited Liability Partnership (LLP), or any other type of entity engaged in import or export activities.', 'Whether you are an exporter or importer, having an IEC is mandatory for all such transactions. Even if a business is not actively engaged in import or export activities,', 'but it plans to do so in the future, it still needs to obtain an IEC beforehand.', 'Prerequisites for Applying for IEC', 'To apply for IEC, the following prerequisites are required:']

    const iecRequirements = [
        "A valid Permanent Account Number (PAN) card issued by the Income Tax Department of India is mandatory",
        "The applicant must have a bank account in the name of the firm or individual, as IEC is issued in the name of the applicant",
        "The business entity should be registered under the Registrar of Companies (ROC)",
        "The applicant needs to submit the relevant documents such as identity proof, address proof, and details of the business entity to the DGFT office."
    ];


    return (
        <>
            <div className="font-bold text-lg mt-5">Nature of the Firm Obtaining an IEC</div>
            <div>
                {content?.map(item => <div className="my-4">{item}</div>)}
                <div className="ml-8 mt-4">
                    <ul style={{ listStyle: 'number' }}>
                        {iecRequirements?.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    )
}

export const ImportTypes = () => {
    return (
        <div>
            <div className="font-bold text-lg">Eligibility to Build an IEC Code</div>
            <div className="my-3">Any type of business registration: sole proprietorship, partnership, private limited companies, charitable organizations dealing with imports and exports in India can register for import export code, irrespective of the size. However, an IEC Number is not required for the import/export of goods for personal use, which is not connected with trade, manufacturing, or agriculture. There is no need for a business setup.</div>
        </div>
    )
}

export const ImportDocuments = () => {

    const iecDocuments = [
        "PAN CARD of the company (not applicable in the case of the proprietor)",
        "Applicant’s PAN and Aadhar card (driving license/Aadhaar/voter ID)",
        "Incorporation certificate/partnership deed",
        "Address proof (electricity bill/rent agreement)",
        "Copy of canceled cheque leaf/banker certificate of the current account in the name of the company."
    ];

    return (
        <div>
            <div className="font-bold text-lg">Documents Required for Import Export Code Registration</div>
            <div className="my-3 font-bold text-lg">import export code
            </div>
            <div className="my-3">The documents required for an IEC License registration are listed below.</div>
            <div className="ml-8 mt-4">
                <ul style={{ listStyle: 'disc' }}>
                    {iecDocuments?.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
        </div>
    )
}