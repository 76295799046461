export const TrademarkHearingOverview = () => {

    const trademarkHearingScenarios = [
        {
            title: "Trademark Application Issues",
            content: "If a trademark application encounters objections from the trademark office, such as issues related to distinctiveness, similarity to existing marks, or incorrect documentation, a hearing may be scheduled to address these objections."
        },
        {
            title: "Trademark Opposition",
            content: "When a third party believes that a trademark application should not be granted because it conflicts with their existing trademark rights, they can file an opposition. A hearing allows both parties to present their arguments and evidence before a decision is made."
        },
        {
            title: "Trademark Cancellation or Revocation",
            content: "If someone believes that a registered trademark should not have been granted due to reasons like non-use, abandonment, or genericness, they can request a cancellation or revocation. A hearing is conducted to determine the validity of these claims."
        },
        {
            title: "Trademark Infringement",
            content: "In cases of alleged trademark infringement, where one party claims that another is using a confusingly similar mark, a hearing can be held to determine if the use indeed constitutes infringement."
        },
        {
            title: "Trademark Disputes",
            content: "Trademark disputes can arise over ownership, licensing, or other matters. A hearing can be used to resolve these disputes and clarify the rights of the parties involved."
        }
    ];

    return (
        <div>
            <div className="my-5 font-bold text-lg">Trademark Hearings may occur: </div>
            <div className="my-5">
                {trademarkHearingScenarios?.map((item, index) => (
                    <div key={index}>
                        <div className="font-bold text-lg">{item.title}</div>
                        <div className="my-4">{item.content}</div>
                    </div>
                ))}</div>
        </div>
    )
}

export const TrademarkHearingBenefit = () => {

    const trademarkHearingBenefits = [
        {
            title: "Resolution of Disputes",
            content: "Trademark hearings provide a formal and structured process for resolving disputes related to trademark rights, infringement, and registration. They help clarify and determine the rightful owner of a trademark and settle disagreements between parties."
        },
        {
            title: "Trademark Protection",
            content: "Through hearings, trademark owners can defend their registered trademarks against potential infringement or unauthorized use. This helps maintain the distinctiveness and value of their brands and prevents confusion among consumers."
        },
        {
            title: "Clearing the Trademark Register",
            content: "Trademark offices conduct hearings to ensure that the trademark register remains accurate and up-to-date. This helps prevent the registration of confusingly similar marks and maintains the integrity of the trademark system."
        },
        {
            title: "Preventing Confusion",
            content: "Trademark hearings help prevent consumer confusion by addressing cases where similar trademarks could lead to misunderstanding or deception in the marketplace. Resolving such issues promotes fair competition and protects consumers from making uninformed purchasing decisions."
        },
        {
            title: "Ensuring Fair Competition",
            content: "By resolving trademark disputes, hearings promote fair competition in the marketplace. Trademark owners can protect their investment in branding and innovation, encouraging them to continue developing quality products and services."
        },
        {
            title: "Legal Precedent",
            content: "Decisions made during trademark hearings can establish legal precedent that serves as guidance for future trademark cases. This contributes to the development of trademark law and its consistent interpretation."
        },
        {
            title: "Public Interest",
            content: "Trademark hearings help safeguard the interests of consumers by ensuring that trademarks are not misleading or likely to cause confusion. This contributes to the overall transparency and trustworthiness of commercial transactions."
        },
        {
            title: "Encouraging Proper Trademark Use",
            content: "Trademark hearings encourage the proper use of trademarks by discouraging unauthorized use and infringement. This promotes responsible branding practices and discourages opportunistic attempts to benefit from others’ established trademarks."
        },
        {
            title: "Balancing Rights",
            content: "Trademark hearings provide a platform for considering the rights and interests of both trademark owners and potential challengers. A fair hearing allows for the presentation of evidence and arguments from all parties involved."
        },
        {
            title: "Legal Precedent",
            content: "Decisions made during trademark hearings can establish a legal precedent that serves as guidance for future trademark cases. This contributes to the development of trademark law and its consistent interpretation."
        }
    ];

    return (
        <div>
            <div className="my-5 font-bold text-lg">Some of the key benefits include:</div>
            <div className="my-5">
                {trademarkHearingBenefits?.map((item, index) => (
                    <div key={index}>
                        <div className="font-bold text-lg">{item.title}</div>
                        <div className="my-4">{item.content}</div>
                    </div>
                ))}
            </div>
            <div className="font-bold text-lg mt-8">Trademark Application Hearing:</div>
            <div> During the trademark application process, if the trademark examiner raises concerns or objections, the applicant may be required to attend a hearing to address these issues. This typically happens if the examiner believes the mark lacks distinctiveness, is too similar to existing trademarks, or doesn’t meet other registration criteria.</div>
        </div>
    )
}

export const TrademarkHearingRequirement = () => {

    const trademarkHearingApplicationErrors = [
        "Incorrect Name of the Trademark Applicant",
        "Incorrect Address on the Trademark Application",
        "Failure to file Trademark Form TM-48",
        "Filing of Incorrect Trademark Form",
        "Trademark filing under the Wrong Trademark Class",
        "The proposed Trademark already exists",
        "Trademark lacks distinctive character",
        "Vague Specifications of Goods and Services",
        "Deceptive Trademark",
        "User affidavit not attached"
    ]

    return (
        <div>
            <div className="font-bold text-xl my-5">Reasons For Trademark Objection</div>
            <div className="ml-8 mt-4">
                <ul style={{ listStyle: 'number' }}>
                    {trademarkHearingApplicationErrors?.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export const TrademarkHearingTypes = () => {

    const trademarkHearingTypes = [
        {
            "title": "Trademark Application Hearing",
            "content": "This type of hearing occurs during the trademark application process when the trademark office examines the application. If the examiner raises objections or concerns about the application, the applicant may be required to attend a hearing to address these issues."
        },
        {
            "title": "Trademark Opposition Hearing",
            "content": "If a third party believes that a published trademark application may cause confusion with their existing trademark, they can file an opposition. The opposition proceeding involves a hearing where both the applicant and the opponent present their arguments and evidence before the trademark office."
        },
        {
            "title": "Trademark Cancellation or Revocation Hearing",
            "content": "After a trademark has been registered, interested parties can initiate cancellation or revocation proceedings if they believe the registered mark should not have been granted or is no longer valid. A hearing is conducted to consider the evidence and arguments from all parties involved."
        },
        {
            "title": "Appeals Hearing",
            "content": "If an applicant or an opponent disagrees with a decision made by the trademark office or authority, they can file an appeal. An appeals hearing allows the party to present their case to a higher authority for reconsideration."
        }
    ]

    return (
        <div>
            <div className="my-5 text-lg"><b>Trademark hearing types:</b></div>
            <div className="my-5">
                {trademarkHearingTypes?.map((item, index) => (
                    <div key={index}>
                        <div className="font-bold text-lg">{item.title}</div>
                        <div className="my-4">{item.content}</div>
                    </div>
                ))}</div>
        </div>
    )
}

export const TrademarkHearingDocuments = () => {

    const trademarkDocumentRequired = ['A copy of the trademark application and all related documents filed with the trademark office.', 'Responses and correspondence exchanged with the trademark examiner during the application process.', 'Any evidence or arguments to address the examiner’s objections or concerns']

    return (
        <div>
            <div className="my-5 text-lg">Document Required <b>Trademark Application Hearing:</b></div>
            <div className="my-5">
                {trademarkDocumentRequired?.map((item, index) => (
                    <div className="ml-8 mt-4">
                        <ul style={{ listStyle: 'disc' }}>
                            <li key={index}>{item}</li>
                        </ul>
                    </div>
                ))}</div>
        </div>
    )
}