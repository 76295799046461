export const TrademarkOppositionOverview = () => {
  const copyrightOverviewResponse = [
    {
      title: "Protecting Existing Rights",
      content:
        "The primary benefit of filing a trademark opposition is to protect your existing trademark rights. If you believe that the registration of a similar or identical mark could cause confusion with your mark, opposing it helps prevent potential infringement and dilution.",
    },
    {
      title: "Preserving Market Position",
      content:
        "By opposing a potentially conflicting trademark, you can maintain your established market position and prevent confusion among consumers. This safeguards your brand's reputation and identity.",
    },
    {
      title: "Cost-Effective",
      content:
        "Opposing a trademark is often less costly and time-consuming than resorting to litigation after the mark is registered. It allows you to address the issue early in the registration process.",
    },
    {
      title: "Negotiation and Settlement",
      content:
        "The opposition process might provide an opportunity for the opposing party and the applicant to negotiate a settlement. This could involve coexistence agreements, licensing arrangements, or other mutually beneficial solutions.",
    },
    {
      title: "Maintaining Control",
      content:
        "Opposing a trademark gives you more control over the outcome compared to waiting for potential infringement to occur and then pursuing legal action.",
    },
    {
      title: "Public Interest",
      content:
        "Opposing a mark that could be misleading or deceptive benefits the public by ensuring accurate branding and preventing consumer confusion.",
    },
  ];

  return (
    <div>
      <div className="font-bold text-lg">Overview:</div>
      <div className="my-5">
        {copyrightOverviewResponse?.map((item, index) => (
          <div key={index}>
            <div className="font-bold text-lg">{item.title}</div>
            <div className="my-4">{item.content}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const TrademarkOppositionBenefit = () => {
  const copyrightOverviewResponse = [
    {
      title: "Protecting Existing Rights",
      content:
        "The primary benefit of filing a trademark opposition is to protect your existing trademark rights. If you believe that the registration of a similar or identical mark could cause confusion with your mark, opposing it helps prevent potential infringement and dilution.",
    },
    {
      title: "Preserving Market Position",
      content:
        "By opposing a potentially conflicting trademark, you can maintain your established market position and prevent confusion among consumers. This safeguards your brand's reputation and identity.",
    },
    {
      title: "Cost-Effective",
      content:
        "Opposing a trademark is often less costly and time-consuming than resorting to litigation after the mark is registered. It allows you to address the issue early in the registration process.",
    },
    {
      title: "Negotiation and Settlement",
      content:
        "The opposition process might provide an opportunity for the opposing party and the applicant to negotiate a settlement. This could involve coexistence agreements, licensing arrangements, or other mutually beneficial solutions.",
    },
    {
      title: "Maintaining Control",
      content:
        "Opposing a trademark gives you more control over the outcome compared to waiting for potential infringement to occur and then pursuing legal action.",
    },
    {
      title: "Public Interest",
      content:
        "Opposing a mark that could be misleading or deceptive benefits the public by ensuring accurate branding and preventing consumer confusion.",
    },
  ];

  return (
    <div>
      <div className="font-bold text-lg">
        Benefits of Trademark Opposition in India:
      </div>
      <div className="my-5">
        {copyrightOverviewResponse?.map((item, index) => (
          <div key={index}>
            <div className="font-bold text-lg">{item.title}</div>
            <div className="my-4">{item.content}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const TrademarkOppositionRequirement = () => {
  const copyrightRequirementResponse = [
    {
      title: "Standing",
      content:
        "The opposing party must have legal standing to file an opposition. This generally means they must have existing trademark rights that could be potentially infringed upon by the proposed mark.",
    },
    {
      title: "Timeliness",
      content:
        "Oppositions must be filed within the specified timeframe after the publication of the trademark application. This timeframe varies by jurisdiction but is usually around 30 to 60 days.",
    },
    {
      title: "Notice of Opposition",
      content:
        "The opposing party must submit a formal notice of opposition to the trademark office. This document outlines the grounds for opposition, including details of the existing mark, the nature of the opposition, and the legal basis for the claim.",
    },
    {
      title: "Detailed Grounds",
      content:
        "The notice of opposition must clearly state the grounds for opposition. Common grounds include the likelihood of confusion, dilution, lack of distinctiveness, improper use, and prior rights.",
    },
    {
      title: "Evidence",
      content:
        "The opposing party may need to provide evidence supporting their claims. This could include documentation of prior use, registrations, market presence, consumer confusion, and any other relevant information.",
    },
    {
      title: "Legal Arguments",
      content:
        "The notice of opposition may also include legal arguments explaining why the proposed mark should not be registered based on the provided evidence and the relevant trademark laws.",
    },
    {
      title: "Filing Fee",
      content:
        "Oppositions often require payment of a filing fee. The fee amount can vary depending on the jurisdiction.",
    },
    {
      title: "Representation",
      content:
        "While not always required, it's advisable for the opposing party to have legal representation, such as a trademark attorney, who can navigate the legal complexities of the opposition process.",
    },
    {
      title: "Response from Applicant",
      content:
        "Once the notice of opposition is filed, the trademark applicant has an opportunity to respond. They can submit counterarguments, evidence, and legal reasoning defending their mark.",
    },
    {
      title: "Evidence Period",
      content:
        "Some jurisdictions provide a specific period during which both parties can exchange evidence and arguments to support their respective positions.",
    },
    {
      title: "Hearing (if applicable)",
      content:
        "In some cases, a hearing might be scheduled where both parties present their cases in front of a trademark examiner or an opposition board.",
    },
    {
      Decision: "Decision",
      content:
        "Based on the evidence, arguments, and applicable trademark laws, a decision is made on whether to allow or reject trademark registration.",
    },
  ];

  return (
    <div>
      <div className="font-bold text-lg">
        Requirement of Trademark Opposition:
      </div>
      <div className="my-5">
        {copyrightRequirementResponse?.map((item, index) => (
          <div key={index}>
            <div className="font-bold text-lg">{item.title}</div>
            <div className="my-4">{item.content}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const TrademarkOppositionTypes = () => {
  const copyrightTypes = [
    {
      title: "Procedural Opposition",
      content:
        "An opposition might be filed based on procedural deficiencies in the application, such as incorrect filing procedures or missing required documentation.",
    },
    {
      title: "Genericness Opposition",
      content:
        "This type of opposition argues that the proposed trademark has become a generic term for the goods or services it represents, and therefore, it shouldn't be registered as a trademark.",
    },
    {
      title: "Functional Opposition",
      content:
        "If the proposed trademark is primarily functional, serving a utilitarian purpose, it might be opposed on the grounds that functional elements should not receive trademark protection.",
    },
    {
      title: "Misrepresentation Opposition",
      content:
        "If the applicant has misrepresented information or made false statements in the application, an opposition can be based on these grounds.",
    },
  ];

  return (
    <div>
      <div className="font-bold text-lg">
        Here are some common Types of Trademark Opposition:
      </div>
      <div className="my-5">
        {copyrightTypes?.map((item, index) => (
          <div key={index}>
            <div className="font-bold text-lg">{item.title}</div>
            <div className="my-4">{item.content}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const TrademarkOppositionDocumentsRequired = () => {
  const copyrightDocumentRequired = [
    {
      title: "Notice of Opposition Form",
      content:
        "This is the formal document that initiates the opposition process. It provides information about the opposing party, the proposed trademark, the grounds for opposition, and any evidence or arguments supporting the opposition.",
    },
    {
      title: "Evidence of Prior Rights",
      content:
        "If the opposition is based on prior use or registration of a similar or identical mark, documents showing the existence and validity of these prior rights might be required. This can include copies of registration certificates, evidence of use, and documentation of market presence.",
    },
    {
      title: "Evidence of Confusion",
      content:
        "For a likelihood of confusion opposition, the opposing party might need to provide evidence demonstrating that consumers are likely to be confused between the proposed trademark and their existing mark. This can include consumer surveys, expert opinions, and evidence of actual confusion in the market.",
    },
    {
      title: "Evidence of Fame for Dilution Opposition",
      content:
        "If the opposition is based on dilution, evidence showing that the existing mark is well-known and has acquired fame might be necessary.",
    },
    {
      title: "Evidence of Descriptiveness",
      content:
        "If the opposition is based on the mark being descriptive or generic, documents illustrating the descriptive nature of the mark might be required.",
    },
    {
      title: "Declaration or Affidavit",
      content:
        "The opposing party might need to submit a sworn declaration or affidavit affirming the accuracy of the information provided in the notice of opposition.",
    },
  ];

  return (
    <div>
      <div className="font-bold text-lg">
        The documentation required for Trademark Opposition:
      </div>
      <div className="my-5">
        {copyrightDocumentRequired?.map((item, index) => (
          <div key={index}>
            <div className="font-bold text-lg">{item.title}</div>
            <div className="my-4">{item.content}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
