import React, { useEffect, useRef, useState } from "react";
import themeStyle from "../styles/theme.style";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import TalkToExpertForm from "./TalkToExpertForm";
import { useSelector } from "react-redux";
function TopBannerSection({
  heading,
  BackgroundImage = null,
  backgroundCover = true,
  location,
}) {
  const reduxData = useSelector((state) => state);
  const [data, setData] = useState({});
  const fromRef = useRef(null);
  function formatService(companyName) {
    // Remove the leading slash if it exists
    if (companyName.startsWith("/")) {
      companyName = companyName.substring(1);
      companyName = `${companyName}`.replace("/", "");
    }

    return companyName;
  }
  const formattedService = formatService(location);
  const getContent = async () => {
    if (reduxData?.webData) {
      document.title = reduxData?.webData[formattedService]?.title
      setData(reduxData?.webData[formattedService] || {});
    }
  };
  const hasBgImage = false;
  useEffect(() => {
    getContent();
  }, [reduxData]);
  return (
    data.title && (
      <div
        className="parallax"
        style={{
          backgroundColor: themeStyle.COLOR_WHITE,
          backgroundImage: hasBgImage
            ? `url(${BackgroundImage ? BackgroundImage : undefined})`
            : undefined,
          backgroundSize: backgroundCover ? "cover" : "contain",
        }}
      >
        <div
          className={`px-6 md:px-10 py-16  w-full ${
            hasBgImage && `bg-black bg-opacity-50 shadow-lg`
          }`}
        >
          <div className="flex flex-col lg:flex-row ">
            <div className="lg:w-[50%] mb-6 md:mb-8 lg:mx-16">
              <h1
                className={`lg:text-4xl text-3xl font-bold underline ${
                  hasBgImage ? "text-white" : "text-black"
                }`}
                dangerouslySetInnerHTML={{ __html: data.title }}
              />
              {/* dangerouslySetInnerHTML={{ __html: content }} */}
              <h2
                className={`text-lg my-8 font-semibold`}
                ref={fromRef}
                style={{
                  color: hasBgImage ? "white" : "black",
                }}
                dangerouslySetInnerHTML={{ __html: data.subtitle }}
              />
              {Array.isArray(data.sub_points) && (
                <div className="mt-4">
                  {data.sub_points.map((point, point_index) => (
                    <tr
                      key={point_index}
                      className="flex items-center gap-2 mt-2"
                    >
                      {/* <div key={index} > */}
                      <td>
                        <RiVerifiedBadgeFill
                          size={24}
                          color={themeStyle.COLOR_GREEN}
                        />
                      </td>
                      <td>
                        <p
                          className={`${
                            hasBgImage ? "text-white" : "text-black"
                          }`}
                          dangerouslySetInnerHTML={{ __html: point }}
                        />
                      </td>
                      {/* </div> */}
                    </tr>
                  ))}
                </div>
              )}
            </div>
            <div className="lg:w-[50%] w-[97%] flex justify-center">
              <TalkToExpertForm heading={data.form_title} />
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default TopBannerSection;
