import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { API_END_POINT } from "../../Config/config";
import LoadingGif from "../../assets/images/loading.gif";
import { pagePaths } from "../../Config/pagePaths";
function ChoosePlan({ route }) {
  const { leadId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [paymentData, setPaymentData] = useState({});
  const [loadingSubmit, setLoadingSubmit] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [raiseTicked, setRaiseTicked] = useState(false);
  const [ticketMessage, setTicketMessage] = useState("");
  const [tickedSubmitted, setTickedSubmitted] = useState(false);
  function formatService(companyName) {
    // Remove the leading slash if it exists
    if (companyName?.startsWith("/")) {
      companyName = companyName?.substring(1);
    }

    // Split the string by dashes
    let words = companyName?.split("-");

    // Capitalize the first letter of each word and join them with a space
    let formattedName = words
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return formattedName
      ?.replace(/^\/+/, "")
      ?.replace(/\/+$/, "")
      ?.split("/")
      ?.pop();
  }
  const getData = async () => {
    const API = `${API_END_POINT}payment/getOrderDetails.php?orderId=${leadId}`;
    await fetch(API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // mode: "no-cors",
    })
      .then((res) => res.json())
      .then((resJson) => {
        if (resJson.status == "success") {
          setPaymentData(resJson);
        } else setErrorMessage(resJson.message);
      });
  };
  const processPayment = (planId) => {
    setLoadingSubmit(planId);
    setTimeout(async () => {
      const API = `${API_END_POINT}payment/updateOrder.php?orderId=${leadId}&step=${paymentData?.result?.step}&plan_id=${planId}`;
      console.log(API);
      await fetch(API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((resJson) => {
          console.log(resJson);
          setLoadingSubmit(null);
          if (resJson.success == 1) {
            navigate(`/${pagePaths.payment}/${leadId}`, { state: null });
          } else setErrorMessage(resJson.message);
        });
    }, 1500);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <p className="text-3xl font-bold my-4 text-center">
        Choose a right plan for your business
        {/* {formatService(paymentData?.plan?.service)} */}
      </p>
      <div className="flex justify-center">
        <span className="text-sm font-bold text-center text-gray-600">
          {"100% Money-Back Guarantee "}
          <a
            className="text-blue-400 underline"
            href={"/" + pagePaths.termsConditions}
            target="_blank"
          >
            T&C
          </a>
        </span>
      </div>
      {paymentData?.result?.plan_id == null && errorMessage == null ? (
        <div
          className={`flex items-center justify-center gap-x-5 gap-y-16 py-10`}
        >
          {paymentData?.plan?.plans?.map(
            (plan) =>
              `${plan?.plan_name}`.includes(
                plan?.pllan_name || paymentData?.result?.recommended_plan
              ) && (
                <div
                  key={plan.id}
                  className={`bg-red-600 rounded-lg shadow-xl border-solid border-2 lg:content-center ${
                    plan?.id == paymentData?.plan?.default_plan
                      ? "px-6 py-3 text-white"
                      : "my-5 p-6 bg-white"
                  }`}
                >
                  {plan.id == paymentData?.plan?.default_plan && (
                    <p className="text-x font-bold text-center mb-2 w-full bottom-10">
                      <u>{"Most Popular"}</u>
                    </p>
                  )}
                  <p className="text-3xl font-bold text-center">
                    {plan?.plan_name}
                  </p>
                  <p
                    className={`text-sm font-bold my-2 ${
                      plan.id == paymentData?.plan?.default_plan
                        ? "text-red-200"
                        : "text-gray-500"
                    }`}
                  >
                    {plan?.description}
                  </p>
                  <span className="text-sm ">
                    <s>&#x20B9;{plan?.service_price}</s>{" "}
                  </span>
                  <span className="text-xs bg-lime-100 text-lime-600 rounded p-1">
                    {plan?.discount_title}
                  </span>
                  <p className={`text-xl font-bold`}>
                    &#x20B9;{plan?.discount_price}
                  </p>
                  <p
                    className={`text-xs my-2 ${
                      plan.id == paymentData?.plan?.default_plan
                        ? "text-red-300"
                        : "text-gray-400"
                    }`}
                  >
                    {plan.other_fees}
                  </p>
                  <div className="flex justify-center">
                    <button
                      onClick={() => processPayment(plan.id)}
                      disabled={loadingSubmit != null}
                      className={`rounded-md px-4 py-2 w-full font-bold ${
                        plan.id == paymentData?.plan?.default_plan
                          ? "bg-white text-red-600"
                          : "bg-red-600 text-white"
                      }`}
                    >
                      {loadingSubmit == plan.id ? (
                        <img
                          src={LoadingGif}
                          width={20}
                          className="img-responsive center-block"
                          style={{
                            margin: "auto",
                          }}
                        />
                      ) : (
                        "Proceed"
                      )}
                    </button>
                  </div>
                  <p className={`text-xl font-bold mt-5 mb-2`}>
                    What you'll get
                  </p>
                  <ul>
                    {plan?.what_they_ll_get?.map((get, getIndex) => (
                      <li className="list-disc my-1 mx-3">{get}</li>
                    ))}
                  </ul>
                </div>
              )
          )}
        </div>
      ) : (
        errorMessage == null && (
          <div className="flex justify-center my-20">
            <span className="text-2xl text-center text-black ">
              You already choose a plan, your payment is still pending. <br />
              you can now{" "}
              <NavLink
                to={`/${pagePaths.payment}/${leadId}`}
                className={"font-bold"}
              >
                <u>Proceed to Pay</u>
              </NavLink>
            </span>
          </div>
        )
      )}
      {errorMessage && (
        <div className="flex justify-center my-20 flex-row">
          <div className="lg:w-[70%] shadow-lg p-8 relative rounded-lg overflow-hidden grid grid-cols-1 content-center">
            {!raiseTicked && (
              <p
                className="text-2xl text-center text-black"
                dangerouslySetInnerHTML={{ __html: errorMessage }}
              />
            )}
            {raiseTicked && !tickedSubmitted && (
              <>
                <p>
                  Name:{" "}
                  {paymentData?.result?.name
                    ? paymentData?.result?.name
                    : "N/A"}
                </p>
                <p>
                  Phone:{" "}
                  {paymentData?.result?.phone
                    ? paymentData?.result?.phone
                    : "N/A"}
                </p>
                <p>Order Id: {leadId || "N/A"}</p>
                <p>Issue: {"Could not get the plan details"}</p>
                <textarea
                  value={ticketMessage}
                  id="message"
                  required
                  onChange={(e) => setTicketMessage(e.target.value)}
                  placeholder="Message (optional)"
                  className={`w-full p-3 mt-4 border border-green-800 focus-within:ring-1 focus-within:ring-yellow-500 rounded-md overflow-hidden`}
                  rows="1"
                  cols="50"
                ></textarea>
                <p className="text-gray-400 text-sm">
                  your ticket is already includes with all the details, you
                  don't need to add those!
                </p>
              </>
            )}
            {tickedSubmitted && (
              <p className="text-center text-xl font-bold">
                Your Ticket has been successfully submitted, we will get back to
                you soon
              </p>
            )}
            {!tickedSubmitted && (
              <button
                onClick={async () => {
                  if (!raiseTicked) setRaiseTicked((e) => !e);
                  else {
                    let API = `${API_END_POINT}Ticket/addContactForm.php?name=${
                      paymentData?.result?.name
                        ? paymentData?.result?.name
                        : "N/A"
                    }&phone=${
                      paymentData?.result?.phone
                        ? paymentData?.result?.phone
                        : "N/A"
                    }&subject=${"TKT: Could not get the plan details"}&message=${encodeURI(
                      `order id: ${leadId}\n${ticketMessage}`
                    )}`;
                    const response = await fetch(API);
                    const resJson = await response.json();
                    if (resJson.success) setTickedSubmitted(true);
                  }
                }}
                // disabled={loadingSubmit != null}
                className={`rounded-md px-4 py-2 font-bold bg-red-600 text-white mt-5`}
              >
                {raiseTicked ? "Submit" : "Click Here to raise a quick ticket"}
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ChoosePlan;
