import React from 'react'
import { FaEnvelope, FaLocationArrow, FaPhoneAlt, FaUser } from 'react-icons/fa'
import { MdEmail, MdSubject } from 'react-icons/md'
import ContactUsImg from '../assets/images/contact-us.jpg'
import foodVegBg from '../assets/images/contactUs.jpg'
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import themeStyle from '../styles/theme.style'

function Thankyou() {

    const location = useLocation();
    const previousRoute = location.state?.from || 'Unknown';
    const navigate = useNavigate()

    return (
        <div>
            <div className='flex flex-wrap lg:flex-nowrap md:p-16 p-8 gap-8'>
                <div className='lg:w-[70%] w-full shadow-lg p-8 relative rounded-lg overflow-hidden text-center  items-center justify-center flex'>
                    <div>
                    <h1 style={{ color: themeStyle.COLOR_RED }} className='font-bold text-2xl lg:contact-title'>Thanks for your interest, Our experts will reach you shortly!</h1>
                    <button
                        onClick={() =>navigate('/')}
                        type="submit"
                        className="form-button p-3 mt-5 text-sm font-bold text-white bg-green-800 hover:bg-green-900 rounded-md"
                    >
                        Back to Home
                    </button>
                    </div>
                </div>
                <div className='lg:w-[30%] w-full shadow-lg p-8 relative rounded-lg overflow-hidden'>
                    <div className='mt-4 flex justify-center'>
                        <img height={400} width={400} src={'https://img.freepik.com/premium-vector/thank-you-placard-concept-vector-illustration_1253202-21958.jpg?size=338&ext=jpg&ga=GA1.1.1141335507.1719100800&semt=ais_user'} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Thankyou