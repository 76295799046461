import React from 'react'
import TopBannerSection from '../../components/TopBannerSection'
import ProcessRoundCircleSection from '../../components/ProcessRoundCircleSection'
import CustomTabs from '../../components/CustomTabs'
import { PrivateLimitedBenefit, PrivateLimitedDocumentsRequired, PrivateLimitedOverview, PrivateLimitedRequirement, PrivateLimitedTypes } from './PrivateLimitedTabsContent'
import PageFaq from '../../components/PageFaq'
import { TfiHeadphoneAlt } from 'react-icons/tfi'
import { HiDocumentArrowDown } from 'react-icons/hi2'
import { FaCheck } from 'react-icons/fa'
import themeStyle from '../../styles/theme.style'
import OverviewContent from '../../components/OverviewContent'
import BackgroundImage from "../../assets/images/Private Limited Company/banner.jpg"
import OverViewImage from "../../assets/images/Private Limited Company/Over View.jpg";
import { useLocation } from 'react-router-dom'
function PrivateLimitedCompany() {
  const topImageUrl = "https://st3.depositphotos.com/30987588/34891/v/450/depositphotos_348913014-stock-illustration-people-in-cozy-cafe-coffee.jpg";

  const data = ['Help you to register your directors with the MCA', 'We help you pick the right company name.', 'We draft and file (MoA and AoA).', 'Get you Documents Ready within 7 days.', 'Get a call by expert Lawyer.']

  const process = [
    { title: 'Reach out to our Experts', icon: <TfiHeadphoneAlt size={46} color={themeStyle.COLOR_RED} />, description: 'We have the best business experts who can resolve all your queries' },
    { title: 'Provide all the required documents', icon: <HiDocumentArrowDown size={46} color={themeStyle.COLOR_RED} />, description: 'Provide all the documents. Our team will initiate the paperwork on your behalf' },
    { title: 'Get your Private Limited Company done', icon: <FaCheck size={46} color={themeStyle.COLOR_RED} />, description: 'Private Limited Company is completed and the certificates will be sent to you.' },
  ]

  const tabs = [
    // { title: "Overview", content: <PrivateLimitedOverview /> },
    { title: "Benefit", content: <PrivateLimitedBenefit /> },
    { title: "Requirement", content: <PrivateLimitedRequirement /> },
    { title: "Types", content: <PrivateLimitedTypes /> },
    { title: "Document required", content: <PrivateLimitedDocumentsRequired /> },
  ];

  const faqs = [
    {
      title: 'How many days are needed ?',
      content: 'If all the documents are ready, it will take around 2 weeks',
    },
    {
      title: 'What is DIN ?',
      content: 'Director Identification Number is a unique number issued by the Ministry of Corporate Affairs to applicants on whose name the application is produced, which permits the individual to be a Director in the Company.',
    },
    {
      title: 'What is the Annual Compliance Required Private Ltd Company',
      content: 'During every financial year, the private limited company must hold one Annual General Meeting (AGM) and about 4 board meetings such as once every 4 months.',
    },
  ];
  const location = useLocation();
  return (
    <div>
      <TopBannerSection location={location.pathname} BackgroundImage={BackgroundImage} lineThrough={'₹11999'} boldNumber={'₹8999'} list={data ?? []} />
      <ProcessRoundCircleSection heading={'How To Do Your Private Limited Company Through "GLI"'} process={process} />
      <OverviewContent OverViewImage={OverViewImage} content={ <PrivateLimitedOverview /> }/>
      <CustomTabs tabs={tabs} />
      <PageFaq list={faqs} />
    </div>
  )
}

export default PrivateLimitedCompany