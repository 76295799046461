import React from 'react'
import TopBannerSection from '../../components/TopBannerSection'
import ProcessRoundCircleSection from '../../components/ProcessRoundCircleSection'
import { TfiHeadphoneAlt } from 'react-icons/tfi'
import { HiDocumentArrowDown } from 'react-icons/hi2'
import { FaCheck } from 'react-icons/fa'
import themeStyle from '../../styles/theme.style'
import PageFaq from '../../components/PageFaq'
import OverviewContent from '../../components/OverviewContent'
import { GSTRegistrationBenefit, GSTRegistrationDocumentsRequired, GSTRegistrationOverview, GSTRegistrationRequirement, GSTRegistrationTypes } from './GSTTabContent/GSTRegistrationTabContent'
import CustomTabs from '../../components/CustomTabs'
import { GSTReturnFiingOverview, GSTReturnFilingBenefit, GSTReturnFilingDocuments, GSTReturnFilingRequirement, GSTReturnFilingTypes } from './GSTTabContent/ReturnFilingContent'
import BackgroundImage from "../../assets/images/Tax & Compliance/banenr.jpg"
import OverViewImage from "../../assets/images/Tax & Compliance/over view.jpg";
import { useLocation } from 'react-router-dom'
function GSTReturnFiling() {
  const topImageUrl = "https://st3.depositphotos.com/30987588/34891/v/450/depositphotos_348913014-stock-illustration-people-in-cozy-cafe-coffee.jpg";

  const data = ['We make it easy for you to get your GST']

  const process = [
    { title: 'Reach out to our Experts', icon: <TfiHeadphoneAlt size={46} color={themeStyle.COLOR_RED} />, description: 'We have the best business experts who can resolve all your queries' },
    { title: 'Provide all the required documents', icon: <HiDocumentArrowDown size={46} color={themeStyle.COLOR_RED} />, description: 'Provide all the documents. Our team will initiate the paperwork on your behalf' },
    { title: 'Get your GST Return Filing done', icon: <FaCheck size={46} color={themeStyle.COLOR_RED} />, description: 'GST Return Filing is completed and the certificates will be sent to you.' },
  ]

  const faqs = [
    {
      title: 'Who should file GST returns?',
      content: 'Every person or entity registered under GST would be required to file a GST return for the prescribed period. Even those entities having a GST registration but no activity would be required to file a GST Nil Return to stay compliant with GST regulations.',
    },
    {
      title: 'How to file GST returns?',
      content: 'GST Returns must be filed online. There would also be a facility to prepare the returns offline and upload the same into an online portal.',
    },
    {
      title: 'What is the time limit for filing GST returns?',
      content: 'The due date for filing the GSTR1 return is usually the 10th of every month. However, for the month of July 2017, the due date for filing the GSTR1 return is the 10th of October. The due date for filing all other GSTR1 returns is yet to be announced by the GST Council.',
    },
  ];

  const tabs = [
      { title: "Benefit", content: <GSTReturnFilingBenefit /> },
      { title: "Requirement", content: <GSTReturnFilingRequirement /> },
      { title: "Types", content: <GSTReturnFilingTypes /> },
      { title: "Document required", content: <GSTReturnFilingDocuments /> },
    ];

    const location = useLocation();
  return (
    <div>
      <TopBannerSection location={location.pathname} BackgroundImage={BackgroundImage} backgroundCover={true} heading={'GST Return Filing'} lineThrough={'₹4999'} boldNumber={'₹1499'} list={data ?? []} />
      <ProcessRoundCircleSection heading={'How To Do Your GST Return Filing Through "GLI"'} process={process} />
      <OverviewContent OverViewImage={OverViewImage} content={<GSTReturnFiingOverview />} />
      <CustomTabs tabs={tabs} />
      <PageFaq list={faqs} />
    </div>
  )
}

export default GSTReturnFiling