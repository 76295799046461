export default {

    // FONT_WEIGHT_LIGHT: '200',
    FONT_WEIGHT_REGULAR: '400',
    // FONT_WEIGHT_MEDIUM: '500',
    FONT_WEIGHT_SEMI_BOLD: '600',
    FONT_WEIGHT_BOLD: '700',

    FONT_SIZE_XSMALL: 10,
    FONT_SIZE_SMALL: 13,
    FONT_SIZE_MEDIUM: 15,
    FONT_SIZE_LARGE: 17,
    FONT_SIZE_XLARGE: 20,
    FONT_SIZE_XXLARGE: 21,
    FONT_SIZE_XXXLARGE: 24,

    COLOR_THEME: '#FFA600',
    COLOR_PRIMARY: '#FFA600',
    COLOR_PRIMARY_LITE: '#FFF0D6',
    COLOR_SECONDARY: '#B8B8B8',
    COLOR_WHITE: '#FFFFFF',
    COLOR_BACKGROUND: '#FFFFFF',
    COLOR_SHADOW: '#99999940',
    COLOR_BLACK: '#323232',
    COLOR_DARK_BLACK: '#000000',
    COLOR_DARK_GREY: '#737373',
    COLOR_GREY: '#B8B8B8',
    COLOR_LIGHT_GREY: '#EBEBEB',
    COLOR_GREEN: '#87A840',
    COLOR_LIGHT_GREEN: '#87A840',
    COLOR_RED: '#F11300',
    COLOR_LIGHT_RED: '#ffe7da',
    COLOR_RED_GRADIENT: '#FBBCAB',
    COLOR_LINEAR_GRADIENT: '#E7EED9',
    COLOR_PRIMARY_RED: '#FCCABC',
    COLOR_SYNC_CARD: '#F4F4F4',
    //COLOR_LIGHT_BACKGROUND: '#E5E5E5',
};