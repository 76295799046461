import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import themeStyle from "../styles/theme.style";
import { FaAngleDown, FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { RxHamburgerMenu } from "react-icons/rx";
import { isPageEnabled, navTitles, pagePaths } from "../Config/pagePaths";

const Navbar = ({ toggleSidebar }) => {
  const activeStyle = {
    fontWeight: "bold",
    color: themeStyle.COLOR_RED,
  };

  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <>
      <header className="sticky top-0 z-50">
        <div
          style={{
            backgroundColor: themeStyle.COLOR_RED,
          }}
          className="w-full shadow-lg"
        >
          <div className="w-full p-2 mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between flex-wrap items-center">
              <div className="flex items-center space-x-8">
                <div className="flex items-center space-x-2">
                  <FaPhoneAlt size={16} color={themeStyle.COLOR_WHITE} />
                  <a
                    href="tel:+918240441937"
                    className="text-white text-sm font-semibold"
                  >
                    +91 82404 41937
                  </a>
                </div>
                <div className="flex items-center space-x-2">
                  <FaEnvelope size={16} color={themeStyle.COLOR_WHITE} />
                  <a
                    href="mailto:info@golegalindia.com"
                    className="text-white text-sm font-semibold"
                  >
                    info@golegalindia.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="w-full p-3 bg-white shadow-lg">
          <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
            <ul className="flex justify-between flex-wrap items-center py-3">
              <Link to="/">
                <img
                  src={require("../assets/images/Logo.png")}
                  width={100}
                  height={100}
                  alt="Company Logo"
                />
              </Link>
              <div className="lg:hidden block">
                <RxHamburgerMenu
                  color={themeStyle.COLOR_RED}
                  size={20}
                  onClick={toggleSidebar}
                  aria-label="Toggle Navigation"
                />
              </div>
              <div className="hidden lg:block">
                <div className="flex items-center space-x-8 flex-wrap">
                  <NavLink
                    className="text-black text-md font-semibold"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                    to="/"
                    aria-label="Home"
                  >
                    Home
                  </NavLink>
                  {(isPageEnabled.privateLimitedCompany ||
                    isPageEnabled.llpRegistration ||
                    isPageEnabled.onePersonCompany ||
                    isPageEnabled.ngoRegistration ||
                    isPageEnabled.isoCertificate ||
                    isPageEnabled.importExport) && (
                    <li className="hidden md:block sm:ml-6">
                      <div className="flex space-x-4">
                        <div className="relative group">
                          <div className="flex items-center gap-x-1">
                            <button
                              className={`text-black text-md font-semibold ${
                                isActive("/private-limited-company") ||
                                isActive("/llp-registration") ||
                                isActive("/one-person-company") ||
                                isActive("/ngo-registration") ||
                                isActive("/iso-certificate") ||
                                isActive("/import-export")
                                  ? "text-red-600 font-bold"
                                  : ""
                              }`}
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Start Business
                            </button>
                            <FaAngleDown />
                          </div>
                          <div className="absolute left-0 hidden group-hover:block bg-white border rounded-md shadow-lg z-10 w-52 p-1">
                            {[
                              pagePaths.privateLimitedCompany,
                              pagePaths.llpRegistration,
                              pagePaths.onePersonCompany,
                              pagePaths.ngoRegistration,
                              pagePaths.isoCertificate,
                              pagePaths.importExport,
                            ].map(
                              (page, index) =>
                                isPageEnabled[
                                  Object.keys(pagePaths).find(
                                    (key) => pagePaths[key] === page
                                  )
                                ] && (
                                  <NavLink
                                    key={index.toString()}
                                    to={`/${page}`}
                                    className="block px-4 py-2 text-black text-sm nav-submenu hover:text-red-500 hover:font-semibold"
                                    style={({ isActive }) =>
                                      isActive ? activeStyle : undefined
                                    }
                                  >
                                    {
                                      navTitles[
                                        Object.keys(pagePaths).find(
                                          (key) => pagePaths[key] === page
                                        )
                                      ]
                                    }
                                  </NavLink>
                                )
                            )}
                          </div>
                          {/* <div className="absolute left-0 hidden group-hover:block bg-white border rounded-md shadow-lg z-10 w-52 p-1">
                            {isPageEnabled.privateLimitedCompany && (
                              <NavLink
                                to="/private-limited-company"
                                className="block px-4 py-2 text-black hover:text-red-500 text-sm nav-submenu hover:font-semibold"
                                style={({ isActive }) =>
                                  isActive ? activeStyle : undefined
                                }
                                aria-label="Private Limited Company"
                              >
                                Private Limited Company
                              </NavLink>
                            )}
                            {isPageEnabled.llpRegistration && (
                              <NavLink
                                to="/llp-registration"
                                className="block px-4 py-2 text-black text-sm nav-submenu hover:text-red-500 hover:font-semibold"
                                style={({ isActive }) =>
                                  isActive ? activeStyle : undefined
                                }
                                aria-label="LLP Registration"
                              >
                                LLP Registration
                              </NavLink>
                            )}
                            {isPageEnabled.onePersonCompany && (
                              <NavLink
                                to="/one-person-company"
                                className="block px-4 py-2 text-black text-sm nav-submenu hover:text-red-500 hover:font-semibold"
                                style={({ isActive }) =>
                                  isActive ? activeStyle : undefined
                                }
                                aria-label="One Person Company"
                              >
                                One Person Company
                              </NavLink>
                            )}
                            {isPageEnabled.ngoRegistration && (
                              <NavLink
                                to="/ngo-registration"
                                className="block px-4 py-2 text-black text-sm nav-submenu hover:text-red-500 hover:font-semibold"
                                style={({ isActive }) =>
                                  isActive ? activeStyle : undefined
                                }
                                aria-label="NGO Registration"
                              >
                                NGO Registration
                              </NavLink>
                            )}
                          </div> */}
                        </div>
                      </div>
                    </li>
                  )}
                  {[
                    isPageEnabled.gstRegistration,
                    isPageEnabled.gstReturnFiling,
                  ].includes(true) && (
                    <li className="hidden md:block sm:ml-6">
                      <div className="flex space-x-4">
                        <div className="relative group">
                          <div className="flex items-center gap-x-1">
                            <button
                              className={`text-black text-md font-semibold ${
                                isActive(`/gst-registration`) ||
                                isActive(`/gst-return-filing`)
                                  ? "text-red-600 font-bold"
                                  : ""
                              }`}
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Tax & Compliance
                            </button>
                            <FaAngleDown />
                          </div>
                          <div className="absolute left-0 hidden group-hover:block bg-white border rounded-md shadow-lg z-10 w-52 p-1">
                            {[
                              pagePaths.gstRegistration,
                              pagePaths.gstReturnFiling,
                              pagePaths.incomeTaxFiling,
                            ].map(
                              (page, index) =>
                                isPageEnabled[
                                  Object.keys(pagePaths).find(
                                    (key) => pagePaths[key] === page
                                  )
                                ] && (
                                  <NavLink
                                    key={index.toString()}
                                    to={`/${page}`}
                                    className="block px-4 py-2 text-black text-sm nav-submenu hover:text-red-500 hover:font-semibold"
                                    style={({ isActive }) =>
                                      isActive ? activeStyle : undefined
                                    }
                                  >
                                    {
                                      navTitles[
                                        Object.keys(pagePaths).find(
                                          (key) => pagePaths[key] === page
                                        )
                                      ]
                                    }
                                  </NavLink>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                  )}
                  {[
                    isPageEnabled.fssaiRegistration,
                    isPageEnabled.fssaiRenewal,
                  ].includes(true) && (
                    <li className="hidden md:block sm:ml-6">
                      <div className="flex space-x-4">
                        <div className="relative group">
                          <div className="flex items-center gap-x-1">
                            <button
                              className={`text-black text-md font-semibold ${
                                isActive("/fssai-registration") ||
                                isActive("/fssai-renewal")
                                  ? "text-red-600 font-bold"
                                  : ""
                              }`}
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Filing & Drafting
                            </button>
                            <FaAngleDown />
                          </div>
                          <div className="absolute left-0 hidden group-hover:block bg-white border rounded-md shadow-lg z-10 w-52 p-1">
                            {[
                              pagePaths.fssaiRegistration,
                              pagePaths.fssaiRenewal,
                            ].map(
                              (page, index) =>
                                isPageEnabled[
                                  Object.keys(pagePaths).find(
                                    (key) => pagePaths[key] === page
                                  )
                                ] && (
                                  <NavLink
                                    key={index.toString()}
                                    to={`/${page}`}
                                    className="block px-4 py-2 text-black text-sm nav-submenu hover:text-red-500 hover:font-semibold"
                                    style={({ isActive }) =>
                                      isActive ? activeStyle : undefined
                                    }
                                  >
                                    {
                                      navTitles[
                                        Object.keys(pagePaths).find(
                                          (key) => pagePaths[key] === page
                                        )
                                      ]
                                    }
                                  </NavLink>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                  )}
                  {[
                    isPageEnabled.trademarkRegistration,
                    isPageEnabled.trademarkObjection,
                    isPageEnabled.trademarkHearing,
                    isPageEnabled.copyrightRegistration,
                  ].includes(true) && (
                    <li className="hidden md:block sm:ml-6">
                      <div className="flex space-x-4">
                        <div className="relative group">
                          <div className="flex items-center gap-x-1">
                            <button
                              className={`text-black text-md font-semibold ${
                                isActive("/trademark-registration") ||
                                isActive("/trademark-objection") ||
                                isActive("/trademark-hearing") ||
                                isActive("/copyright")
                                  ? "text-red-600 font-bold"
                                  : ""
                              }`}
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Trademark & IP
                            </button>
                            <FaAngleDown />
                          </div>
                          <div className="absolute left-0 hidden group-hover:block bg-white border rounded-md shadow-lg z-10 w-52 p-1">
                            {[
                              pagePaths.trademarkRegistration,
                              pagePaths.trademarkObjection,
                              pagePaths.trademarkHearing,
                              pagePaths.trademarkOpposition,
                              pagePaths.copyrightRegistration,
                            ].map(
                              (page, index) =>
                                isPageEnabled[
                                  Object.keys(pagePaths).find(
                                    (key) => pagePaths[key] === page
                                  )
                                ] && (
                                  <NavLink
                                    key={index.toString()}
                                    to={`/${page}`}
                                    className="block px-4 py-2 text-black text-sm nav-submenu hover:text-red-500 hover:font-semibold"
                                    style={({ isActive }) =>
                                      isActive ? activeStyle : undefined
                                    }
                                  >
                                    {
                                      navTitles[
                                        Object.keys(pagePaths).find(
                                          (key) => pagePaths[key] === page
                                        )
                                      ]
                                    }
                                  </NavLink>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                  )}
                  <li>
                    <NavLink
                      className="text-black text-md font-semibold"
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to="/about-us"
                      aria-label="About Us"
                    >
                      About Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="text-black text-md font-semibold"
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to="/contact-us"
                      aria-label="Contact Us"
                    >
                      Contact Us
                    </NavLink>
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </nav>
        {(location.pathname.includes("fssai-registration") ||
          location.pathname.includes("fssai-renewal")) && (
          <div
            style={{ backgroundColor: themeStyle.COLOR_PRIMARY }}
            className="w-full shadow-lg text-white"
          >
            <marquee className="flex items-center">
              <img
                className="rotate-[30deg]"
                height={35}
                width={35}
                src="https://www.srkttc.in/images/img/unnamed.gif"
                alt="FSSAI Update"
                style={{
                  display: "inline",
                  verticalAlign: "middle",
                  marginRight: 10,
                }}
              />
              FSSAI Update: Food Business Can Now Be Fined Rs.5 Lakh & 6 Months
              Jail for Not Having Food License.
            </marquee>
          </div>
        )}
      </header>
    </>
  );
};

export default Navbar;
