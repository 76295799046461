export const NGOOverview = () => {

    const overview = [
        {
            title: "Directors and Members",
            content: "You need a minimum of two directors (individuals) for a Section 8 Company, and there should be a minimum of two shareholders/members as well."
        },
        {
            title: "Digital Signature Certificate (DSC)",
            content: "Obtain Digital Signature Certificates for the proposed directors. It is used to sign electronic documents during the registration process."
        },
        {
            title: "Director Identification Number (DIN)",
            content: " Obtain DIN for all the proposed directors. It’s a unique identification number issued by the Ministry of Corporate Affairs (MCA)."
        },
        {
            title: "Name Approval",
            content: "Choose a unique name for your Section 8 Company and apply for name availability through the RUN (Reserve Unique Name) service on the MCA portal."
        },
        {
            title: "Memorandum and Articles of Association",
            content: "Draft the Memorandum of Association (MOA) and Articles of Association (AOA) of the company. These documents define the company’s objectives and rules."
        },
        {
            title: "License under Section 8",
            content: "Apply for a license under Section 8 of the Companies Act, 2013. Prepare and submit Form INC-12 to the Registrar of Companies (RoC) along with the necessary documents, such as the MOA, AOA, and a declaration from the proposed directors."
        },
        {
            title: "Incorporation",
            content: "Once you receive the license under Section 8, you can proceed with the incorporation process. File the incorporation documents (Form SPICe) along with the required attachments, including the MOA, AOA, and other declarations, with the RoC."
        },
        {
            title: "PAN and TAN",
            content: "Apply for a Permanent Account Number (PAN) and Tax Deduction and Collection Account Number (TAN) for your Section 8 Company."
        },
        {
            title: "Goods and Services Tax (GST) Registration",
            content: "If applicable, register for GST with the concerned tax authorities."
        },
        {
            title: "Bank Account",
            content: "Open a bank account in the name of the Section 8 Company."
        },
    ];

    return (
        <div>
            <div className="font-bold text-lg">A Section 8 Company, also known as a non-profit organization (NPO) or a non-governmental organization (NGO)</div>
            <div className="my-3">NGO</div>
            <div className="mt-5">
                {overview?.map((item, index) => (
                    <div key={index}>
                        <div className="font-bold text-lg">{item.title}</div>
                        <div className="my-4">{item.content}</div>
                    </div>
                ))}
            </div>
            <div className="my-3">is registered under Section 8 of the Companies Act, 2013. The primary objective of such companies is to promote charitable or not-for-profit activities for the welfare of society.</div>
            <div className="my-3">Here are the general steps for registering a Section 8 Company in India:</div>
        </div>
    )
}

export const NGOBenefit = () => {

    const benefits = [
        { title: 'Charitable Objectives', content: 'The main benefit of a Section 8 Company is that it is formed with the intention of promoting charitable activities. It can be established for various purposes like education, promotion of art, science, commerce, sports, social welfare, and more.' },
        { title: 'Limited Liability', content: ' Just like any other company, the liability of the members is limited to the extent of their unpaid share capital. The personal assets of the members are generally not at risk.' },
        { title: 'Tax Exemptions', content: 'Section 8 Companies are eligible for various tax exemptions and benefits. They can avail of income tax exemptions under Section 12AA and Section 80G of the Income Tax Act, 1961, for contributions received and donations made' },
        { title: 'No Minimum Capital Requirement', content: 'Unlike some other types of companies, a Section 8 Company does not have a minimum capital requirement. You can start with any amount of capital as per your organization’s needs.' },
        { title: 'No Dividend Distribution', content: 'Section 8 Companies are prohibited from distributing dividends to their members. Any surplus generated from their activities must be utilized for furthering the organization’s objectives and cannot be distributed among the members.' },
        { title: 'Credibility and Recognition', content: 'Being a registered Section 8 Company provides credibility and recognition to the organization. It is easier for such companies to raise funds, get grants, and collaborate with other organizations for social initiatives' },
        { title: 'Perpetual Succession', content: 'Section 8 Companies have perpetual succession, which means that their existence is not affected by the status of their members or directors. It continues to exist until it is legally dissolved.' },
        { title: 'No Requirement of “Limited” in Name', content: 'Section 8 Companies are exempted from using the word “Limited” or “Private Limited” in their name, which is mandatory for other types of companies' },
        { title: 'Exemption from Stamp Duty', content: 'Some states in India provide stamp duty exemptions or reductions to Section 8 Companies, making the registration process more cost-effective.' },
        { title: 'Professional Management', content: 'Since Section 8 Companies require a minimum of two directors, they are usually managed by a group of individuals with diverse expertise, ensuring professional management.' },
    ]

    return (
        <div>
            <div className="font-bold text-lg">Section 8 Company (also known as a non-profit organization or non-governmental organization) is a legal entity registered under Section 8 of the Companies Act, 2013, in India. The primary objective of such companies is to promote charitable or not-for-profit activities for the welfare of society. Here are some of the benefits of forming a Section 8 Company:</div>
            {benefits?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg">{item.title}</div>
                    <div className="my-4">{item.content}</div>
                </div>
            ))}
        </div>
    )
}

export const NGORequirement = () => {

    const requirement = [
        {
            title: "Minimum Directors",
            content: "A Section 8 Company must have a minimum of two directors. For certain types of companies, such as private limited companies, the minimum number of directors is three."
        },
        {
            title: "Minimum Members",
            content: "There should be at least two members (shareholders) for a Section 8 Company. The maximum number of members is unlimited."
        },
        {
            title: "Objectives",
            content: "The primary objective of a Section 8 Company should be to promote charitable or not-for-profit activities, such as education, art, science, sports, social welfare, etc. The profits earned, if any, should be utilized solely for the company’s charitable activities, and no dividend should be paid to the members."
        },
        {
            title: "License under Section 8",
            content: "The company needs to obtain a license under Section 8 of the Companies Act, 2013, before incorporation. To obtain the license, the applicant must submit Form INC-12 to the Registrar of Companies (RoC) along with the Memorandum of Association (MOA) and Articles of Association (AOA) and other required documents."
        },
        {
            title: "Name Approval",
            content: "The proposed name of the company should be unique and not similar to any existing company or trademark. The name must comply with the naming guidelines issued by the MCA."
        },
        {
            title: "Registered Office",
            content: " The company should have a registered office in India. This address will be used for all official communications."
        },
        {
            title: "Memorandum of Association (MOA) and Articles of Association (AOA)",
            content: "The MOA and AOA are essential documents that define the company’s objectives, rules, and regulations. These documents must be drafted carefully and submitted to the RoC."
        },
        {
            title: "Declaration",
            content: "The directors of the proposed Section 8 Company need to submit a declaration affirming that they are not disqualified from being appointed as directors under the Companies Act."
        },
        {
            title: "Compliance",
            content: "Once the Section 8 Company is registered, it must comply with the ongoing regulatory and reporting requirements, such as filing annual financial statements, conducting annual general meetings (AGMs), etc."
        },
    ];

    return(
        <div>
            <div className="mb-3">To form a Section 8 Company in India, there are specific requirements that must be fulfilled. These requirements are governed by the Companies Act, 2013, and the rules and regulations issued by the Ministry of Corporate Affairs (MCA). Below are the key requirements to register a Section 8 Company</div>
             {requirement?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg">{item.title}</div>
                    <div className="my-4">{item.content}</div>
                </div>
            ))}
        </div>
    )
}

export const NGOTypes = () => {

    const typesOfLLPAgreements = [
        {
            title: "Section 8 Company",
            content: "This is the main type of company that is registered under Section 8 of the Companies Act, 2013. It is a non-profit organization (NPO) formed with the primary objective of promoting charitable activities, such as education, art, science, sports, social welfare, etc. The profits earned, if any, are used solely for promoting the company’s charitable objectives, and no dividend is distributed to its members. The name of the company may end with “Foundation,” “Association,” “Society,” “Council,” “Club,” “Academy,” etc."
        },
        {
            title: "Section 8 Company with Limited Liability (Section 8(1A))",
            content: "This is a special type of Section 8 Company that has the added feature of limited liability for its members. While the primary objectives and characteristics remain the same as a regular Section 8 Company, this type offers an additional benefit of limited liability protection to its members. The name of the company may end with “Limited.”"
        },
    ];


    return (
        <div>
            <div className="mb-3">Under Section 8 of the Companies Act, 2013, there are two types of companies that can be registered:</div>
            {typesOfLLPAgreements?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg">{item.title}</div>
                    <div className="my-4">{item.content}</div>
                </div>
            ))}
        </div>
    )
}

export const NGODocuments = () => {

    const documentsRequired = [
        {
            title: "Memorandum of Association (MOA)",
            description: " MOA is a fundamental document that outlines the company’s objectives, scope of activities, and the relationship of the company with its members. It should state that the company intends to apply its profits, if any, to promoting charitable or not-for-profit activities."
        },
        {
            title: "Articles of Association (AOA)",
            description: "AOA is a document that contains the rules and regulations governing the internal management and operations of the company."
        },
        {
            title: "Declaration by Promoters",
            description: "The proposed promoters of the company (directors and shareholders) need to submit a declaration stating their intention to form the Section 8 Company and that they are not disqualified under the Companies Act, 2013."
        },
        {
            title: "Affidavit from Directors and Members",
            description: "The directors and members of the proposed company are required to submit affidavits confirming that they are not convicted of any offense related to the promotion, formation, or management of any company and that they have not been found guilty of fraud or any other financial misconduct."
        },
        {
            title: "Proof of Registered Office",
            description: "You need to provide a document as proof of the registered office address of the company. It can be a utility bill, property tax receipt, or lease/rent agreement, along with a no-objection certificate (NOC) from the property owner, if applicable."
        },
        {
            title: "Director Identification Number (DIN) and Digital Signature Certificate (DSC)",
            description: "Each proposed director needs to have a DIN, which can be obtained by filing Form DIR-3. Additionally, the directors must obtain a DSC, which will be used to digitally sign the incorporation documents."
        },
        {
            title: "Name Approval",
            description: "Apply for name approval through the RUN (Reserve Unique Name) service on the MCA portal. Once the name is approved, it remains valid for 20 days."
        },
        {
            title: "Consent Letters",
            description: "Obtain consent letters from the proposed directors and other key personnel to act as directors, managers, or office bearers of the company."
        },
        {
            title: "License under Section 8",
            description: "For Section 8 Companies, you need to obtain a license under Section 8 of the Companies Act, 2013. Form INC-12 needs to be filed with the RoC along with the prescribed fee and necessary documents."
        },
        {
            title: "Board Resolution",
            description: "A board resolution authorizing the person(s) to apply for the registration of the Section 8 Company and approving the MOA and AOA must be prepared."
        },

    ];


    return (
        <div>
            <div className="mb-3">To register a Section 8 Company in India, you will need to prepare and submit various documents to the Registrar of Companies (RoC) as part of the registration process. Below is a list of the essential documents required for incorporating a Section 8 Company:</div>
            {documentsRequired?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg">{item.title}</div>
                    <div className="my-4">{item.description}</div>
                </div>
            ))}
        </div>
    )
}