export const ITRFilingOverview = () => {
  return (
    <div>
      <div>
        <p>
          <b>Filing income tax returns (ITR)</b> is a crucial duty of every
          taxpayer in India, ensuring adherence to the nation's tax regulations.
          It involves detailing all sources of income, deductions, and tax
          liabilities for a comprehensive report to the Income Tax Department.
          The last date to file your Income Tax Return for the Financial Year
          2023-24 (Assessment Year 2024-25) without incurring a late fee is July
          31, 2024.
        </p>
        <br />
        <p>
          Early ITR filing helps avoid errors and last-minute technical
          glitches, ensuring a smoother submission process. At IndiaFilings, we
          streamline the ITR e filing process, enabling you to submit your ITR
          returns online with ease. Our service simplifies each step, ensuring a
          faster, more efficient, and secure Income tax e filing experience.
        </p>
        <br />
        <p>
          With expert assistance available throughout the process, IndiaFilings
          ensures that your ITR e filing is hassle-free, allowing you to fulfil
          your tax obligations effortlessly.
        </p>
      </div>
    </div>
  );
};

export const ITRFilingBenefit = () => {
  const benefit = [
    {
      title: "Claim Tax Benefits",
      content:
        "Income tax e filing allows you to claim tax deductions and exemptions under various sections of the Income Tax Act, effectively reducing your tax liability.",
    },
    {
      title: "Essential for Financial Transactions",
      content: `ITRs are often required for: 
        <ol>
            <li class="list-disc ml-10"><b>Loan Applications:</b> Banks and financial institutions typically require the last few years' ITRs as proof of income.</li>
            <li class="list-disc ml-10"><b>Visa Applications:</b> Many countries require proof of financial records, such as ITRs, for visa applications to ensure the applicant's financial stability.</li>
        </ol>`,
    },
    {
      title: "Avoid Penalties",
      content: `Failing to do income tax filing can result in penalties and fines, especially if you are liable to pay tax. Timely Income tax e filing helps avoid these unnecessary costs.`,
    },
    {
      title: "Carry Forward Losses",
      content: `If you incur any financial losses, these can be carried forward to subsequent years to be offset against future profits. This benefit is only available if you did income tax filing.`,
    },
  ];

  return (
    <div>
      <div className="mt-5">
        {benefit?.map((item, index) => (
          <div key={index}>
            <div
              className="font-bold text-lg"
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
            <div
              className="my-4"
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export const ITRFilingRequirement = () => {
  const requirement = [
    {
      title: "Who is eligible to file ITR-1 for AY 2024-25?",
      content: `<ul>
      <li class="list-disc">Total income does not exceed ₹ 50 lakh during the FY</li>
      <li class="list-disc">Income is from salary, one house property, family pension income, agricultural income (up to ₹5000/-), and other sources, which include:
        <ul>
            <li class="list-disc ml-10">Interest from Savings Accounts</li>
            <li class="list-disc ml-10">Interest from Deposits (Bank / Post Office / Cooperative Society)</li>
            <li class="list-disc ml-10">Interest from Income Tax Refund</li>
            <li class="list-disc ml-10">Interest received on Enhanced Compensation</li>
            <li class="list-disc ml-10">Any other Interest Income</li>
            <li class="list-disc ml-10">Family Pension</li>
        </ul>
      </li>
      <li class="list-disc">Income of Spouse (other than those covered under Portuguese Civil Code) or Minor is clubbed (only if the source of income is within the specified limits as mentioned above).</li>
      </ul>`,
    },
  ];

  return (
    <div>
      <div className="mt-5">
        {requirement?.map((item, index) => (
          <div key={index}>
            <div
              className="font-bold text-lg"
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
            <div
              className="my-4"
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export const ITRFilingTypes = () => {
  const requirement = [
    {
      title: "ITR-1: SAHAJ",
      content:
        "This Income tax return form is designed for individuals whose annual income from salary or pension is below Rs. Fifty lakhs who own just one house property.",
    },
    {
      title: "ITR-2: Beyond Business",
      content:
        "Ideal for Non-Resident Indians (NRIs), directors of companies, and shareholders of private companies. This ITR form is used if you have capital gains income or foreign income or own two or more house properties and if your income exceeds Rs. 50 lakhs.",
    },
    {
      title: "ITR-3: Professionals and Proprietorships",
      content:
        "This ITR form Suitable for professionals and individuals running a proprietorship business in India.",
    },
    {
      title: "ITR-4: Presumptive Taxation Enrollees",
      content:
        "This Income tax return form is for individuals opting for the presumptive taxation scheme, where business income is below Rs. 2 crores or professional income is below Rs. 50 lakhs.",
    },
    {
      title: "ITR-5: Partnerships, LLPs, and More",
      content:
        "This income tax form must be filed by partnership firms, Limited Liability Partnerships (LLPs), associations, and bodies of individuals.",
    },
    {
      title: "ITR-6: Companies",
      content:
        "This ITR e filing form is Applicable to companies that are registered in India.",
    },
    {
      title: "ITR-7: Special Entities",
      content:
        "This ITR e filing is Used by entities such as charitable/religious trusts, political parties, scientific research institutions, and colleges/universities.",
    },
  ];

  return (
    <div>
      <div className="mt-5">
        {requirement?.map((item, index) => (
          <div key={index}>
            <div className="font-bold text-lg">{item.title}</div>
            <div className="my-4">{item.content}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const ITRFilingDocumentsRequired = () => {
  const requirement = [
    {
      title: "General Documents for ITR filing",
      content: `<ol>
            <li class="list-disc ml-10">PAN (Permanent Account Number): Essential for every kind of financial transaction and tax-related activity.</li>
            <li class="list-disc ml-10">Aadhaar (Linked to PAN): Required for verification and linking to your tax records.</li>
            <li class="list-disc ml-10">Bank Account Details: We need to process any potential refunds or verify financial details.</li>
          </ol>`,
    },
    {
      title: "Income-Related Documents",
      content: `<ol>
            <li class="list-disc ml-10">Salary Slips: To verify your income from employment.</li>
            <li class="list-disc ml-10">Rent Receipts: If you are claiming House Rent Allowance (HRA).</li>
            <li class="list-disc ml-10">Form 16: Issued by your employer, detailing the tax deducted at source on your salary.</li>
            <li class="list-disc ml-10">Form-16A: For TDS on Income other than salary.</li>
            <li class="list-disc ml-10">Form-16B: For TDS on the sale of the property.</li>
            <li class="list-disc ml-10">Form-16C: For TDS on rent.</li>
            <li class="list-disc ml-10">Form 26AS: Your tax credit statement, which shows all taxes that have been deposited against your PAN.</li>
          </ol>`,
    },
    {
      title: "Deductions and exemptions-related documents",
      content: `<ol>
            <li class="list-disc ml-10">Interest Certificates: These are from banks and post offices for savings accounts and fixed deposits.</li>
            <li class="list-disc ml-10">Home Loan Details: If you're claiming deductions on home loan interest.</li>
            <li class="list-disc ml-10">Proof of Tax-Saving Instruments: Includes life insurance, health insurance, Public Provident Fund (PPF), National Savings Certificates (NSC), ELSS mutual funds, etc.</li>
            <li class="list-disc ml-10">Income from Capital Gains: Documents related to the sale of assets like stocks or property.</li>
            <li class="list-disc ml-10">Rental Income: Lease agreements and rent receipts if you have rental property.</li>
            <li class="list-disc ml-10">Foreign Income and Dividend Income Proofs: Documents verifying income from foreign sources and dividends.</li>
          </ol>`,
    },
    {
      title: "Other Documents",
      content:
        "Other Documents may required for further proceed",
    },
  ];

  return (
    <div>
      <div className="mt-5">
        {requirement?.map((item, index) => (
          <div key={index}>
            <div
              className="font-bold text-lg"
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
            <div
              className="my-4"
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
