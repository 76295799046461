import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import themeStyle from '../styles/theme.style';
import { LiaStarSolid } from 'react-icons/lia';
import rightArrowIcon from '../assets/images/right-arrow.png'
import leftArrowIcon from '../assets/images/left-arrow.png'
import quote from "../assets/images/quote.png"
import { companyName } from '../Config/config';

const testimonials = [
    {
        name: 'Dr Snehal Yadav',
        company: 'Smartyields Agro Pvt Ltd | Incorporation',
        image: 'https://lh3.googleusercontent.com/a/ACg8ocIff8qDLQGfFfyTzUrGzsdcJ5sc2JqFqPjUC8hGxmACr7hrLbZd=w120-h120-p-rp-mo-br100',
        feedback: 'I recently had the pleasure of experiencing 5-star service from your team, and I must say, it exceeded all my expectations. From the moment I walked in, I was greeted with warmth and professionalism. The staff\'s attention to detail and dedication to customer satisfaction were truly commendable.'
    },
    {
        name: 'Rupesh Pal',
        company: 'Upcelerators Technologies Pvt Ltd | Incorporation',
        image: 'https://lh3.googleusercontent.com/a/ACg8ocImZeYMKTXWGSwvw6bLn-m7XQ9WAr9MoJAnsvFVaAhIBWSwgtVP=w120-h120-p-rp-mo-ba3-br100',
        feedback: 'Go legal India has delivered an excellent work. Very great experience and the work was done fast and it was hassle-free. The way they respond their client is just amazing. Highly recommended.'
    },
    {
        name: 'sandeep kumar',
        company: 'Smartyields Agro Pvt Ltd | Incorporation',
        image: 'https://lh3.googleusercontent.com/a-/ALV-UjVtoJ8FO9nimvhyowLioUdwZLRyHW3o-jQ4V3MT-crxhME6Lz5WpQ=w120-h120-p-rp-mo-br100',
        feedback: 'Very good service i got my food license within 3 days they are very  much professional.. i loved the services and i will take further services from them.'
    },
    {
        name: 'Rupesh Pal',
        company: 'Upcelerators Technologies Pvt Ltd | Incorporation',
        image: 'https://lh3.googleusercontent.com/a/ACg8ocImZeYMKTXWGSwvw6bLn-m7XQ9WAr9MoJAnsvFVaAhIBWSwgtVP=w120-h120-p-rp-mo-ba3-br100',
        feedback: `${companyName} delivered exceptional work promptly and with remarkable patience. Their professionalism and dedication to customer satisfaction make them a top choice for any project. Highly recommended!`
    },
    // https://lh3.googleusercontent.com/a/ACg8ocIJSTPCsgpdU25iBWbcbzAtROrt16QawDlI1mKg6zERAJS1sg=w72-h72-p-rp-mo-br100 - r
    {
        name: 'Yogesh Arora',
        company: 'Smartyields Agro Pvt Ltd | Incorporation',
        image: 'https://lh3.googleusercontent.com/a/ACg8ocJHDUviDsrXYuqPRwS_lW70mZ-XUnhqiH9dWkDCiEU8BbecNg=w72-h72-p-rp-mo-br100',
        feedback: 'I had a very good experience with this company got my fssai within 3 days thank you so much.'
    },
    {
        name: 'Rahi Munnisa',
        company: 'Upcelerators Technologies Pvt Ltd | Incorporation',
        image: 'https://lh3.googleusercontent.com/a/ACg8ocII6WQJ3KPKi5PFDKD99JsL4LCb83oD5VWa2WWcAgiPF2AZsw=w72-h72-p-rp-mo-br100',
        feedback: `Awesome service by ${companyName}  a kind and correct response from him.`
    },
    {
        name: 'Sanjeev Pujari',
        company: 'Upcelerators Technologies Pvt Ltd | Incorporation',
        image: 'https://lh3.googleusercontent.com/a/ACg8ocI8hLDBzD9oY5WedUVnCHXJXxA3HSHaVuyahT22X_TNBqK3RA=w120-h120-p-rp-mo-br100',
        feedback: 'Good service I got my license within 3 days.employees are also very polite. Loved the work. Thank you go legal india.'
    },
    {
        name: 'GokulPrasad Thangavel',
        company: 'Upcelerators Technologies Pvt Ltd | Incorporation',
        image: 'https://lh3.googleusercontent.com/a-/ALV-UjUSH6On0iGGAzWV6J-aEOCxrTg0dEqAyAsuE4Qn8N8PQkKNMsE=w72-h72-p-rp-mo-br100',
        feedback: 'The way they respond to their clients is just amazing . I just got my work done perfectly with no effort becoz of this outstanding team . Would recommend this to everyone . Keep growing ..'
    },
    {
        name: 'Buddy NattuRious',
        company: 'Upcelerators Technologies Pvt Ltd | Incorporation',
        image: 'https://lh3.googleusercontent.com/a-/ALV-UjVTeHHG1Y2a1eYkGaeYjGhavk5IO1T7ihQOOSDv5GAoFykO3KfZ=w120-h120-p-rp-mo-br100',
        feedback: 'Out of all the people I talked to, got the best and quickest with the most reasonably charged counsel and service from Go Legal. With quick WhatsApp and call reply, received the best advice too. Highly recommended regardless of state and city.'
    },
    // add more testimonials as needed
];

const NextArrow = ({ onClick }) => {
    return (
        <div
            className='absolute top-[40%] cursor-pointer z-10 right-[-9%]  lg:right-[-5%]'
            onClick={onClick}
        >
            <img src={rightArrowIcon} height={32} width={32} />
        </div>
    );
}

const PrevArrow = ({ onClick }) => {
    return (
        <div
            className='absolute top-[40%] cursor-pointer z-10 left-[-9%]  lg:left-[-5%]'
            onClick={onClick}
        >
            <img src={leftArrowIcon} height={32} width={32} />
        </div>
    );
}

const Carousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className='px-12 md:px-16 py-16  w-full' style={{ backgroundColor: themeStyle.COLOR_LIGHT_GREY }}>
            <div style={{ margin: '20px auto', }}>
                <h2 className='text-black text-4xl font-bold mb-3 text-center'>What people say about us</h2>
                    <img
                        src={quote}
                        // alt={testimonial.name}
                        className='w-14'
                        width={20}
                    />
                <div className='mb-16 md:mx-16'>
                    <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                        <div key={index}>
                            <div className="h-[300px] md:max-h-[400px] bg-white p-4 m-3 rounded-xl overflow-hidden shadow-md">
                                <div className="flex items-center mb-4">
                                    <img
                                        src={testimonial.image}
                                        alt={testimonial.name}
                                        className="w-16 h-16 rounded-full mr-4"
                                    />
                                    <div>
                                        <p className="font-bold text-md">{testimonial.name}</p>
                                        {/* <p className="text-sm text-gray-600 pb-1">{testimonial.company}</p> */}
                                        <div className='flex '>
                                            <LiaStarSolid color={themeStyle.COLOR_PRIMARY} />
                                            <LiaStarSolid color={themeStyle.COLOR_PRIMARY} />
                                            <LiaStarSolid color={themeStyle.COLOR_PRIMARY} />
                                            <LiaStarSolid color={themeStyle.COLOR_PRIMARY} />
                                            <LiaStarSolid color={themeStyle.COLOR_PRIMARY} />
                                        </div>
                                    </div>
                                </div>
                                <p className="text-gray-800 leading-relaxed">{testimonial.feedback}</p>
                            </div>
                        </div>
                    ))}
                </Slider></div>
            </div>
        </div>
    );
}

export default Carousel;
