import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";

function SidebarSubmenu({ list, toggleSidebar }) {
  const [openIndex, setOpenIndex] = useState(null);

  const AccordionItem = ({ index, title, submenu, isOpen, onClick }) => {
    const [height, setHeight] = useState("0px");
    const contentRef = useRef(null);

    useEffect(() => {
      if (isOpen) {
        setHeight(`${contentRef.current.scrollHeight}px`);
      } else {
        setHeight("0px");
      }
    }, [isOpen]);

    return (
      submenu?.length > 0 && (
        <div className="mb-2">
          <div
            className="cursor-pointer flex justify-between gap-5 items-center"
            onClick={() => onClick(index)}
          >
            <span className="text-md font-semibold hover:text-red-600">
              {title}
            </span>
            <span className="text-red-600">{isOpen ? "−" : "+"}</span>
          </div>
          <div
            ref={contentRef}
            style={{ maxHeight: height }}
            className="overflow-hidden transition-all duration-300 ease-in-out"
          >
            <div className="p-4 pt-0">
              {submenu?.map((item, index) => (
                <NavLink
                  className="block text-gray-800 text-sm font-semibold pt-3 hover:text-red-600"
                  to={`${item.navigation}`}
                  onClick={toggleSidebar}
                >
                  {item.title}
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      )
    );
  };

  const handleClick = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div>
      <div className="gap-4">
        {list?.map(
          (item, index) =>
            item && (
              <AccordionItem
                key={index}
                index={index}
                title={item.title}
                submenu={item.submenu}
                isOpen={openIndex === index}
                onClick={handleClick}
              />
            )
        )}
      </div>
    </div>
  );
}

export default SidebarSubmenu;
