export const LLPOverview = () => {
    return (
        <div>
            <div className="font-bold text-lg">Limited Liability Partnership Registration Online – An Overview</div>
            <div className="mt-5">
                One of the most highly recommended methods for starting a business in India is to establish a private limited company, which provides its shareholders with limited liability while imposing certain ownership restrictions. When it is LLP, the partners will manage it. On the other hand, a private limited company allows for directors and shareholders to be separate entities.
            </div>
        </div>
    )
}

export const LLPBenefit = () => {

    const benefits = [
        { title: 'Benefits of a Limited Liability Partnership', content: 'Here are four major reasons why people tend to choose LLP as their business model:' },
        { title: 'Limited Liability', content: 'The members of an LLP are only liable for a small amount of debt incurred by the firm. In case of bankruptcy, the personal assets of the partners will not be taken into account. On the other hand, for proprietorships and partnerships, the personal assets of directors and partners will be seized if the business goes bankrupt.' },
        { title: 'Separate Legal Entity', content: 'An LLP is a separate legal entity from the partners in it. It has an uninterrupted existence that follows perpetual succession, i.e., the partners might leave, but the business remains. The terms of dissolution have to be mutually agreed upon for the firm to dissolve.' },
        { title: 'Flexible Agreement', content: 'Transferring the ownership of an LLP is also simple. A person can easily be inducted as DESIGNATE PARTNER IN LLP and the ownership is transferred to them.' },
        { title: 'Suitable For Small Business', content: 'LLPs having a capital amount of less than ?25 lakhs and turnover below ?40 lakhs per year do not require any formal audits. This makes registering as an LLP beneficial for small businesses and startups.' },
    ]
    return (
        <div>
            {benefits?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg">{item.title}</div>
                    <div className="my-4">{item.content}</div>
                </div>
            ))}
        </div>
    )
}


export const LLPRequirement = () => {

    const steps = [
        {
            title: "How to Register an LLP Online with the US",
            content: "You can register LLP online through Legal Bharat Services. While we make LLP registration a simple 3-step process for you, the actual registration process is elaborate and is explained below for your knowledge:"
        },
        {
            title: "Step 1: Obtaining DSC And DIN",
            content: "All the forms that need to be submitted online require the directors’ DSC. So, the first step in the process is to get DSCs and DINs for 2 partners. We collect the necessary information from you and file it on your behalf."
        },
        {
            title: "Step 2: Application For Name Approval",
            content: "Simultaneously, we check if the name you want to register under is available and reserve it for your LLP. You can check for name availability in the MCA portal.\n\nThe approval of the name will be made by the registrar only if the central government does not deem it undesirable. The name should not hold any resemblance to any of the existing partnership firms, LLPs, trademarks, or body corporates."
        },
        {
            title: "Step 3: LLP Agreement",
            content: "The next step is to draft the LLP agreement and other documents for registration. An LLP agreement is very crucial in a limited liability partnership as it determines the mutual rights and duties amongst the partners and between the LLP and the partners. Thus, our experts take utmost care in drafting this agreement.\n\nThe partners enter into the LLP agreement upon registering the LLP by filing Form 3 online on the MCA portal. This procedure has to be done within 30 days of the date of incorporation."
        },
        {
            title: "Step 4: LLP Incorporation Certificate",
            content: "Our team will file the necessary forms and documents with the registrar. Once the registrar approves all the forms and documents, you get your LLP incorporation certificate and are almost set for running your business."
        },
        {
            title: "Step 5: Apply for Your PAN, TAN, & Bank Account",
            content: "As soon as you get the incorporation certificate, we will apply for the PAN, TAN, and bank account for your LLP."
        }
    ];

    const indiaPackage = ['DSCs for 2 directors', 'DINs for 2 directors', 'Drafting of MoA & AoA', 'Drafting partnership agreement', 'Registration fees and stamp duty', 'Company incorporation certificate', 'PAN and TAN registration.']

    const assist = ['A first free consultation, followed by subsequent support to clear every concern you may face', 'Complete support on opening a current bank account', 'Comprehensive and on-time updates on ROC compliances', 'Online accounting software valid for one year', 'A master file that contains all the documentation needed to file the incorporation', 'You will also get a zero-balance current account – powered by DBS Bank.']

    return (
        <div>
            {steps?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg">{item.title}</div>
                    <div className="my-4">{item.content}</div>
                </div>
            ))}

            <div>The following are included in the LBS LLP Registration in India package:</div>
            <div className="ml-8 mt-4">
                <ul style={{ listStyle: 'number' }}>
                    {indiaPackage?.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>

            <div className="mt-4">We also assist with the following to register an LLP company in India:</div>
            <div className="ml-8 mt-4">
                <ul style={{ listStyle: 'number' }}>
                    {assist?.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export const LLPTypes = () => {

    const typesOfLLPAgreements = [
        {
            title: "Equal Rights LLP",
            content: "In such a type of LLP, all partners mutually work together they share equal profit or loss of the company. They contribute equal capital, time, and energy to the LLP. All the partners have the same rights and contribute equally to the management of the LLP."
        },
        {
            title: "Differential Rights LLP",
            content: "In such type of LLP, it is opposite to equal rights LLP. They have different amounts of contribution in terms of capital, energy, and time. They have different profit-sharing, decision-making, and managerial rights."
        },
        {
            title: "Board Managed LLP",
            content: "In such type of LLP, management is done by forming a board of partners similar to the board of directors in the company. The partners have the day-to-day managerial and operational, decision-making power rest in the hand of the board/committee of partners."
        },
        {
            title: "Husband & Wife LLP",
            content: "In such a type of LLP if Husband and wife are running LLP, then a special agreement related to tax liability can be made so as to minimize the family tax liability. Besides, they can choose any of the LLPs according to their convenience."
        },
        {
            title: "Differential Rights and Differential Powers LLP",
            content: "In such type of LLP, partners hold different rights and powers. Some may be just investing while others may be holding managerial responsibilities. In order to avoid miscommunication and conflict, the rights and power of partners must be well-defined and agreed on."
        },
        {
            title: "Absolute Rights LLP",
            content: "In this type of LLP, there are only two partners, and one of them is appointed as the nominee or only as the investor then the LLP agreement will be drafted in such a way that one person will get all the management and decision making power."
        },
        {
            title: "Manager Managed LLP",
            content: "In such a type of LLP, partners appoint the manager and give him powers related to the administration, management, and operations of the company. The role of the partner is limited as an investor and does not have any decision-making power on day to day activities of the company."
        }
    ];


    return (
        <div>
            {typesOfLLPAgreements?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg">{item.title}</div>
                    <div className="my-4">{item.content}</div>
                </div>
            ))}
        </div>
    )
}

export const LLPDocuments = () => {

    const documentsRequired = [
        {
            title: "Documents of Partners",
            content: [
                {
                    subtitle: "ID Proof of Partners",
                    description: "All the partners are required to provide their PAN at the time of registering LLP. PAN card acts as a primary ID proof."
                },
                {
                    subtitle: "Address Proof of Partners",
                    description: "Partner can submit any one document out of Voter’s ID, Passport, Driver’s license, or Aadhar Card. The name and other details as per address proof and PAN card should be exactly the same. If the spelling of own name or father’s name or date of birth is different in the address proof and PAN card, it should be corrected before submitting to RoC."
                },
                {
                    subtitle: "Residence Proof of Partners",
                    description: "Latest bank statement, telephone bill, mobile bill, electricity bill, or gas bill should be submitted as residence proof. Such a bill or statement shouldn’t be more than 2 months old and must contain the name of the partner as mentioned in the PAN card."
                },
                {
                    subtitle: "Photograph",
                    description: "Partners should also provide their passport-size photograph, preferably on white background."
                },
                {
                    subtitle: "Passport (in case of Foreign Nationals/ NRIs)",
                    description: "For becoming a partner in Indian LLP, foreign nationals and NRIs have to submit their passport compulsorily. The passport has to be notarised or apostilled by the relevant authorities in the country of such foreign nationals and NRI, or else Indian Embassy situated in that country can also sign the documents.\n\nForeign Nationals or NRIs have to submit proof of address also which will be a driving license, bank statement, residence card, or any government-issued identity proof containing the address.\n\nIf the documents are in other than the English language, a notarised or apostilled translation copy will also be attached."
                }
            ]
        },
        {
            title: "Documents of LLP",
            content: [
                {
                    subtitle: "Proof of Registered Office Address",
                    description: "Proof of registered office has to be submitted during registration, or within 30 days of its incorporation.\n\nIf the registered office is taken on rent, a rent agreement and a no objection certificate from the landlord have to be submitted. No objection certificate will be the consent of the landlord to allow the LLP to use the place as a ‘registered office’.\n\nBesides, anyone documents out of utility bills like gas, electricity, or telephone bill must be submitted. The bill should contain the complete address of the premise and the owner’s name and the document shouldn’t be older than 2 months."
                },
                {
                    subtitle: "Digital Signature Certificate",
                    description: "One of the designated partners needs to opt for a digital signature certificate also since all documents and applications will be digitally signed by the authorized signatory."
                }
            ]
        }
    ];


    return (
        <div>
            {documentsRequired?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg my-5">{item.title}</div>
                    {item.content?.map((c) => (
                        <div className="mt-5">
                            <div className="font-bold">{c.subtitle}</div>
                            <div>{c.description}</div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}