export const GSTReturnFiingOverview = () => {

  const overview1 = [
    {
      title: "Types of GST Returns",
      content: "There are multiple types of GST returns that taxpayers need to file based on their business category and turnover. Some of the common GST returns include GSTR-1, GSTR-3B, GSTR-4, GSTR-9, etc."
    },
    {
      title: "GSTR-1",
      content: "GSTR-1 is the monthly or quarterly return for outward supplies (sales) made by the taxpayer. It includes details of all sales transactions along with the corresponding tax collected."
    },
    {
      title: "GSTR-3B",
      content: "GSTR-3B is a monthly summary return, where taxpayers provide summarized details of their outward supplies, inward supplies, and net tax liability. It is a self-declared return filed before the 20th of the following month."
    },
    {
      title: "GSTR-4",
      content: "GSTR-4 is a quarterly return filed by taxpayers opting for the Composition Scheme. It includes details of outward supplies and tax payments."
    },
    {
      title: "GSTR-9",
      content: "GSTR-9 is an annual return that provides a summary of all the monthly/quarterly returns filed during the financial year."
    },
    {
      title: "Due Dates for GST Return Filing",
      content: "The due dates for GST return filing vary based on the type of return and the taxpayer's turnover. GSTR-1 is usually due on the 11th of the following month, GSTR-3B is due on the 20th of the following month, and GSTR-9 is due on or before December 31st of the next financial year."
    },
    {
      title: "Input Tax Credit (ITC) Reconciliation",
      content: "Taxpayers need to reconcile the input tax credit claimed on purchases with the details furnished by their suppliers (GSTR-2A) and take corrective actions if necessary."
    },
    {
      title: "Late Fees and Penalties",
      content: "Failure to file GST returns on time may attract late fees and penalties. The late fee is generally Rs. 50 per day (Rs. 25 for nil returns) for each day of delay."
    },
    {
      title: "Nil Return Filing",
      content: "Even if there are no transactions during a specific period, taxpayers are required to file nil GST returns to comply with the law."
    },
    {
      title: "E-Filing and Digital Signature",
      content: "GST return filing is done electronically on the GSTN portal. For certain returns, such as GSTR-9 and GSTR-9C, a digital signature is required."
    }
  ];

  return (
    <div>
      <div><b>GST return filing</b> is the process by which businesses registered under the Goods and Services Tax (GST) system in India report their sales, purchases, and tax liabilities to the government. Under the GST regime, registered taxpayers are required to file regular GST returns, providing details of their business transactions for a specific period. GST return filing is done electronically through the Goods and Services Tax Network (GSTN) portal.</div>
      <div className="my-3">Here are the key points related to GST return filing:</div>
      <div className="mt-5 mb-10 ml-10">
        {overview1?.map((item, index) => (
          <div key={index}>
            <div className="font-bold text-lg">{item.title}</div>
            <div className="my-4">{item.content}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export const GSTReturnFilingBenefit = () => {
  const overview2 = [
    {
      title: "Compliance with the Law",
      content: "Filing GST returns is a legal requirement for all registered taxpayers. By adhering to the filing deadlines and procedures, businesses stay compliant with the Goods and Services Tax Act."
    },
    {
      title: "Input Tax Credit (ITC) Claim",
      content: "Regular GST return filing allows businesses to claim Input Tax Credit on the GST paid on purchases. This helps reduce the overall tax liability and improves cash flow."
    },
    {
      title: "Transparent Taxation",
      content: "GST return filing promotes transparency in tax reporting. It provides a clear record of all business transactions, which helps in tax assessment and ensures proper tax collection."
    },
    {
      title: "Simplified Tax Process",
      content: "The GST return filing process is relatively streamlined and user-friendly, especially with the help of technology and the GSTN portal. This makes tax compliance more manageable for businesses."
    },
    {
      title: "Better Business Insights",
      content: "Filing GST returns involves providing detailed information about sales, purchases, and tax liabilities. Analyzing these figures can offer valuable insights into business performance and financial health."
    },
    {
      title: "Easy ITC Reconciliation",
      content: "Regular GST return filing enables businesses to reconcile the Input Tax Credit claimed with the details provided by their suppliers (GSTR-2A). This helps identify any discrepancies and rectify them promptly."
    },
    {
      title: "Avail Government Benefits",
      content: "Compliance with GST return filing requirements is often a prerequisite for businesses to access various government benefits, incentives, and subsidies."
    },
    {
      title: "Avoid Penalties and Late Fees",
      content: "Timely filing of GST returns helps businesses avoid penalties and late fees, which are imposed on delayed or non-filing of returns."
    },
    {
      title: "Building Trust with Customers",
      content: "Regular GST return filing enhances a business's credibility and trustworthiness in the eyes of customers, suppliers, and stakeholders."
    },
    {
      title: "Easy Refund Process",
      content: "For exporters or businesses eligible for GST refunds, regular and accurate GST return filing facilitates a smoother refund process."
    },
    {
      title: "National Integration",
      content: "GST return filing promotes a unified tax structure across the country, fostering national integration and facilitating seamless interstate trade."
    },
    {
      title: "Improved Tax Collection",
      content: "By ensuring proper reporting and payment of taxes, GST return filing contributes to increased tax collection, benefiting the government's revenue."
    }
  ];

  return (
    <>
      <div className="mb-8"><b>Filing GST returns offers several benefits</b> to businesses and the government alike. Here are the key advantages of GST return filing:</div>
      <div className="mt-2 mb-10 ml-10">
        {overview2?.map((item, index) => (
          <div key={index}>
            <div className="font-bold text-lg">{item.title}</div>
            <div className="my-4">{item.content}</div>
          </div>
        ))}
      </div>
    </>
  )
}


export const GSTReturnFilingRequirement = () => {

  const overview3 = [
    {
      title: "Mandatory for Registered Taxpayers",
      content: "Any business or individual whose turnover exceeds the threshold limit for GST registration (Rs. 40 lakhs for most states, Rs. 10 lakhs for special category states) must register for GST and file regular GST returns."
    },
    {
      title: "Frequency of GST Return Filing",
      content: "The frequency of GST return filing depends on the type of taxpayer and the type of return. For most regular taxpayers, GSTR-3B (monthly summary return) is filed on a monthly basis. GSTR-1 (details of outward supplies) is filed either monthly or quarterly, based on the turnover."
    },
    {
      title: "Nil Return Filing",
      content: "Even if there are no business transactions during a specific period, taxpayers are required to file nil GST returns to stay compliant with the law."
    },
    {
      title: "Input Tax Credit (ITC) Reconciliation",
      content: "Regular GST return filing enables taxpayers to claim Input Tax Credit on their purchases. It also facilitates the reconciliation of ITC claimed with the details provided by suppliers (GSTR-2A)."
    },
    {
      title: "Timely Filing",
      content: "GST returns must be filed within the prescribed due dates to avoid penalties and late fees. Failing to file GST returns on time may lead to penalties of Rs. 50 per day (Rs. 25 for nil returns) for each day of delay."
    },
    {
      title: "Correct Information",
      content: "Taxpayers must provide accurate and correct information in their GST returns, as any discrepancies or errors may lead to further scrutiny by tax authorities."
    },
    {
      title: "Digital Filing",
      content: "GST returns are filed electronically on the Goods and Services Tax Network (GSTN) portal. Taxpayers may need a digital signature certificate (DSC) for certain returns."
    },
    {
      title: "Compliance with GSTR-9: Annual Return",
      content: "All regular taxpayers are required to file GSTR-9, the annual return, by December 31 of the following financial year. This return provides a summary of all monthly/quarterly returns filed during the financial year."
    },
    {
      title: "Compliance with GSTR-9C",
      content: "Taxpayers with an annual turnover exceeding Rs. 2 crores are required to get their accounts audited and file GSTR-9C, the reconciliation statement, along with the annual return."
    }
  ];

  return (
    <>
      <div className="mb-8"><b>The requirement of GST return filing</b> is mandatory for all businesses and individuals who are registered under the Goods and Services Tax (GST) system in India. GST return filing involves providing details of business transactions, including sales, purchases, and tax liability, to the government. Here are the key requirements of GST return filing:</div>
      <div className="mt-2 mb-10 ml-10">
        {overview3?.map((item, index) => (
          <div key={index}>
            <div className="font-bold text-lg">{item.title}</div>
            <div className="my-4">{item.content}</div>
          </div>
        ))}
      </div>
    </>
  )
}


export const GSTReturnFilingTypes = () => {

  const overview4 = [
    {
      title: "GSTR-1 (Outward Supplies Return)",
      content: "GSTR-1 is a monthly or quarterly return filed by regular taxpayers to furnish details of their outward supplies of goods and services. It includes information about sales made to registered taxpayers, unregistered persons, and consumers."
    },
    {
      title: "GSTR-3B (Summary Return)",
      content: "GSTR-3B is a monthly summary return filed by regular taxpayers to report their summarized tax liability for the month. It is a self-declaration return and helps in the payment of taxes."
    },
    {
      title: "GSTR-4 (Composition Scheme Return)",
      content: "GSTR-4 is a quarterly return filed by taxpayers registered under the Composition Scheme. It provides details of outward supplies and tax payments."
    },
    {
      title: "GSTR-5 (Non-Resident Taxpayer Return)",
      content: "GSTR-5 is a monthly return filed by non-resident taxpayers who engage in taxable supplies in India. It includes details of supplies and taxes paid."
    },
    {
      title: "GSTR-6 (Input Service Distributor Return)",
      content: "GSTR-6 is a monthly return filed by Input Service Distributors (ISDs) to distribute the input tax credit (ITC) to their branches or units."
    },
    {
      title: "GSTR-7 (TDS Return)",
      content: "GSTR-7 is a monthly return filed by taxpayers required to deduct Tax Deducted at Source (TDS) on payments made to suppliers. It provides details of TDS deducted and deposited."
    },
    {
      title: "GSTR-8 (TCS Return)",
      content: "GSTR-8 is a monthly return filed by e-commerce operators who are required to collect Tax Collected at Source (TCS) on certain transactions. It includes details of supplies and TCS collected."
    },
    {
      title: "GSTR-9 (Annual Return)",
      content: "GSTR-9 is an annual return filed by regular taxpayers to provide a consolidated summary of all the monthly/quarterly returns filed during the financial year."
    },
    {
      title: "GSTR-9A (Composition Scheme Annual Return)",
      content: "GSTR-9A is an annual return filed by taxpayers registered under the Composition Scheme to provide a summary of quarterly returns."
    },
    {
      title: "GSTR-9C (Reconciliation Statement)",
      content: "GSTR-9C is an annual reconciliation statement filed by taxpayers whose annual turnover exceeds Rs. 2 crores. It includes a reconciliation of the audited financial statements with the GST returns filed."
    }
  ];

  return (
    <>
      <div className="mb-8">In India, <b>there are various types of GST return filings</b> that taxpayers need to submit based on their business category, turnover, and compliance requirements. The main types of GST return filings are as follows:</div>
      <div className="mt-2 mb-10 ml-10">
        {overview4?.map((item, index) => (
          <div key={index}>
            <div className="font-bold text-lg">{item.title}</div>
            <div className="my-4">{item.content}</div>
          </div>
        ))}
      </div>
    </>
  )
}


export const GSTReturnFilingDocuments= () => {

  const overview5 = [
    {
      title: "Sales Invoices",
      content: "Invoices issued for the outward supply of goods or services should be maintained. These invoices should contain all the necessary details, including the GSTIN of the recipient (if registered), description of goods/services, quantity, value, and applicable tax rates."
    },
    {
      title: "Purchase Invoices",
      content: "Invoices received for the inward supply of goods or services should also be kept for reference. These invoices should contain the GSTIN of the supplier (if registered), description of goods/services, quantity, value, and applicable tax rates."
    },
    {
      title: "Payment Vouchers",
      content: "Documents supporting the payment made for goods or services, such as payment vouchers, bank statements, or electronic fund transfer (EFT) details, should be retained."
    },
    {
      title: "Input Tax Credit (ITC) Documents",
      content: "Documents related to eligible ITC, such as invoices, debit notes, and tax payment challans, should be maintained to claim ITC accurately."
    },
    {
      title: "Tax Payment Challans",
      content: "Challans or proof of electronic payment of GST, including the Integrated Goods and Services Tax (IGST), Central Goods and Services Tax (CGST), and State Goods and Services Tax (SGST), should be kept as evidence of tax payment."
    },
    {
      title: "Export Documentation",
      content: "In case of export transactions, export invoices, shipping bills, and other relevant export documents should be retained for claiming GST refunds."
    },
    {
      title: "Reverse Charge Mechanism (RCM) Records",
      content: "Records of transactions where a reverse charge is applicable, along with payment details of tax under RCM, should be maintained."
    },
    {
      title: "E-way Bills",
      content: "For businesses involved in the movement of goods, e-way bills and relevant supporting documents should be retained."
    },
    {
      title: "Bank Statements",
      content: "Bank statements and other financial records can be useful for reconciling transactions and cross-verifying the information in GST returns."
    },
    {
      title: "Reconciliation Statements",
      content: "For taxpayers required to file GSTR-9C (Reconciliation Statement), relevant reconciliation documents and working papers should be prepared."
    }
  ];

  return (
    <>
      <div className="mb-8">While filing GST returns in India, taxpayers do not need to submit any physical documents. However, they are required to maintain proper records and documentation to support the information provided in their GST returns. The documents required for GST return filing may vary based on the nature of the business and the type of transactions conducted. Here are some common documents that businesses should keep ready while preparing their GST returns:</div>
      <div className="mt-2 mb-10 ml-10">
        {overview5?.map((item, index) => (
          <div key={index}>
            <div className="font-bold text-lg">{item.title}</div>
            <div className="my-4">{item.content}</div>
          </div>
        ))}
      </div>
    </>
  )
}

