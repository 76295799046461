import React from 'react'
import TopBannerSection from '../../components/TopBannerSection'
import { TfiHeadphoneAlt } from 'react-icons/tfi'
import themeStyle from '../../styles/theme.style'
import { HiDocumentArrowDown } from 'react-icons/hi2'
import { FaCheck } from 'react-icons/fa'
import ProcessRoundCircleSection from '../../components/ProcessRoundCircleSection'
import CustomTabs from '../../components/CustomTabs'
import { RenewalBenefit, RenewalDocument, RenewalOverview, RenewalRequirement, RenewalTypes } from '../Lisence/content/RenewalTabContent'
import PageFaq from '../../components/PageFaq'
import OverviewContent from '../../components/OverviewContent'
import BackgroundImage from "../../assets/images/License/banner.jpg"
import OverViewImage from "../../assets/images/License/over view.jpg";
import { useLocation } from 'react-router-dom'
import AuthorizedPartner from '../../components/AuthorisedPartner'
function FssaiRenewal() {
  const topImageUrl = "https://st3.depositphotos.com/30987588/34891/v/450/depositphotos_348913014-stock-illustration-people-in-cozy-cafe-coffee.jpg";

  const data = ['Renew Food License', 'Get License Renewed Same Day', 'We will file your returns', 'Complete all other compliances']

  const process = [
    { title: 'Reach out to our Experts', icon: <TfiHeadphoneAlt size={46} color={themeStyle.COLOR_RED} />, description: 'We have the best business experts who can resolve all your queries' },
    { title: 'Provide all the required documents', icon: <HiDocumentArrowDown size={46} color={themeStyle.COLOR_RED} />, description: 'Provide all the documents. Our team will initiate the paperwork on your behalf' },
    { title: 'Get your Fssai renewal done', icon: <FaCheck size={46} color={themeStyle.COLOR_RED} />, description: 'Registration is completed and the certificates will be sent to you.' },
  ]

  const tabs = [
    // { title: "Overview", content: <RenewalOverview /> },
    { title: "Benefit", content: <RenewalBenefit/> },
    { title: "Requirement", content: <RenewalRequirement/> },
    { title: "Types", content: <RenewalTypes/>},
    { title: "Document required", content: <RenewalDocument/> },
  ];

  const faqs = [
    {
      title: 'When should I renew my FSSAI license?',
      content: 'You should start the renewal process well before your current FSSAI license expires to ensure continuous compliance. The exact renewal timing depends on the type of license and its validity period.',
    },
    {
      title: 'How do I renew my FSSAI license?',
      content: 'FSSAI license renewal can typically be done online through Us',
    },
    {
      title: 'What documents are required for FSSAI renewal?',
      content: 'Common documents required for renewal include a filled and signed renewal application form (Form A/Form B), a copy of your current/previous FSSAI license, personal identification documents, and, in some cases, a Food Safety Management System (FSMS) plan.',
    },
    {
      title: 'How much does it cost to renew an FSSAI license?',
      content: 'Renewal fees vary based on the type of license and the duration of renewal. The specific fees can be found on the FSSAI website or by contacting your local FSSAI office.',
    },
  ];

  const location = useLocation();
  return (
    <div>
            {/* <div className="mt-10">
        <AuthorizedPartner />
      </div> */}
      <TopBannerSection location={'fssai-renewal'} BackgroundImage={BackgroundImage} heading={'Renew Your FSSAI Food License'} lineThrough={'₹1999'} boldNumber={'₹999'} list={data ?? []} />
      <ProcessRoundCircleSection heading={'How To Do Your Fssai Renewal  Through "GLI"'} process={process} />
      <OverviewContent OverViewImage={OverViewImage} content={ <RenewalOverview /> }/>
      <CustomTabs tabs={tabs} />
      <PageFaq list={faqs}/>
    </div>
  )
}

export default FssaiRenewal