import React, { useEffect } from 'react'
import themeStyle from '../../styles/theme.style'
import { companyName } from '../../Config/config';

function PrivacyPolicy() {
  useEffect(() => {
    document.title = "Privacy Policy | " + companyName;
  }, []);
  return (
    <div className='md:mx-28 md:my-20 m-12'>
      <div>
        <h1 className='font-bold text-4xl'>Privacy Policy</h1>
        <h1 className='font-semibold text-lg mt-5 '>1. GENERAL</h1>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>a) This document is an electronic record under the Information Technology Act, 2000 and its applicable rules, including amendments. It is generated by a computer system and does not need physical or digital signatures.</p>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>b) This document is published according to Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011, which require publishing rules, regulations, privacy policy, and Terms of Use for accessing or using golegalindia.net.</p>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>c) The domain www.golegalindia.net (“Website”) is owned and operated by Go Legal India, located at 33H/3 Mominpore Road, Kolkata, West Bengal - 700023. This includes its representatives, administrators, and employees.</p>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>d) For this Privacy Policy (“Policy”):</p>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4 ml-4'>  i) ‘You’ or ‘User’ refers to any person or entity using the Website’s services, who is legally able to enter binding contracts under the Indian Contract Act, 1872.</p>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4 ml-4'>   ii) ‘We’, ‘Us’ or ‘Our’ refers to the Website.</p>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4 ml-4'>   iii) ‘Party’ or ‘Parties’ refer to the User and the Website individually and collectively.</p>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>e) Section headings in this Policy are for organization only and have no legal or contractual significance.</p>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>f) Use of the Website is governed by this Policy and the Terms of Use (“Terms”, available at www.golegalindia.net), including any changes made by the Website. By visiting the Website or using its services, the User accepts this Policy and the Terms. The Terms and Policy are co-terminus, meaning expiration or termination of one results in termination of the other.</p>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>g) The User agrees that this Policy and the Terms form a legally binding agreement with the Website. The User is subject to the rules, guidelines, policies, and terms applicable to any service provided by the Website, which are incorporated into the Terms. No signature is required to make these binding; visiting any part of the Website signifies full acceptance.</p>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>h) The Website can amend or modify the Policy and Terms at any time without prior notice. Such changes take effect immediately. Users must periodically review the Policy and Terms to stay informed. Continued use of the Website indicates acceptance of any changes. The User is granted a personal, non-exclusive, non-transferable, revocable, limited right to access and use the Website, provided they comply with the Policy and Terms.
        </p>
      </div>
      <div>
        <h1 className='font-semibold text-lg mt-5 '>2. COLLECTION OF PERSONAL AND OTHER INFORMATION</h1>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>a) The User agrees that the Website collects and stores personal information provided by the User, such as username, passwords, email address, name, address, age, date of birth, gender, nationality, shopping preferences, browsing history, and any uploaded images. This information is used to provide personalized services and improve the Website for a better user experience.</p>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>b) The User consents to the Website automatically tracking information based on the User’s IP address and activity on the Website. This data helps the Website understand user demographics, interests, and behavior to improve services. Tracked information may include URLs visited before and after the Website, computer and browser details, and IP address.</p>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>c) By purchasing products/services from the Website, the User consents to the collection of information about their buying behavior and trends.
        </p>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>d) The User acknowledges that any messages, reviews, or feedback posted on the Website will be collected and stored indefinitely. This information may be used to resolve disputes, provide customer support, and troubleshoot problems.
        </p>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>e) The User understands that some sections of the Website can be browsed without registration, but activities like placing orders require providing valid personal information. Contact information may be used to send offers and promotions based on the User’s past orders and interests, and the User consents to receive these communications.</p>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>f) The Website may occasionally ask the User to complete optional surveys requiring contact and demographic information (e.g., zip code, age, income, gender). This data helps customize the Website and provide relevant products, services, and content.
        </p>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>g) The User agrees that the Website may request reviews for products/services purchased. These reviews help other users make informed decisions and help the Website identify unsatisfactory sellers. The User authorizes the Website to publish these reviews, along with the User’s name and some contact details, for other users’ benefit.</p>
      </div>
      <div>
        <h1 className='font-semibold text-lg mt-5 '>3. COOKIES</h1>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>a) The User understands that cookies are small pieces of data stored by a web server on a web browser to track information specific to the User, such as login details and passwords. The User consents to the Website placing both permanent and temporary cookies on their computer and browser.
        </p>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>b) The User is aware that the Website uses cookies on certain pages to analyze page traffic, measure promotional effectiveness, and enhance trust and safety. Some features of the Website are available only through these cookies. The User can decline cookies if their browser allows, but this may limit access to certain Website features</p>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>c) The User acknowledges that third parties or affiliates may place cookies or similar devices on some Website pages. The Website does not control these cookies and is not responsible for them. The User assumes all risks related to third-party cookies.
        </p>
      </div>
      <div>
        <h1 className='font-semibold text-lg mt-5 '>4. SHARING OF PERSONAL INFORMATION</h1>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>a) The User understands that the Website may share personal information with other companies and affiliates to prevent identity theft, fraud, and other illegal activities; to link related accounts and prevent service abuse; and to provide joint or co-branded services.
        </p>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>b) The User acknowledges that the Website may disclose personal information if required by law or if it believes such disclosure is necessary to respond to legal requests like subpoenas or court orders. The Website may also share information with law enforcement, third-party rights owners, or others to enforce the Terms or Policy, address claims of rights violations, or protect the rights, property, or safety of users or the public.
        </p>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>c) The User understands that the Website and its affiliates may share or sell personal information if the Website merges with, is acquired by, or undergoes reorganization with another business. The new entity will be bound by the existing Terms and Policy, as updated.
        </p>
      </div>
      <div>
        <h1 className='font-semibold text-lg mt-5 '>5. SECURITY</h1>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>The Website ensures that all transactions are secure and protected. Any information entered by the User during transactions is encrypted to prevent unauthorized access and accidental disclosure to third parties. The Website does not receive, store, or retain the User’s credit and debit card information. This sensitive information is directly provided by the User to authorized payment gateways. These gateways comply with the stringent regulations and standards of banks, financial institutions, and payment franchises, ensuring the secure handling of financial information. This allows Users to conduct transactions confidently and safely.

        </p>
      </div>
      <div>
        <h1 className='font-semibold text-lg mt-5 '>6. USER’S CONSENT</h1>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>When using the Website or providing information through it, the User agrees to the collection and use of the disclosed information in accordance with this Policy. This includes the User’s consent to the Website sharing or divulging their information as outlined in Section 4 of the Policy above. </p>
      </div>
      <div>
        <h1 className='font-semibold text-lg mt-5 '>7. DISPUTE RESOLUTION AND JURISDICTION</h1>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className='mt-4'>If a dispute arises between the parties, they will first attempt to resolve it amicably within thirty (30) days of one party notifying the other party of the dispute. If no resolution is reached within this timeframe, the dispute will be settled through arbitration.
          Both parties agree that any agreements between them, including the Terms and Policy, are governed by Indian laws. Any disputes will be exclusively heard and resolved by the Courts in Kolkata, West Bengal.
        </p>
      </div>
    </div>
  )
}

export default PrivacyPolicy