import React from 'react'
import TopBannerSection from '../../components/TopBannerSection'
import ProcessRoundCircleSection from '../../components/ProcessRoundCircleSection'
import { TfiHeadphoneAlt } from 'react-icons/tfi'
import { HiDocumentArrowDown } from 'react-icons/hi2'
import { FaCheck } from 'react-icons/fa'
import themeStyle from '../../styles/theme.style'
import { LLPBenefit, LLPDocuments, LLPOverview, LLPRequirement, LLPTypes } from './LLPTabContent'
import CustomTabs from '../../components/CustomTabs'
import PageFaq from '../../components/PageFaq'
import OverviewContent from '../../components/OverviewContent'
import BackgroundImage from "../../assets/images/Private Limited Company/banner.jpg"
import OverViewImage from "../../assets/images/Private Limited Company/Over View.jpg";
import { useLocation } from 'react-router-dom'
function LLPRegistration() {
  const topImageUrl = "https://st3.depositphotos.com/30987588/34891/v/450/depositphotos_348913014-stock-illustration-people-in-cozy-cafe-coffee.jpg";

  const data = ['Reserving LLP name', 'Obtain DSCs', 'LLP Agreement & Filing', 'A first free consultation', 'Complete support on opening a current Account Online accounting', 'Online accounting']

  const process = [
    { title: 'Reach out to our Experts', icon: <TfiHeadphoneAlt size={46} color={themeStyle.COLOR_RED} />, description: 'We have the best business experts who can resolve all your queries' },
    { title: 'Provide all the required documents', icon: <HiDocumentArrowDown size={46} color={themeStyle.COLOR_RED} />, description: 'Provide all the documents. Our team will initiate the paperwork on your behalf' },
    { title: 'Get your LLP Registartion done', icon: <FaCheck size={46} color={themeStyle.COLOR_RED} />, description: 'Private Limited Company is completed and the certificates will be sent to you.' },
  ]

  const tabs = [
    // { title: "Overview", content: <LLPOverview /> },
    { title: "Benefit", content: <LLPBenefit/> },
    { title: "Requirement", content: <LLPRequirement/> },
    { title: "Types", content: <LLPTypes/>},
    { title: "Document required", content: <LLPDocuments/> },
  ];
  
  const faqs = [
    {
      title: 'What is the eligibility of designated in an LLP ?',
      content: 'Any individual, or even a company or an LLP, can become a partner. However, only an individual can become a ‘designated partner’ in an LLP.',
    },
    {
      title: 'Is the process with Go Legal India is fully online ?',
      content: 'Yes, the process for GST registration is online completely. You can do everything in the personalised dashboard we provide for you.',
    },
    {
      title: 'What is an LLP Agreement ?',
      content: 'An LLP agreement is made between the partners and the LLP regarding the relationship between the individual partners in the LLP',
    },
    {
      title: 'What is the minimum numbers of partner required in LLP ?',
      content: 'A minimum of two designated partners are required to start an LLP. The designated partners are responsible for fulfilling all the essential requirements involved in starting and running an LLP',
    },
  ];
  const location = useLocation();
  return (
    <div>
      <TopBannerSection location={location.pathname} BackgroundImage={BackgroundImage} heading={'LLP Registration with Go Legal India'} lineThrough={'₹11999'} boldNumber={'₹9999'} list={data ?? []} />
      <ProcessRoundCircleSection heading={'How To Do Your LLP Registration Through "GLI"'} process={process} />
      <OverviewContent OverViewImage={OverViewImage} content={ <LLPOverview /> }/>
      <CustomTabs tabs={tabs} />
      <PageFaq list={faqs}/>
    </div>
  )
}

export default LLPRegistration