import React from 'react'
import TopBannerSection from '../../components/TopBannerSection'
import ProcessRoundCircleSection from '../../components/ProcessRoundCircleSection'
import { TfiHeadphoneAlt } from 'react-icons/tfi'
import themeStyle from '../../styles/theme.style'
import { HiDocumentArrowDown } from 'react-icons/hi2'
import { FaCheck } from 'react-icons/fa'
import { ImportBenefit, ImportDocuments, ImportOverview, ImportRequirement, ImportTypes } from '../Lisence/content/ImportTabContent'
import CustomTabs from '../../components/CustomTabs'
import PageFaq from '../../components/PageFaq'
import OverviewContent from '../../components/OverviewContent'
import BackgroundImage from "../../assets/images/License/importExportBanner.jpg"
import OverViewImage from "../../assets/images/License/importExportOverview.jpg";
import { useLocation } from 'react-router-dom'
function ImportExport() {

  const data = ['Same Day Processing , Guaranteed submission of IEC application within 1 hour in the DGFT office.', 'Get a call from IEC expert / Upload All the Necessary Documents /Apply Anywhere from India/ 4.8 Rating portal']

  const process = [
    { title: 'Reach out to our Experts', icon: <TfiHeadphoneAlt size={46} color={themeStyle.COLOR_RED} />, description: 'We have the best business experts who can resolve all your queries' },
    { title: 'Provide all the required documents', icon: <HiDocumentArrowDown size={46} color={themeStyle.COLOR_RED} />, description: 'Provide all the documents. Our team will initiate the paperwork on your behalf' },
    { title: 'Get your Import Export Code done', icon: <FaCheck size={46} color={themeStyle.COLOR_RED} />, description: 'Import Export Code is completed and the certificates will be sent to you.' },
  ]

  const tabs = [
    // { title: "Overview", content: <ImportOverview /> },
    { title: "Benefit", content: <ImportBenefit/> },
    { title: "Requirement", content: <ImportRequirement/> },
    { title: "Types", content: <ImportTypes/>},
    { title: "Document required", content: <ImportDocuments/> },
  ];

  const faqs = [
    {
      title: 'what is IEC (Import Export Code) ?',
      content: 'IEC (Import Export Code) is mandatory for anyone who is looking to start his/her import/export business in India. It is issued by the DGFT (Director General of Foreign Trade). IEC is a 10-digit code that has lifetime validity.',
    },
    {
      title: 'Is it mandatory to update IEC every year ?',
      content: 'All IEC holders are now legally required to update and validate their IEC Details, even if there are no changes, from April to June once every year through the Online system, failing which their IEC shall be de-activated and no import or export activity will be possible.',
    },
    {
      title: 'Documents Required ?',
      content: 'Photograph of the applicant Copy PAN Card Bank certificate/canceled cheque A copy of Aadhar/Voter ID Address proof of the office premise',
    },
  ];
  const location = useLocation();
  return (
    <div>
      <TopBannerSection location={location.pathname} BackgroundImage={BackgroundImage} heading={'Apply for Import Export Code (IEC) Online'} lineThrough={'₹2499'} boldNumber={'₹1999'} list={data ?? []} />
      <ProcessRoundCircleSection heading={'How To Do Your Import Export Code Through "GLI"'} process={process} />
      <OverviewContent OverViewImage={OverViewImage} content={ <ImportOverview /> }/>
      <CustomTabs tabs={tabs} />
      <PageFaq list={faqs}/>
    </div>
  )
}

export default ImportExport