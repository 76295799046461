import React, { useEffect } from "react";
import themeStyle from "../../styles/theme.style";
import { companyName } from "../../Config/config";

function Disclaimer() {
  useEffect(() => {
    document.title = "Disclaimer | " + companyName;
  }, []);
  return (
    <div className="md:mx-28 md:my-20 m-12">
      <div>
        <h1 className="font-bold text-4xl">Disclaimer</h1>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className="mt-4">
          {companyName}, operating under
          the registered business name {companyName}, acts as
          a facilitator linking users with trustworthy professionals. We do not
          operate as a law firm and do not directly provide legal services.
          It&#39;s important to note that this website is independent of any
          governmental entity and is solely intended for informational purposes.
          The information provided on this platform should not be interpreted as
          legal advice or opinion.
        </p>

        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className="mt-4">
          <strong>{companyName},</strong> the entity owning and
          managing this website, functions as a private consultancy firm and
          holds no affiliation with Foscos/FSSAI or any governmental department.
          While we comply with legal requirements by notifying FSSAI of our
          operations, our role involves assisting with the preparation of FSSAI
          registration application forms and providing support for food license
          registration and renewal. Our fees encompass consultancy charges and
          government fees, which are outlined in the fee structure below.
          It&#39;s crucial to understand that the issuance of a license rests
          solely with the FSSAI Govt not with us.
        </p>

        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className="mt-4">
          By accessing or using our website, you agree to comply with the terms
          and conditions detailed herein. If you do not consent to these terms,
          we kindly ask that you refrain from using our services.
        </p>

        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className="mt-4">
          Please be aware that FSSAI processing times can vary, and the issuance
          of licenses may take up to 3 days or longer, depending on FSSAI&#39;s
          procedures.
        </p>

        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className="mt-4">
          {companyName} offers legal services strictly for informational
          purposes. While our website and services may assist with queries
          related to startup documentation, we do not offer legal advice.
        </p>

        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className="mt-4">
          We do not make any representations or warranties regarding the
          completeness, accuracy, reliability, suitability, or availability of
          any information, advice, opinion, or statement provided within our
          services. Any reliance on such information is done at your own risk.
        </p>

        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className="mt-4">
          {companyName} bears no responsibility for any loss or damage
          arising from the use of our services, including indirect or
          consequential losses, loss of profit or revenue, loss of business, or
          loss of data.
        </p>

        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className="mt-4">
          We reserve the right to modify, update, or discontinue our services at
          any time without prior notice. By engaging our services, you
          acknowledge and accept this disclaimer.
        </p>

        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className="mt-4">
          Should you have any questions or concerns regarding this disclaimer,
          please don&#39;t hesitate to contact us at
          info@golegalindia.com.
        </p>

        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className="mt-4">
          Please remember that the issuance of a license is subject to
          government discretion, and we do not assume liability for the
          decision. Our role is solely to assist with the application process.
        </p>

        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className="mt-4">
          <strong>
            We appreciate your decision to choose {companyName}.
          </strong>
        </p>

        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className="mt-4">
          Thank you for choosing “{companyName}”.
        </p>
      </div>
    </div>
  );
}

export default Disclaimer;
