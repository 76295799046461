import React from 'react'
import TopBannerSection from '../../components/TopBannerSection'
import ProcessRoundCircleSection from '../../components/ProcessRoundCircleSection'
import { TfiHeadphoneAlt } from 'react-icons/tfi'
import themeStyle from '../../styles/theme.style'
import { HiDocumentArrowDown } from 'react-icons/hi2'
import { FaCheck } from 'react-icons/fa'
import CustomTabs from '../../components/CustomTabs'
import { OnePersonBenefit, OnePersonDocuments, OnePersonOverview, OnePersonRequirement, OnePersonTypes } from './OnePersonTabContent'
import PageFaq from '../../components/PageFaq'
import OverviewContent from '../../components/OverviewContent'
import BackgroundImage from "../../assets/images/Private Limited Company/banner.jpg"
import OverViewImage from "../../assets/images/Private Limited Company/Over View.jpg";
import { useLocation } from 'react-router-dom'
function OnePersonCompany() {
const topImageUrl = "https://st3.depositphotos.com/30987588/34891/v/450/depositphotos_348913014-stock-illustration-people-in-cozy-cafe-coffee.jpg";
  const data = ['We reserve the name of your company and help you obtain a DSC and DIN.', 'We draft and file the documents required for your OPC registration (MoA, AoA, PAN, & TAN).', 'We help you with the post-registration formalities and compliances.',]

  const process = [
    { title: 'Reach out to our Experts', icon: <TfiHeadphoneAlt size={46} color={themeStyle.COLOR_RED} />, description: 'We have the best business experts who can resolve all your queries' },
    { title: 'Provide all the required documents', icon: <HiDocumentArrowDown size={46} color={themeStyle.COLOR_RED} />, description: 'Provide all the documents. Our team will initiate the paperwork on your behalf' },
    { title: 'Get your One Person Company done', icon: <FaCheck size={46} color={themeStyle.COLOR_RED} />, description: 'Private Limited Company is completed and the certificates will be sent to you.' },
  ]

  const tabs = [
    // { title: "Overview", content: <OnePersonOverview /> },
    { title: "Benefit", content: <OnePersonBenefit /> },
    { title: "Requirement", content: <OnePersonRequirement /> },
    { title: "Types", content: <OnePersonTypes /> },
    { title: "Document required", content: <OnePersonDocuments /> },
  ];

  const faqs = [
    {
      title: 'Who can register an OPC ?',
      content: 'OPC company registration can be done only by Indian residents, and that too only one at a time, as per the specifications of the Ministry of Corporate Affairs.',
    },
    {
      title: 'What are the mandatory requirements for OPC ?',
      content: 'All such businesses must maintain books of accounts, comply with statutory audit requirements and submit income tax returns and annual filings with the ROC',
    },
    {
      title: 'How much capital is required to start an OPC ?',
      content: 'There is no difference in capital requirement between an OPC and a private limited company. It needs an authorized capital of ₹1 lakh, to begin with, but none of this actually needs to be paid up. This means that you don’t really need to invest any money into the business.',
    },
    {
      title: 'What are the tax advantages available to an OPC ?',
      content: 'No general advantages; though some industry-specific advantages are available. Tax is to be paid at a flat rate of 30% on profits, Dividend distribution tax applies, as does minimum alternate tax.',
    },
    {
      title: 'How much do it cost to run an OPC ?',
      content: 'The cost of an OPC is only marginally lower than that of a private limited company. You’ll be shelling out around ₹12,000 to incorporate, then paying around ₹15,000 a year in compliance fees and an auditor to inspect your books.',
    },
  ];
  const location = useLocation();
  return (
    <div>
      <TopBannerSection location={location.pathname} BackgroundImage={BackgroundImage} heading={'One Person Company Registration In India'} lineThrough={'₹12999'} boldNumber={'₹8999'} list={data ?? []} />
      <ProcessRoundCircleSection heading={'How To Do Your One Person Company Through "GLI"'} process={process} />
      <OverviewContent OverViewImage={OverViewImage} content={ <OnePersonOverview /> }/>
      <CustomTabs tabs={tabs} />
      <PageFaq list={faqs}/>
    </div>
  )
}

export default OnePersonCompany