export const TrademarkCopyrightOverview = () => {

    const copyrightOverviewResponse = [
        {
            "title": "What is Copyrightable",
            "content": "Copyright protects original works of authorship fixed in a tangible medium of expression. This includes literary works, music, art, photographs, films, software code, architectural designs, and more."
        },
        {
            "title": "Copyright Registration",
            "content": "While not required for protection, registering a copyrighted work with the relevant copyright office offers legal advantages, such as the ability to sue for statutory damages and attorney’s fees in case of infringement."
        },
        {
            "title": "Copyright Notice",
            "content": "While not mandatory, displaying a copyright notice (©), along with the year of creation and the copyright owner’s name, helps inform others of the work’s protected status."
        },
        {
            "title": "Scope of Protection",
            "content": "Copyright protection gives the creator the exclusive right to reproduce, distribute, perform, display, and create derivative works based on the original work. Others cannot use these rights without permission."
        }
    ]

    return (
        <div>
            <div className="font-bold text-lg">Overview:</div>
            <div className="my-5">
                {copyrightOverviewResponse?.map((item, index) => (
                    <div key={index}>
                        <div className="font-bold text-lg">{item.title}</div>
                        <div className="my-4">{item.content}</div>
                    </div>
                ))}</div>
        </div>
    )
}

export const TrademarkCopyrightBenefit = () => {

    const copyrightOverviewResponse = [
        {
            "title": "Exclusive Rights",
            "content": "Copyright provides the creator with exclusive rights to their work. This means the creator has control over how their work is used, reproduced, distributed, performed, displayed, and adapted. Others generally need permission to use the work in these ways."
        },
        {
            "title": "Financial Benefits",
            "content": "Copyright allows creators to financially benefit from their creations. Creators can license their works to others, receive royalties, and generate income through various channels such as publishing, distribution, performances, and digital platforms."
        },
        {
            "title": "Recognition and Attribution",
            "content": "Copyright ensures that creators receive recognition and attribution for their work. The rights holder’s name is typically associated with the work, allowing them to build a reputation and identity as a creator."
        },
        {
            "title": "Encouragement of Creativity",
            "content": "Copyright law encourages creativity by providing an incentive for individuals and organizations to invest time, effort, and resources into creating original works. The prospect of financial gain and recognition motivates creators to produce new content."
        },
        {
            "title": "Protection against Unauthorized Use",
            "content": "Copyright protection helps prevent unauthorized use and exploitation of a creator’s work. This safeguards their investment in time and effort and reduces the risk of their work being used without permission."
        }
    ]
    

    return (
        <div>
            <div className="font-bold text-lg">Benefits of Copyright Registration in India:</div>
            <div className="my-5">
                {copyrightOverviewResponse?.map((item, index) => (
                    <div key={index}>
                        <div className="font-bold text-lg">{item.title}</div>
                        <div className="my-4">{item.content}</div>
                    </div>
                ))}</div>
        </div>
    )
}

export const TrademarkCopyrightRequirement = () => {

    const copyrightRequirementResponse =[
        {
            "title": "Creative Expression",
            "content": "Copyright protects creative expression, not facts or ideas. This means that while your unique expression of an idea is protected, someone else is free to express the same idea in their own way."
        },
        {
            "title": "Tangible Expression",
            "content": "The work should be fixed in a tangible medium, such as writing it down, recording it, painting it, or saving it digitally. Ideas and concepts themselves are not protected by copyright."
        },
        {
            "title": "Original Work",
            "content": "The work must be original, meaning it is the result of your creative effort and not merely a copy of someone else’s work."
        },
        {
            "title": "Enforcement",
            "content": "In case of copyright infringement, you have the right to enforce your copyright. This might involve sending cease-and-desist letters, negotiating licensing agreements, or pursuing legal action."
        }
    ]
    
    return (
        <div>
            <div className="font-bold text-lg">Requirement of Copyright:</div>
            <div className="my-5">
                {copyrightRequirementResponse?.map((item, index) => (
                    <div key={index}>
                        <div className="font-bold text-lg">{item.title}</div>
                        <div className="my-4">{item.content}</div>
                    </div>
                ))}</div>
        </div>
    )
}

export const TrademarkCopyrightTypes = () => {

    const copyrightTypes =[
        {
            "title": "Literary Works",
            "content": "This category includes novels, short stories, poems, plays, essays, articles, and other written content."
        },
        {
            "title": "Musical Works",
            "content": "Original musical compositions, including lyrics and melodies, are protected by copyright. This covers songs, instrumental compositions, and musical scores."
        },
        {
            "title": "Artistic Works",
            "content": "Visual art forms such as paintings, drawings, sculptures, photographs, and graphic designs are eligible for copyright protection."
        },
        {
            "title": "Dramatic Works",
            "content": "Theatrical plays, scripts, screenplays, and choreographic works fall under this category."
        },
        {
            "title": "Audiovisual Works",
            "content": "These are works that combine visual and auditory elements, such as movies, TV shows, documentaries, and video clips."
        },
        {
            "title": "Architectural Works",
            "content": "Original architectural designs, both in the form of buildings and architectural drawings, can be protected by copyright."
        },
        {
            "title": "Software and Computer Programs",
            "content": "Original software code, including computer programs and applications, can be protected under copyright law."
        },
        {
            "title": "Sound Recordings",
            "content": "While not the same as musical works, sound recordings capture the performance or reproduction of music and other sounds. This includes songs, speeches, and other recorded sounds."
        },
        {
            "title": "Broadcasts",
            "content": "Radio and television broadcasts, including the content and organization of broadcasts, can be protected."
        },
        {
            "title": "Derivative Works",
            "content": "These are new works created by modifying or adapting existing copyrighted works. This could include translations, adaptations, remixes, and parodies."
        },
        {
            "title": "Compilations",
            "content": "Collections of information or data, such as databases, directories, and anthologies, can be protected if they exhibit creativity in their selection and arrangement."
        },
        {
            "title": "Literary Characters and Stories",
            "content": "Original literary characters and fictional stories can be protected, but individual names and short phrases generally aren’t."
        },
        {
            "title": "Software Interfaces",
            "content": "In some cases, the user interface elements of software applications can be protected if they are original and creative."
        },
        {
            "title": "Non-Fiction Works",
            "content": "Historical accounts, biographies, encyclopedias, reference works, and other non-fiction texts can also be protected by copyright."
        },
        {
            "title": "Advertisements and Marketing Materials",
            "content": "Original creative content used in advertising campaigns, brochures, catalogs, and other promotional materials can be protected."
        }
    ]
    
    return (
        <div>
            <div className="font-bold text-lg">Here are some common Types of Copyright:</div>
            <div className="my-5">
                {copyrightTypes?.map((item, index) => (
                    <div key={index}>
                        <div className="font-bold text-lg">{item.title}</div>
                        <div className="my-4">{item.content}</div>
                    </div>
                ))}</div>
        </div>
    )
}

export const TrademarkCopyrightDocumentsRequired = () => {

    const copyrightDocumentRequired =[
        {
            "title": "Application Form",
            "content": "Most copyright offices provide an application form that you need to fill out. This form collects essential information about the work and the author."
        },
        {
            "title": "Description of the Work",
            "content": "A detailed description of the work, including its title, nature, purpose, and any relevant information about its content or context."
        },
        {
            "title": "Copyrightable Work",
            "content": "The actual copyrighted work itself, whether it’s a manuscript, artwork, musical score, software code, or any other form of expression."
        },
        {
            "title": "Authorship Information",
            "content": "Information about the creator or author of the work, including their name, contact details, and any pseudonyms used."
        },
        {
            "title": "Date of Creation",
            "content": "The date when the work was created or fixed in a tangible medium. This can be important for determining copyright duration and priority."
        },
        {
            "title": "Copyright Notice",
            "content": "While not mandatory, you might want to include a copyright notice (©) on the work, indicating your intention to assert copyright ownership."
        },
        {
            "title": "Publication Information",
            "content": "If the work has been published, include details about the publication, such as the publisher’s name and publication date."
        },
        {
            "title": "Previous Registrations",
            "content": "If the work has been registered for copyright before or published previously, provide information about any earlier registrations or publications."
        },
        {
            "title": "Power of Attorney (if applicable)",
            "content": "If you’re using a representative, such as an attorney or agent, a power of attorney document might be required to authorize them to act on your behalf."
        }
    ]
    
    
    return (
        <div>
            <div className="font-bold text-lg">The documentation required for copyright registration:</div>
            <div className="my-5">
                {copyrightDocumentRequired?.map((item, index) => (
                    <div key={index}>
                        <div className="font-bold text-lg">{item.title}</div>
                        <div className="my-4">{item.content}</div>
                    </div>
                ))}</div>
        </div>
    )
}