import React from 'react'
import TopBannerSection from '../../components/TopBannerSection'
import ProcessRoundCircleSection from '../../components/ProcessRoundCircleSection'
import { TfiHeadphoneAlt } from 'react-icons/tfi'
import { HiDocumentArrowDown } from 'react-icons/hi2'
import { FaCheck } from 'react-icons/fa'
import themeStyle from '../../styles/theme.style'
import PageFaq from '../../components/PageFaq'
import OverviewContent from '../../components/OverviewContent'
import { GSTRegistrationBenefit, GSTRegistrationDocumentsRequired, GSTRegistrationOverview, GSTRegistrationRequirement, GSTRegistrationTypes } from './GSTTabContent/GSTRegistrationTabContent'
import CustomTabs from '../../components/CustomTabs'
import BackgroundImage from "../../assets/images/Tax & Compliance/banenr.jpg"
import OverViewImage from "../../assets/images/Tax & Compliance/over view.jpg";
import { useLocation } from 'react-router-dom'
function GSTRegistration() {

    const data = ['We make it easy for you to get your GST']

    const process = [
        { title: 'Reach out to our Experts', icon: <TfiHeadphoneAlt size={46} color={themeStyle.COLOR_RED} />, description: 'We have the best business experts who can resolve all your queries' },
        { title: 'Provide all the required documents', icon: <HiDocumentArrowDown size={46} color={themeStyle.COLOR_RED} />, description: 'Provide all the documents. Our team will initiate the paperwork on your behalf' },
        { title: 'Get your GST Registration done', icon: <FaCheck size={46} color={themeStyle.COLOR_RED} />, description: 'GST Registration is completed and the certificates will be sent to you.' },
    ]

    const faqs = [
        {
            title: 'Is it necessary to pay GST ?',
            content: 'Yes, it is mandatory to pay GST for all the tax payers who is registered under GST regime.',
        },
        {
            title: 'What is the full form of CGST, SGST, IGST?',
            content: 'The full form of CGST– Central Goods and Service Tax | SGST– State Goods and Service Tax | IGST– Integrated Goods and Service Tax.',
        },
        {
            title: 'What is the difference between CGST, SGST and IGST?',
            content: 'CGST and IGST are levied by Central Govt. and SGST is levied by State Govt. CGST and SGST are paid for Intra-State Supply and IGST is paid for inter-State supply.',
        },
        {
            title: 'How to get GST certificate?',
            content: 'GST certificate is provided in soft copy format by Govt. of India. After the allotment of GSTIN, GST certificate can be downloaded from the GST portal at anytime by the applicant.'
        }
    ];

    const tabs = [
        { title: "Benefit", content: <GSTRegistrationBenefit /> },
        { title: "Requirement", content: <GSTRegistrationRequirement /> },
        { title: "Types", content: <GSTRegistrationTypes /> },
        { title: "Document required", content: <GSTRegistrationDocumentsRequired /> },
      ];
      const location = useLocation();
    return (
        <div>
            <TopBannerSection location={location.pathname} BackgroundImage={BackgroundImage} backgroundCover={true} backgroundSize={"contain"} heading={'GST Registration Online'} lineThrough={'₹2499'} boldNumber={'₹1999'} list={data ?? []} />
            <ProcessRoundCircleSection heading={'How To Do Your GST Registration Through "GLI"'} process={process} />
            <OverviewContent OverViewImage={OverViewImage} content={<GSTRegistrationOverview />} />
            <CustomTabs tabs={tabs} />
            <PageFaq list={faqs} />
        </div>
    )
}

export default GSTRegistration