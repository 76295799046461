import React, { useEffect } from 'react'
import themeStyle from '../../styles/theme.style'
import AboutusImg from '../../assets/images/about-us.png'
import { companyName } from '../../Config/config'

function AboutUs() {
  useEffect(()=>{
    document.title = "About US | "+companyName
  },[])
  return (
    <div className="px-6 md:px-10 py-16  w-full">
      <div className="lg:flex items-center flex-col md:flex-row">
      <div className='lg:w-[50%] mt-5 flex justify-center'>
          <img className='rounded-3xl' height={600} width={600} src={AboutusImg} />
        </div>
        <div className="lg:w-[50%] mb-6 md:mb-0 md:mx-10 mt-10">
          <h1 className='text-black text-4xl font-bold'>About Us</h1>
          <div className='mt-5'>
            <p style={{ color: themeStyle.COLOR_DARK_GREY }}>At {companyName}, we specialize in facilitating the acquisition of essential food licenses, particularly the Food Safety and Standards Authority of India (FSSAI) certificate. Our mission is to empower businesses and individuals within the food industry by ensuring compliance with regulatory standards, fostering trust among consumers, and promoting public health and safety.</p>
            <br/>
            <p style={{ color: themeStyle.COLOR_DARK_GREY }}>{companyName} is a dedicated team of professionals with extensive experience in regulatory compliance and food safety management. Our experts possess in-depth knowledge of the legal requirements and intricacies involved in obtaining FSSAI certification. We leverage this expertise to guide our clients through the certification process seamlessly.</p>
            <br/>
            <p style={{ color: themeStyle.COLOR_DARK_GREY }}>At {companyName}, we are committed to simplifying the process of obtaining FSSAI certification and empowering businesses to thrive in the food industry while upholding the highest standards of safety and quality. Partner with us to navigate the regulatory landscape with confidence and ensure the success and compliance of your food business.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs