import React from "react";
import TopBannerSection from "../../components/TopBannerSection";
import ProcessRoundCircleSection from "../../components/ProcessRoundCircleSection";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import themeStyle from "../../styles/theme.style";
import { HiDocumentArrowDown } from "react-icons/hi2";
import { FaCheck } from "react-icons/fa";
import CustomTabs from "../../components/CustomTabs";
import {
  FssaiBenefit,
  FssaiDocumentsRequired,
  FssaiOverview,
  FssaiRequirement,
  FssaiTypes,
} from "../Lisence/content/FssaiTabContent";
import PageFaq from "../../components/PageFaq";
import OverviewContent from "../../components/OverviewContent";
import BenefitsofFssai from "../Home/BenefitsofFssai";
import BackgroundImage from "../../assets/images/License/banner.jpg";
import OverViewImage from "../../assets/images/License/over view.jpg";
import TypesOfFssaiLicense from "../Home/TypesOfFssaiLicense";
import Carousel from "../../components/Carousel";
import FSSAI_Update from "../../assets/images/fssaiUpdate.jpg";
import FoodSafetyMitra from "../../assets/images/FoodSafetyMitra.jpg";
import FoodSafetyStandards from "../../assets/images/FoodSafetyStandards.jpg";
import FssaiCarousel from "../../components/FssaiCarousel";
import { useLocation } from "react-router-dom";
import FssaiCertificate from "../SampleCertificate/FssaiCertificate";
import AuthorizedPartner from "../../components/AuthorisedPartner";
function FssaiRegistration() {
  const topImageUrl =
    "https://st3.depositphotos.com/30987588/34891/v/450/depositphotos_348913014-stock-illustration-people-in-cozy-cafe-coffee.jpg";

  const process = [
    {
      title: "Reach out to our Experts",
      icon: <TfiHeadphoneAlt size={46} color={themeStyle.COLOR_RED} />,
      description:
        "We have the best business experts who can resolve all your queries",
    },
    {
      title: "Provide all the required documents",
      icon: <HiDocumentArrowDown size={46} color={themeStyle.COLOR_RED} />,
      description:
        "Provide all the documents. Our team will initiate the paperwork on your behalf",
    },
    {
      title: "Get your Fssai Registration done",
      icon: <FaCheck size={46} color={themeStyle.COLOR_RED} />,
      description:
        "Registration is completed and the certificates will be sent to you.",
    },
  ];

  const tabs = [
    // { title: "Overview", content: <FssaiOverview /> },
    { title: "Benefit", content: <FssaiBenefit /> },
    { title: "Requirement", content: <FssaiRequirement /> },
    { title: "Types", content: <FssaiTypes /> },
    { title: "Document required", content: <FssaiDocumentsRequired /> },
  ];

  const faqs = [
    {
      title:
        "Can FSSAI registration be transferred from one person to another?",
      content:
        "No, FSSAI registration is specific to the person or entity that applied for it. It is non-transferable.",
    },
    {
      title:
        "Can I apply for FSSAI registration if I have multiple food businesses at different locations?",
      content:
        "Yes, you can apply for a single FSSAI registration to cover multiple locations of the same food business within the same state. However, separate licenses are required if the locations are in different states.",
    },
    {
      title: "What is the penalty for non-compliance with FSSAI regulations?",
      content:
        "Penalties for non-compliance can include fines, imprisonment, cancellation of license, and closure of the food business. The severity of the penalty depends on the nature and extent of the violation.",
    },
    {
      title: "How much time does it take to get the certificate?",
      content:
        "Minimum 3 days maximum 60 days its total depends on state government Fssai Officers.",
    },
  ];
  return (
    <>
      <section>
        <TopBannerSection
          location={"fssai-registration"}
          BackgroundImage={BackgroundImage}
          topImageUrl={topImageUrl}
          heading={"Apply FSSAI License"}
          lineThrough={"₹1999"}
          boldNumber={"₹999"}
        />
      </section>
      <section>
        <div className="mt-10 flex justify-center flex-wrap">
          <img
            className="w-[100%] md:mx-2 md:w-[70%] lg:w-[50%]"
            src={FSSAI_Update}
            alt="FSSAI Update"
          />
        </div>
      </section>
      <section>
        <FssaiCertificate />
      </section>
      <section>
        <TypesOfFssaiLicense />
      </section>
      <section>
        <BenefitsofFssai />
      </section>
      <section>
        <OverviewContent
          OverViewImage={OverViewImage}
          content={<FssaiOverview />}
        />
      </section>
      <section>
        <CustomTabs tabs={tabs} />
      </section>
      <section>
        <ProcessRoundCircleSection
          heading={'How To Do Your Fssai Registration Through "GLI"'}
          process={process}
        />
      </section>
      <section>
        <FssaiCarousel />
      </section>
      <section>
        <PageFaq list={faqs} />
      </section>
    </>
  );
}

export default FssaiRegistration;
