export const PrivateLimitedOverview = () => {
    return (
        <div>
            <div className="font-bold text-lg">Online Company Registration in India – An Overview</div> One of the most highly recommended methods for starting a business in India is to establish a private limited company, which provides its shareholders with limited liability while imposing certain ownership restrictions. When it is LLP, the partners will manage it. On the other hand, a private limited company allows for directors and shareholders to be separate entities.
        </div>
    )
}

export const PrivateLimitedBenefit = () => {

    const list = ['Shield from personal liability and protects from other risks and losses', 'Attract more customers', 'Attract more customers', 'Procure bank credits and good investments from reliable investors with ease', 'Offers liability protection to protect your company’s assets', 'Greater capital contribution and greater stability', 'Increases the potential to grow big and expand']

    return (
        <div>
            <div className="font-bold text-lg">Benefits of Pvt Ltd Company Registration</div>
            <div className="mt-4">There are numerous advantages to registering a company By doing so, you enhance the credibility of your business, which can lead to increased consumer trust. Additionally, company registration can provide various benefits that can help your business to grow and succeed. </div>
            <div className="ml-5 mt-6">
                <ul style={{ listStyle: 'disc' }}>
                    {list?.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}

                </ul>
            </div>
        </div>
    )
}

export const PrivateLimitedRequirement = () => {

    const list = ['Each director should carry a DIN issued by the MCA', 'One of the directors must be an Indian resident, which means they must have spent at least 182 days there in the previous calendar year.']

    return (
        <div>
            <div className="font-bold text-lg">Requirements to Start a Private Limited Company</div>
            <div className="my-4">Before incorporating a firm, it must meet a specific set of conditions. The following are such conditions:</div>
            <div className="mb-4 font-bold text-lg">1. Directors and Members</div>
            <div>As mentioned earlier, at least two directors and no more than 200 members are necessary for legal Private Limited Company Registration in India. This is a mandatory requirement as per the Companies Act of 2013. The Directors should honor the following conditions:</div>
            <div className="ml-8 mt-4">
                <ul style={{ listStyle: 'disc' }}>
                    {list?.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
            <div className="my-4 font-bold text-lg ">2. The Business’s Name</div>
            <div className="mb-3">When selecting a name for a private limited company, there are two factors must be into consideration:</div>
            <div>Name of the principal activity</div>
            <div className="mb-3">Private Limited Company</div>
            <div className="my-4 font-bold text-lg">3. Address of the registered office</div>
            <div>Upon completion of the registration process, the company should give the permanent address of the business’s registered office to the company registrar. The registered office is the primary location where business takes place and stores all documentation pertaining to the company.</div>
            <div className="my-4 font-bold text-lg">4. Getting Additional Documents</div>
            <div>To verify the authenticity of electronically submitted documents, every business must obtain a DSC. Furthermore, the business needs credentials from such professionals as secretaries, chartered accountants, and cost accountants that engage them in various operations.</div>
        </div>
    )
}

export const PrivateLimitedTypes = () => {

    let sevenTypes = ['Sole Proprietorship', 'One-Man or person Company', 'Partnerships organizations', 'A Limited Liability Partnership company.', 'A private limited entity', 'Limited Liability organization', 'Section 8: Business (like NGOs)']


    let sevenTypesContent = [
        { title: 'Sole Proprietorship', content: 'We understand that a sole proprietorship registration is a company where a single entity manages the business. We treat the firm and its operator as a single entity. Similarly, earnings and liabilities become the responsibility of the owner or operator. As the names appear on the sole proprietorship registration, they will also appear on ITR Filing, GST Registration, and accounting, resulting in corporate liability. Almost all single-proprietorship enterprises hold the owner fully responsible for all profits and hazards. A sole proprietorship is a one-person firm that is relatively simple to establish. This form of registration is used by those who work from home or manage enterprises.' },
        { title: 'One-Man or Person Company', content: 'A single individual must serve as a director in one-person company registration. In other words, the single individual will be the director and a member or shareholder. It is similar to a sole proprietorship. The working pattern of one-person company registration can reduce shareholders’ liabilities and responsibilities to a certain extent. A one-person company is a corporation or business entity whose directors and members are the same. Only a single individual manages the activities, and the owner is responsible for work and any personal duties.' },
        { title: 'Partnerships organizations', content: 'Two or more partners who have already determined their role and profit-sharing can enter into a partnership and conduct business activities. For a Partnership Firm Registration, you need to use a binding agreement referred to as the Partnership Deed. You can specify duties, commitments, responsibilities, and the number of shares owned under business registration.' },
        { title: 'Private Limited Company Registration', content: 'Private limited company registration is a perfect option for businesses that need to establish private organization companies. To safeguard their investment, a group of shareholders spreads the liabilities among themselves in this form of corporation. Shares in such a company cannot be traded or distributed publicly. The advantages of private limited company registration are vital because investors and banks prefer this registration, and it exists even if the directors change.' },
        { title: 'Public Limited Company Registration', content: 'Public limited company registration is identical to private limited company registration. The sole distinction is that the company’s shares can perform trading activities on the stock exchange. Therefore, it allows the transfer of shares from one member to another.' },
        { title: 'Private Limited Company Registration', content: 'Private limited company registration is a perfect option for businesses that need to establish private organization companies. To safeguard their investment, a group of shareholders spreads the liabilities among themselves in this form of corporation. Shares in such a company cannot be traded or distributed publicly. The advantages of private limited company registration are vital because investors and banks prefer this registration, and it exists even if the directors change.' },
        { title: 'Section 8: Company Registration (like NGOs)', content: 'Non-Profit Groups, or NGOs, are the most frequent titles for these charity organizations. The primary aims of these groups are social in nature, promoting the arts, sciences, learning, climate change, and assisting people in need. An NGO must have at least two owners and directors for registration purposes.' },
    ]

    return (
        <div>
            <div className="font-bold text-lg ">In India, there are seven different types of company registration:</div>
            <div className="ml-8 mt-4">
                <ul style={{ listStyle: 'number' }}>
                    {sevenTypes?.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
            {sevenTypesContent?.map((item, index) => (
                <div className="mt-5">
                    <div className="font-bold text-lg ">{item?.title}</div>
                    <div className="ml-10 mt-4">
                        <div>{item?.content}</div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export const PrivateLimitedDocumentsRequired = () =>{

    let documentsRequired = ['An Aadhar Card, a PAN Card, registered office proof, and bank account information are necessary for singular proprietorship registration with Indian officials.','One-man or one-person company registration will need ID proof, address proof of the company, and a rent agreement.','A PAN card and address verification of partners and firms, a Partnership Contract, GST registration, and bank account details usually apply for Partnership Company Registration.','For LLP firm establishment, authentic address evidence of office and partners, DSC, and ID proof of partners is necessary.','All directors and shareholders must have a Pan and Aadhar Card, their addresses and PAN cards, their DIN, DSC, Utility Bill, NOC from a landlord, MOA, and Articles of Association.','You’ll need documentation like a trustworthy address, evidence of office and partners, a DSC, and proof of partners’ ID to form a limited liability corporation.','Section 8 companies, such as NGOs, must have a Digital Signature Certificate, DPIN/DIN, MOA, AOA, ID Verification Proof, Passport Size Photographs, Directors Data, and Address Verification Document.']

    return (
        <div>
        <div className="font-bold text-lg ">What credentials do you need to establish the types of companies and registrations?</div>
        <div className="my-4">Please find a list of all paperwork or records necessary for 7 different types of Indian business registrations below:</div>
        <div className="ml-8 mt-4">
            <ul style={{ listStyle: 'number' }}>
                {documentsRequired?.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
        </div>
        <div className="mt-5">Aadhar Cards, PAN Cards, registered address or office proof, bank account details, and photographs are frequent among the seven categories of business registration.</div>
    </div> 
    )
}