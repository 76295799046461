import React from 'react'
import TopBannerSection from '../../components/TopBannerSection'
import { TfiHeadphoneAlt } from 'react-icons/tfi';
import themeStyle from '../../styles/theme.style';
import { HiDocumentArrowDown } from 'react-icons/hi2';
import { FaCheck } from 'react-icons/fa';
import ProcessRoundCircleSection from '../../components/ProcessRoundCircleSection';
import CustomTabs from '../../components/CustomTabs';
import PageFaq from '../../components/PageFaq';
import { TrademarkCopyrightBenefit, TrademarkCopyrightDocumentsRequired, TrademarkCopyrightOverview, TrademarkCopyrightRequirement, TrademarkCopyrightTypes } from './TrademarkTabContent/TrademarkCopyrightContent';
import OverviewContent from '../../components/OverviewContent';
import BackgroundImage from "../../assets/images/Trademark & IP/TrademarkRegistrationCopyright.jpg";
import OverViewImage from "../../assets/images/Trademark & IP/TrademarkRegistration copyright overview.jpg";
import { useLocation } from 'react-router-dom';
function CopyrightRegistration() {

  const data = ['Information Collection & Checking', 'Copyright Filing', 'Regular Updates', 'Dairy number and copyright examination']

  const process = [
    { title: 'Reach out to our Experts', icon: <TfiHeadphoneAlt size={46} color={themeStyle.COLOR_RED} />, description: 'We have the best business experts who can resolve all your queries' },
    { title: 'Provide all the required documents', icon: <HiDocumentArrowDown size={46} color={themeStyle.COLOR_RED} />, description: 'Provide all the documents. Our team will initiate the paperwork on your behalf' },
    { title: 'Get your Copyright done', icon: <FaCheck size={46} color={themeStyle.COLOR_RED} />, description: 'Copyright is completed and the certificates will be sent to you.' },
  ]

  const tabs = [
    // { title: "Overview", content: <TrademarkCopyrightOverview /> },
    { title: "Benefit", content: <TrademarkCopyrightBenefit /> },
    { title: "Requirement", content: <TrademarkCopyrightRequirement /> },
    { title: "Types", content: <TrademarkCopyrightTypes /> },
    { title: "Document required", content: <TrademarkCopyrightDocumentsRequired /> },
  ];

  const faqs = [
    {
      title: 'Do I need a trademark or copyright? What is the difference?',
      content: 'A trademark is a mark given to protect a brand name, logo or slogan. On the other hand, copyright is a protection given to unique content like a book, music, videos, songs, and artistic content',
    },
    {
      title: 'After applying for a copyright, if someone else copies my content, is there any protection?',
      content: 'Yes, absolutely. You must immediately send a legal notice and after sending the notice, if the problem does not get resolved, you can even file a case in a court of law.',
    },
    {
      title: 'Is there any way to search whether my content has been copyrighted by someone else in the past',
      content: 'No, there are no such provisions to check if the work has been copied by any third party. However, you can do a thorough search on the Internet to check if there is anything similar to yours.',
    },
    {
      title: 'Is copyright valid in India alone or is it valid across the world?',
      content: 'India is a signatory of the Berne Convention, so copyright registered in India will get the status of foreign work and protection will be extended to countries that are also signatories to the Berne Convention.',
    },
    {
      title: 'Is copyright applicable to mobile applications?',
      content: 'A mobile application cannot be copyrighted as such; however, the coding can be copyrighted as literary work and other elements can be filed as different copyright applications.'
    },
    {
      title: 'Should my work be published to get registered for copyright?',
      content: 'Published and unpublished works can be registered. If a work is already published, the publishing details need to be furnished along with the application.',
    },
  ];
  const location = useLocation();
  return (
    <div>
      <TopBannerSection location={location.pathname} BackgroundImage={BackgroundImage} backgroundCover={true} heading={'Copyright Registration Online'} lineThrough={'₹3999'} boldNumber={'₹3499'} list={data ?? []} />
      <ProcessRoundCircleSection heading={'How To Do Your Copyright Through "GLI"'} process={process} />
      <OverviewContent OverViewImage={OverViewImage} content={ <TrademarkCopyrightOverview /> }/>
      <CustomTabs tabs={tabs} />
      <PageFaq list={faqs} />
    </div>
  )
}

export default CopyrightRegistration