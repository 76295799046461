import React, { useEffect } from "react";
import themeStyle from "../../styles/theme.style";
import { companyName } from "../../Config/config";

function TermsConditions() {
  useEffect(() => {
    document.title = "Terms & Conditions | " + companyName;
  }, []);
  return (
    <div className="md:mx-28 md:my-20 m-12">
      <div>
        <h1 className="font-bold text-4xl">Terms & Conditions</h1>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className="mt-4">
          “Go Legal India” office located at 33H/3 Mominpore Road, Kolkata, West
          Bengal - 700023, provides a range of resources, services, and products
          via its website. By accessing and using our platform, you agree to
          adhere to the terms and conditions set forth in this User Agreement
          and our Privacy Policy.
        </p>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className="mt-4">
          “Go Legal India” retains the right to amend this User Agreement
          without prior notification. It is your duty to periodically review
          these terms, and your ongoing use of our site implies acceptance of
          any revisions.
        </p>
      </div>
      <div>
        <h1 className="font-bold text-lg mt-6">Responsible Use and Conduct</h1>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className="mt-4">
          Accessing “Go Legal India” resources may require you to provide
          accurate and current information during registration. Unauthorized
          reproduction, resale, or misuse of our resources is strictly
          prohibited, and you bear full responsibility for any repercussions.
        </p>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className="mt-4">
          Although we provide communication tools such as comments and forums,
          we do not actively monitor all user-generated content. You agree not
          to post any unlawful, offensive, or infringing material. We reserve
          the right to remove objectionable content and are not responsible for
          any delays in doing so.
        </p>
      </div>
      <div>
        <h1 className="font-bold text-lg mt-6">Privacy</h1>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className="mt-4">
          “Go Legal India” prioritizes your privacy, and our Privacy Policy
          outlines how we manage your personal information.
        </p>
      </div>
      <div>
        <h1 className="font-bold text-lg mt-6">Limitation of Warranties</h1>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className="mt-4">
          All resources available on our site are provided “as is” and “as
          available,” without any warranties.
        </p>
      </div>
      <div>
        <h1 className="font-bold text-lg mt-6">Limitation of Liability</h1>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className="mt-4">
          “Go Legal India” does not accept responsibility for any direct or
          indirect losses arising from the use of our resources. Our liability
          is restricted to the amount paid, if any, for our services.
        </p>
      </div>
      <div>
        <h1 className="font-bold text-lg mt-6">Copyrights / Trademarks</h1>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className="mt-4">
          All materials on the “Go Legal India” website, such as text, graphics,
          and logos, are our intellectual property and are safeguarded by
          copyright and trademark regulations.
        </p>
      </div>
      <div>
        <h1 className="font-bold text-lg mt-6">Termination of Use</h1>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className="mt-4">
          “Go Legal India” retains the right to suspend or terminate your access
          to our website at our discretion, including for breaches of this User
          Agreement.
        </p>
      </div>
      <div>
        <h1 className="font-bold text-lg mt-6">Governing Law</h1>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className="mt-4">
          By using our website, you consent to the jurisdiction of Kolkata,
          India, and agree that its laws govern these terms and any disputes.
          Legal proceedings will be conducted in Kolkata, India.
        </p>
      </div>
      <div>
        <h1 className="font-bold text-lg mt-6">Cancellation and Refund</h1>
        <p style={{ color: themeStyle.COLOR_DARK_GREY }} className="mt-4">
          Once payment is made, orders cannot be canceled. Refunds are only
          granted in cases where “Go Legal India” fails to deliver. For further
          details, please refer to our Cancellation and Refund Policy.
        </p>
      </div>
    </div>
  );
}

export default TermsConditions;
