export const GSTRegistrationOverview = () => {

    return (
        <div>
            <div><b>GST (Goods and Services Tax) registration</b> is a process through which businesses are required to register themselves under the GST regime. GST is a unified indirect tax system that replaces various taxes like VAT, excise, service tax, and more. Here's an overview of GST registration:</div>
        </div>
    )
}

export const GSTRegistrationBenefit = () => {

    const benefit = [
        {
          title: "Online Registration",
          content: "GST registration is done online through the GST portal provided by the government tax department. The process involves filling out an application form and submitting the required documents."
        },
      ];

    return (
        <div>
            <div className="mt-5">
                {benefit?.map((item, index) => (
                    <div key={index}>
                        <div className="font-bold text-lg">{item.title}</div>
                        <div className="my-4">{item.content}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export const GSTRegistrationRequirement = () => {

    const requirement = [
        {
          title: "Voluntary Registration",
          content: "Even if a business's turnover is below the threshold, they can opt for voluntary GST registration to avail of the benefits of input tax credit and appear more credible to customers and suppliers."
        },
      ];

    return (
        <div>
            <div className="mt-5">
                {requirement?.map((item, index) => (
                    <div key={index}>
                        <div className="font-bold text-lg">{item.title}</div>
                        <div className="my-4">{item.content}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export const GSTRegistrationTypes = () => {

    const requirement = [
        {
          title: "Types of GST",
          content: "GST is usually categorized into CGST (Central GST), SGST (State GST), and IGST (Integrated GST) for inter-state transactions. UTGST (Union Territory GST) is applicable in union territories without legislatures."
        },
      ];

    return (
        <div>
            <div className="mt-5">
                {requirement?.map((item, index) => (
                    <div key={index}>
                        <div className="font-bold text-lg">{item.title}</div>
                        <div className="my-4">{item.content}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export const GSTRegistrationDocumentsRequired = () => {

    const requirement = [
        {
          title: "Documents Required",
          content: "The specific documents needed can vary, but commonly required documents include PAN (Permanent Account Number) of the business, identity and address proofs of promoters/partners/directors, business address proof, bank account details, and photographs."
        },
        {
          title: "Business Details",
          content: "Basic details about the business, such as its name, legal structure (proprietary, partnership, company, etc.), and contact information, are provided during registration."
        }
      ];

    return (
        <div>
            <div className="mt-5">
                {requirement?.map((item, index) => (
                    <div key={index}>
                        <div className="font-bold text-lg">{item.title}</div>
                        <div className="my-4">{item.content}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

