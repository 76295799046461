// redux/reducers.js
const initialState = {};
const counterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "change":
      return action.state;
    case "webData":
      return {
        ...state,
        webData: action.state,
      };
    default:
      return state;
  }
};

export default counterReducer;
