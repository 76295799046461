import React, { useEffect, useRef, useState } from "react";
import { FaUser, FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa";
import { ShowSuccessMessage } from "./ToastMessage/ToastMessage";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { API_END_POINT, companyName, isInDev } from "../Config/config";
import themeStyle from "../styles/theme.style";
import LoadingGif from "../assets/images/loading.gif";
import { pagePaths } from "../Config/pagePaths";
const TalkToExpertForm = ({ heading, service }) => {
  function useQueryParams() {
    const params = new URLSearchParams(window ? window.location.search : {});

    return new Proxy(params, {
      get(target, prop) {
        return target.get(prop);
      },
    });
  }
  const navigate = useNavigate();
  const location = useLocation();
  const [companyDetails, setCompanyDetails] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [recommended_plans, setRecommended_plans] = useState([]);
  const [recommendedChoose, setRecommendedChoose] = useState(null);
  const fromRef = useRef(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [enableGst, setEnableGst] = useState(false);

  const [formData, setFormData] = useState({
    name: isInDev ? "Ashif" : "",
    phoneNumber: isInDev ? "8825870294" : "",
    state: isInDev ? "Delhi" : "",
    gst: isInDev ? "19JILPK9398K1ZX" : "",
  });

  const [errors, setErrors] = useState({
    name: "",
    phoneNumber: "",
    state: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setErrors({ ...errors, [id]: "" });
  };
  function validateGSTIN(gstin) {
    // Regular expression pattern for GSTIN validation
    const gstinRegex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;

    return gstinRegex.test(gstin);
  }
  const validate = () => {
    let isValid = true;
    let errors = {};

    if (!formData.name) {
      errors.name = "Name is required";
      isValid = false;
    } else if (!/^[a-zA-Z\s]+$/.test(formData.name)) {
      errors.name = "Please Enter Valid Name";
      isValid = false;
    }

    if (!formData.phoneNumber) {
      errors.phoneNumber = "Phone number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
      errors.phoneNumber = "Phone number must be 10 digits";
      isValid = false;
    }

    if (!formData.state) {
      errors.state = "State is required";
      isValid = false;
    }
    if (enableGst)
      if (!validateGSTIN(formData.gst)) {
        errors.gst = "Invalid GSTIN";
        isValid = false;
      }
    setErrors(errors);
    return isValid;
  };

  function formatService(companyName) {
    // Remove the leading slash if it exists
    if (companyName.startsWith("/")) {
      companyName = companyName.substring(1);
    }

    // Split the string by dashes
    let words = companyName.split("-");

    // Capitalize the first letter of each word and join them with a space
    let formattedName = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return formattedName
      .replace(/^\/+/, "")
      .replace(/\/+$/, "")
      .split("/")
      .pop();
  }
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    if (!orderId) {
      if (validate()) {
        setLoadingSubmit(true);
        const API = `${API_END_POINT}payment/createOrder.php?name=${encodeURI(
          formData.name
        )}&number=${encodeURI(formData.phoneNumber)}&service=${encodeURI(
          location.pathname
            .replace(/^\/+/, "")
            .replace(/\/+$/, "")
            .split("/")
            .pop()
        )}&state=${encodeURI(formData.state)}`;

        try {
          const response = await fetch(API);

          const resJson = await response.json();
          setTimeout(() => {
            setLoadingSubmit(false);
            setOrderId(resJson?.order_id);
            setCompanyDetails(true);
            !isInDev &&
              setFormData({
                name: "",
                phoneNumber: "",
                state: "",
              });
          }, 1500);
        } catch (error) {
          console.error("Error submitting form:", error);
          setLoadingSubmit(false); // Reset loading state on error
        }
      }
    } else {
      setLoadingSubmit(true);
      const API = `${API_END_POINT}payment/updateOrder.php?orderId=${orderId}&step=${0}&plan_id=${recommendedChoose}`;
      // const API = `${API_END_POINT}Leads/updateRecommended.php?recommended=${recommendedChoose}&order_id=${orderId}`;
      const response = await fetch(API);
      const resJson = await response.json();
      console.log(resJson);
      setTimeout(() => {
        setLoadingSubmit(false);
        navigate(`/${pagePaths.payment}/${orderId}`, {
          state: null,
        });
      }, 1500);
    }
  };

  const getPlanDetails = async () => {
    const API = `${API_END_POINT}Plans/getPlanDetails.php?plan=${encodeURI(
      location.pathname.replace(/^\/+/, "").replace(/\/+$/, "").split("/").pop()
    )}`;
    console.log(API);
    const response = await fetch(API);
    const resJson = await response.json();
    setRecommended_plans(resJson);
    setRecommendedChoose(resJson[0]?.id);
  };
  useEffect(() => {
    getPlanDetails();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      fromRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }, 1000);
  }, []);
  return (
    <div
      className={`${""}bg-white text-black py-5 px-6 shadow-xl lg:w-[70%] w-full rounded-lg overflow-hidden content-center`}
    >
      {!companyDetails && (
        <h2
          className="text-2xl font-bold mb-4 text-center"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
      )}
      {/* <h1>{JSON.stringify(location.search)}</h1> */}
      <form onSubmit={handleSubmit} ref={fromRef}>
        {!companyDetails ? (
          <>
            <label
              for="name"
              className="block mt-2 mb-1 text-sm font-medium text-gray-900"
            >
              Your Name*
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center ps-2 pointer-events-none">
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0 0a8.949 8.949 0 0 0 4.951-1.488A3.987 3.987 0 0 0 13 16h-2a3.987 3.987 0 0 0-3.951 3.512A8.948 8.948 0 0 0 12 21Zm3-11a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full ps-10 p-2.5"
                placeholder="Your Name"
              />
            </div>
            {errors.name && (
              <p className="text-red-500 text-xs mt-1">{errors.name}</p>
            )}
            <label
              for="phoneNumber"
              className="block mt-2 mb-1 text-sm font-medium text-gray-900"
            >
              Phone Number*
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center ps-2 pointer-events-none">
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M18.427 14.768 17.2 13.542a1.733 1.733 0 0 0-2.45 0l-.613.613a1.732 1.732 0 0 1-2.45 0l-1.838-1.84a1.735 1.735 0 0 1 0-2.452l.612-.613a1.735 1.735 0 0 0 0-2.452L9.237 5.572a1.6 1.6 0 0 0-2.45 0c-3.223 3.2-1.702 6.896 1.519 10.117 3.22 3.221 6.914 4.745 10.12 1.535a1.601 1.601 0 0 0 0-2.456Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full ps-10 p-2.5"
                placeholder="Number"
              />
            </div>
            {errors.phoneNumber && (
              <p className="text-red-500 text-xs mt-1">{errors.phoneNumber}</p>
            )}
            <label
              for="state"
              className="block mt-2 mb-1 text-sm font-medium text-gray-500"
            >
              Your State*
            </label>
            <div className="mt-2 relative">
              <div className="absolute inset-y-0 left-0 flex items-center ps-2 pointer-events-none">
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                  />
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17.8 13.938h-.011a7 7 0 1 0-11.464.144h-.016l.14.171c.1.127.2.251.3.371L12 21l5.13-6.248c.194-.209.374-.429.54-.659l.13-.155Z"
                  />
                </svg>
              </div>
              <select
                id="state"
                value={formData.state}
                onChange={handleChange}
                autocomplete="country-name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full ps-10 p-2.5"
              >
                <option value="">Select Your State</option>
                <option value="Andhra Pradesh">Andhra Pradesh</option>
                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                <option value="Assam">Assam</option>
                <option value="Bihar">Bihar</option>
                <option value="Chhattisgarh">Chhattisgarh</option>
                <option value="Delhi">Delhi</option>
                <option value="Goa">Goa</option>
                <option value="Gujarat">Gujarat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal Pradesh</option>
                <option value="Jharkhand">Jharkhand</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Madhya Pradesh">Madhya Pradesh</option>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Manipur">Manipur</option>
                <option value="Meghalaya">Meghalaya</option>
                <option value="Mizoram">Mizoram</option>
                <option value="Nagaland">Nagaland</option>
                <option value="Odisha">Odisha</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Sikkim">Sikkim</option>
                <option value="Tamil Nadu">Tamil Nadu</option>
                <option value="Telangana">Telangana</option>
                <option value="Tripura">Tripura</option>
                <option value="Uttar Pradesh">Uttar Pradesh</option>
                <option value="Uttarakhand">Uttarakhand</option>
                <option value="West Bengal">West Bengal</option>
                <option value="Others">Others</option>
              </select>
            </div>
            {errors.state && (
              <p className="text-red-500 text-xs mt-1">{errors.state}</p>
            )}
            {/* <div className="relative flex gap-x-3 mt-2">
              <div className="flex h-6 items-center">
                <input
                  id="gstBox"
                  name="gstBox"
                  type="checkbox"
                  checked={enableGst}
                  value={enableGst}
                  onChange={(e) => {
                    setEnableGst((e) => {
                      if (e) {
                        setFormData({ ...formData, ["gst"]: "" });
                        setEnableGst((e) => !e);
                      }
                      setEnableGst((e) => !e);
                    });
                  }}
                  className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-gray-300"
                />
              </div>
              <div className="text-sm leading-6">
                <label for="gstBox" className="font-medium text-gray-900">
                  Do You Have GST? <span className="text-gray-400" >(optional)</span>
                </label>
              </div>
            </div> */}
            {false /*enableGst*/ && (
              <>
                <label
                  for="gst"
                  className="block mt-2 mb-1 text-sm font-medium text-gray-900"
                >
                  GST Identification Number
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center ps-2 pointer-events-none">
                    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 7V2.221a2 2 0 0 0-.5.365L3.586 6.5a2 2 0 0 0-.365.5H8Zm2 0V2h7a2 2 0 0 1 2 2v.126a5.087 5.087 0 0 0-4.74 1.368v.001l-6.642 6.642a3 3 0 0 0-.82 1.532l-.74 3.692a3 3 0 0 0 3.53 3.53l3.694-.738a3 3 0 0 0 1.532-.82L19 15.149V20a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9h5a2 2 0 0 0 2-2Z"
                        clip-rule="evenodd"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M17.447 8.08a1.087 1.087 0 0 1 1.187.238l.002.001a1.088 1.088 0 0 1 0 1.539l-.377.377-1.54-1.542.373-.374.002-.001c.1-.102.22-.182.353-.237Zm-2.143 2.027-4.644 4.644-.385 1.924 1.925-.385 4.644-4.642-1.54-1.54Zm2.56-4.11a3.087 3.087 0 0 0-2.187.909l-6.645 6.645a1 1 0 0 0-.274.51l-.739 3.693a1 1 0 0 0 1.177 1.176l3.693-.738a1 1 0 0 0 .51-.274l6.65-6.646a3.088 3.088 0 0 0-2.185-5.275Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="gst"
                    value={formData.gst}
                    onChange={handleChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full ps-10 p-2.5"
                    placeholder="00123TYS9991"
                  />
                </div>
                {errors.gst && (
                  <p className="text-red-500 text-xs mt-1">{errors.gst}</p>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <div className="ad_class"></div>
            <ul className="w-full">
              {recommended_plans.map((item) => (
                <li key={item.id} className="mt-3">
                  <input
                    type="radio"
                    id={item.id}
                    name="hosting"
                    value={item?.id}
                    className="hidden peer"
                    required
                    onChange={(e) => setRecommendedChoose(e.target.value)}
                    checked={recommendedChoose == item?.id}
                  />
                  <label
                    style={{
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                      borderBottomRightRadius:
                        recommendedChoose != item?.id && 10,
                      borderBottomLeftRadius:
                        recommendedChoose != item?.id && 10,
                    }}
                    htmlFor={item.id}
                    className="inline-flex items-center justify-between w-full p-3 text-black bg-white border border-gray-200 cursor-pointer peer-checked:border-red-600 peer-checked:text-red-600 hover:text-black hover:bg-gray-100 peer-checked:hover:bg-white"
                  >
                    <div className="block">
                      <div className="w-full">
                        <span className="text-lg font-semibold">
                          {item?.plan_name}
                        </span>
                      </div>
                      <div className="w-full text-sm">{item?.description}</div>
                      <div
                        className="w-full text-gray-400 inline-flex items-center justify-between mt-3 hap"
                        style={{
                          gap: 10,
                        }}
                      >
                        <span className="text-xs ">
                          <s>&#x20B9;{item?.service_price}</s>
                        </span>
                        <span className={`text-base font-bold text-black`}>
                          &#x20B9;{item?.discount_price}
                        </span>
                        <span className="text-xs bg-lime-100 text-lime-600 rounded p-1">
                          {item?.discount_title}
                        </span>
                      </div>
                    </div>
                  </label>
                  {recommendedChoose == item?.id && (
                    <button
                      style={{
                        borderTopLeftRadius:
                          recommendedChoose != item?.id && 10,
                        borderTopRightRadius:
                          recommendedChoose != item?.id && 10,
                        borderBottomRightRadius: 10,
                        borderBottomLeftRadius: 10,
                      }}
                      type="submit"
                      disabled={loadingSubmit}
                      className="focus:outline-none w-full text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium text-sm py-2 me-2 hover:disabled:bg-red-400 disabled:bg-red-400 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                    >
                      {loadingSubmit ? (
                        <img
                          src={LoadingGif}
                          width={20}
                          className="img-responsive center-block"
                          style={{
                            margin: "auto",
                          }}
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  )}
                </li>
              ))}
            </ul>
          </>
        )}

        {!companyDetails && (
          <button
            type="submit"
            disabled={loadingSubmit}
            className="w-full form-button p-3 text-sm font-bold text-white bg-green-800 hover:bg-green-900 rounded-md mt-5"
          >
            {loadingSubmit ? (
              <img
                src={LoadingGif}
                width={20}
                className="img-responsive center-block"
                style={{
                  margin: "auto",
                }}
              />
            ) : (
              "Get Started"
            )}
          </button>
        )}
      </form>
    </div>
  );
};

export default TalkToExpertForm;
