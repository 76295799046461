import React from "react";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import themeStyle from "../../styles/theme.style";

function BenefitsofFssai() {
  const benefits = [
    {
      title: "Trust of the customers",
      description:
        "An authentic communication is necessary to gain the customer's trust. It will ensure better service and also keep the customers from unhealthy and adulterated food supply.",
    },
    {
      title: "Legal advantages",
      description:
        "FSSAI Certification will enhance the chance of legal enforcement and control over the department at a certain point and will encourage the establishment of several things to a particular area.",
    },
    {
      title: "Government funding and loans",
      description:
        "The FSSAI registration will offer the privileges of Government Funding and Loans that can be easily accessible for the business operators.",
    },
    {
      title: "Use of fssai logo",
      description:
        "The use of FSSAI logo on your product will normally create goodwill trust worthiness amongst the consumers.",
    },
    {
      title: "Expansion of business",
      description:
        "The FSSAI license will provide a wider scope and privilege of expansion at any geographic location.",
    },
    {
      title: "Recognition of Government",
      description:
        "Government of India recognizes the FSSAI Registration resulting in to customer trust and huge base of consumers.",
    },
    {
      title: "Permit of food business",
      description:
        "FSSAI License speaks about the safety of the customers concerning to food business.",
    },
    {
      title: "Benefits from government actions on non-compliance",
      description:
        "Government will take action and bestow the help on the company in case of any non-compliance issue in the future.",
    },
    {
      title: "Brand value",
      description:
        "A company will automatically acquire Brand Value once it is FSSAI registered.",
    },
    {
      title: "Boost up your business",
      description:
        "If a business is FSSAI registered, it will create handsome client base and boost up the business at a larger scale.",
    },
  ];

  const BenefitCard = ({ title, description }) => {
    return (
      <div className="bg-white rounded-lg shadow-md p-6 text-left border border-red-500 my-2 md:my-0">
        <div className="flex gap-x-1 items-center mb-2">
          <tr className="flex flex-row items-center">
            <td className="">
              <RiVerifiedBadgeFill size={24} color={themeStyle.COLOR_RED} />
            </td>
            <td>
              <h2 className="text-xl font-bold">{title}</h2>
            </td>
          </tr>
        </div>
        <p className="text-gray-700">{description}</p>
      </div>
    );
  };

  return (
    <div className="bg-gray-900 lg:px-20 lg:py-16 py-10 px-12 mx-auto">
      <h3 className="text-white font-bold text-4xl text-center">
        Benefits of FSSAI FoSCos Certificate
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 md:gap-y-5 py-16 lg:mx-20 md:mx-16">
        {benefits.map((benefit, index) => (
          <BenefitCard
            key={index}
            title={benefit.title}
            description={benefit.description}
          />
        ))}
      </div>
    </div>
  );
}

export default BenefitsofFssai;
