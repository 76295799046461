import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API_END_POINT, companyName, RazorKey } from "../../Config/config";
import samplePaymentResults from "../../assets/Data/samplePaymentResults.json";
import Logo from "../../assets/images/Logo.png";
import loadingGif from "../../assets/images/loading.gif";
import themeStyle from "../../styles/theme.style";
import { pagePaths } from "../../Config/pagePaths";

function Payment() {
  const { leadId } = useParams();
  const [paymentData, setPaymentData] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [currentPlanData, setCurrentPlanData] = useState({});
  const [loading, setLoading] = useState(false);
  function formatService(companyName) {
    if (companyName?.startsWith("/")) {
      companyName = companyName?.substring(1);
    }
    let words = companyName?.split("-");
    let formattedName = words
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return formattedName
      ?.replace(/^\/+/, "")
      ?.replace(/\/+$/, "")
      ?.split("/")
      ?.pop();
  }
  const getData = async () => {
    const API = `${API_END_POINT}payment/getOrderDetails.php?orderId=${leadId}`;
    console.log(API);
    await fetch(API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // mode: "no-cors",
    })
      .then((res) => res.json())
      .then((resJson) => {
        if (resJson.status == "success") {
          setPaymentData(resJson);
          resJson?.plan?.plans.map((item) => {
            if (item?.id == resJson?.result?.plan_id) {
              setCurrentPlanData(item);
            }
          });
        } else setErrorMessage(resJson.message);
      });
  };
  const updateOrder = async (step) => {
    const API = `${API_END_POINT}payment/updateOrder.php?orderId=${leadId}&step=${step}&plan_id=${paymentData?.result?.plan_id}`;
    await fetch(API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // mode: "no-cors",
    })
      .then((res) => res.json())
      .then((resJson) => {
        console.log(resJson);
        getData();
        setLoading(!true);
      });
  };
  const proceedPayment = async () => {
    console.log("first")
    setLoading(true);
    const API = `${API_END_POINT}payment/updateOrder.php?orderId=${leadId}&step=1&plan_id=${paymentData?.result?.plan_id}`;
    console.log(API);
    await fetch(API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // mode: "no-cors",
    })
      .then((res) => res.json())
      .then((resJson) => {
        if (resJson.success == 1) {
          console.log(resJson);
          var options = {
            key: RazorKey,
            //   key_secret: "3SPXHvKU5BuhX8q1qXwe9PYH",
            amount:
              Math.round(paymentData?.result?.amount) +
              Math.round(
                (paymentData?.result?.amount * paymentData?.result?.gst) / 100
              ),
            order_id: resJson.payment_id,
            currency: "INR",
            name:
              `${companyName} - ` + formatService(paymentData?.plan?.service),
            description: formatService(paymentData?.plan?.service),
            handler: async function (response) {
              // response.status_code
              await updateOrder(2);
              console.log(JSON.stringify(response));
              setLoading(false);
            },
            modal: {
              ondismiss: async function () {
                await updateOrder(3);
              },
            },
            image: "https://golegalindia.com/logo192.png",
            prefill: {
              name: paymentData?.result?.name,
              contact: paymentData?.result?.phone,
            },
            theme: {
              color: themeStyle.COLOR_RED,
            },
          };
          console.log(JSON.stringify(options))
          var pay = new window.Razorpay(options);
          pay.on("payment.failed", async function (response) {
            await updateOrder(3);
          });

          pay.open();
        } else setErrorMessage(resJson.message);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <p className="text-3xl font-bold my-4 text-center">Payment</p>
      {paymentData?.result?.step != null && paymentData?.result?.step != 3 ? (
        <>
          <div className="flex justify-center">
            <div className="flex justify-between w-full lg:w-1/2 px-2 mt-5 lg:px-0">
              <p className="font-bold">
                {formatService(paymentData?.plan?.service)}{" "}
                {currentPlanData?.plan_name
                  ? `- ${currentPlanData?.plan_name}`
                  : ``}
              </p>
              <p>
                <span>
                  <s>&#x20B9;{currentPlanData?.service_price}</s>
                </span>{" "}
                <span className="font-bold">
                  &#x20B9;{paymentData?.result?.amount}
                </span>
              </p>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="flex justify-between w-full lg:w-1/2 px-2 mt-2 lg:px-0">
              <ul className="list-disc">
                {currentPlanData?.what_they_ll_get?.map((item) => (
                  <li className="ml-3">{item}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="flex justify-between w-full lg:w-1/2 px-2 mt-5 lg:px-0">
              <p className="font-bold">Discount</p>
              <p className="bg-lime-100 text-lime-600 rounded p-1">
                {currentPlanData?.discount_title}
              </p>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="flex justify-between w-full lg:w-1/2 px-2 mt-5 lg:px-0">
              <p>Tax {paymentData?.result?.gst}%</p>
              <p className="font-bold">
                &#x20B9;
                {Number(
                  Math.round(
                    (paymentData?.result?.amount * paymentData?.result?.gst) /
                      100
                  )
                ).toFixed(2)}
              </p>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="flex justify-between w-full lg:w-1/2 px-2 mt-5 lg:px-0">
              <p className="text-3xl font-bold">Total</p>
              <p className="font-bold">
                &#x20B9;
                {/* {paymentData?.result?.amount +
                Math.round(
                  (paymentData?.result?.amount * paymentData?.result?.gst)/100
                )} */}
                {((
                  Math.round(paymentData?.result?.amount) +
                    Math.round(
                      (paymentData?.result?.amount * paymentData?.result?.gst) /
                        100
                    )
                ).toFixed(2))}
              </p>
            </div>
          </div>
          <div className="flex justify-center mb-10 mx-3">
            <button
              className={`rounded-md px-4 py-2 w-full lg:w-1/2 px-2 mt-5 lg:px-0 text-white font-bold bg-red-600 `}
              onClick={proceedPayment}
            >
              {loading ? (
                <img
                  src={loadingGif}
                  width={20}
                  className="img-responsive center-block"
                  style={{
                    margin: "auto",
                  }}
                />
              ) : (
                "Proceed to pay"
              )}
            </button>
          </div>
        </>
      ) : paymentData?.result?.step == 3 ? (
        <div className="flex flex-col justify-center items-center my-20">
          <div className="text-xl text-center text-black lg:w-1/2">
            <div>Thanks for your payment, our executive will contact you shortly.</div>
            If you encounter any issues, please contact us with your order ID:{" "}
            <b>{leadId}</b>. {`${companyName}`} is here to assist you!
            <div className="mt-20">
              <Link
                to={`/${pagePaths.receipt}/${leadId}`}
                className="text-blue-500 hover:underline"
              >
                View receipt
              </Link>
            </div>
            <div className="mt-5">
              <Link
                to={`/`}
                className="text-blue-500 hover:underline font-bold"
              >
                Home
              </Link>
            </div>
          </div>
        </div>
      ) : (
        paymentData?.result == null && (
          <div className="flex justify-center my-20">
            <span
              className="text-2xl text-center text-black lg:w-1/2"
              dangerouslySetInnerHTML={{
                __html: `Looks Like you doesn't choose any plans, if anything wrong please contact us with the order id: <b>${leadId}</b>, ${companyName} will help you!.`,
              }}
            />
          </div>
        )
      )}
      <div className="flex justify-center mb-5">
        <span className="text-sm font-bold text-center text-gray-600">
          {"100% Money-Back Guarantee "}
          <a
            className="text-blue-400 underline"
            href={"/" + pagePaths.termsConditions}
            target="_blank"
          >
            T&C
          </a>
        </span>
      </div>
    </>
  );
}

export default Payment;
