export const FssaiOverview = () => {

    const overview = [
        {
            title: "Setting Standards",
            content: "FSSAI Registration sets standards for various food products, including their composition, additives, contaminants, and labeling requirements. These standards are aimed at ensuring the safety and quality of food products."
        },
        {
            title: "Licensing and Registration",
            content: "FSSAI Registration requires food businesses to obtain licenses or registrations, depending on the scale of their operations. This helps in maintaining a database of food businesses and ensures compliance with safety and hygiene standards"
        },
        {
            title: "Monitoring and Surveillance",
            content: "FSSAI Registration conducts regular inspections and monitoring of food businesses to ensure that they adhere to the prescribed standards. This involves testing samples of food products for quality and safety."
        },
        {
            title: "Consumer Awareness",
            content: "FSSAI Registration plays a role in creating awareness among consumers about food safety and healthy eating habits. They provide information and education to the public to make informed food choices."
        },
        {
            title: "Enforcement",
            content: "FSSAI Registration has the authority to take actions against non-compliant businesses, including issuing warnings, fines, and even legal actions if necessary."
        },
        {
            title: "Research and Development",
            content: "FSSAI Registration promotes research in the field of food safety and standards. They collaborate with various institutions to improve the scientific understanding of food safety issues."
        },
        {
            title: "Capacity Building",
            content: " FSSAI Registration provides training and capacity-building programs to food businesses, regulators, and other stakeholders to enhance their understanding of food safety regulations and best practices."
        },
    ];

    return (
        <div>
            <div className="font-bold text-lg">FSSAI Registration Overview :</div>
            <div className="my-3">FSSAI, or the Food Safety and Standards Authority of India, is a regulatory body established by the Indian government to ensure the safety and quality of food products in the country. Its primary responsibility is to set and enforce food safety standards, regulate the manufacture, storage, distribution, sale, and import of food items, and promote public health by safeguarding food quality. FSSAI plays a crucial role in monitoring and controlling food-related issues in India, promoting consumer awareness, and supporting the food industry in complying with food safety standards.</div>
            <div className="mt-5">
                {overview?.map((item, index) => (
                    <div key={index}>
                        <div className="font-bold text-lg">{item.title}</div>
                        <div className="my-4">{item.content}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export const FssaiBenefit = () => {

    const benefits = [
        { title: 'Legal Compliance', content: 'The most fundamental benefit is that it ensures your business operates within the legal framework defined by the government. Operating without a valid food license can lead to penalties, closure of the business, and legal Problems.' },
        { title: 'Consumer Trust and Confidence', content: 'Having an FSSAI license enhances your credibility and demonstrates your commitment to food safety and quality. This can build trust among consumers, leading to increased customer loyalty and repeat business.' },
        { title: 'Market Access', content: 'Many distributors, retailers, and larger establishments require food suppliers to have an FSSAI license. Holding the license allows you to access a wider range of markets, including institutional buyers and retail chains' },
        { title: 'Business Expansion', content: 'If you’re considering expanding your business, an FSSAI license is often a prerequisite for obtaining loans, grants, or support from financial institutions or government bodies.' },
        { title: 'Differentiation', content: 'In a competitive market, having a Food License sets you apart from unregulated competitors. Consumers are more likely to choose products from businesses that are compliant with food safety regulations.' },
        { title: 'Quality Assurance', content: 'The primary aim of food licenses is to ensure the safety of the products you offer. Complying with Food License regulations minimizes the risk of foodborne illnesses and contamination, protecting your customers’ health.' },
        { title: 'Legal Protection', content: 'In case of any food-related incidents or legal issues, having an FSSAI license can serve as evidence that your business was following established safety and hygiene guidelines, providing you with legal protection.' },
        { title: 'Business Reputation', content: 'A Food License contributes to building a positive reputation for your business. Word-of-mouth recommendations and positive online reviews are more likely when customers perceive your products as safe and of high quality.' },
        { title: 'Export Opportunities', content: 'If you’re interested in exporting your food products, an FSSAI license can make the process smoother by ensuring your products meet the necessary safety and quality standards for international markets' },
    ]

    return (
        <div>
            <div className="font-bold text-lg mb-3">Benefit Of Having Food License:</div>
            {benefits?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg">{item.title}</div>
                    <div className="my-4">{item.content}</div>
                </div>
            ))}
        </div>
    )
}

export const FssaiRequirement = () => {

    const requirement = [
        {
            title: "Application Form",
            content: "The relevant application form needs to be filled out accurately."
        },
        {
            title: "Business Details",
            content: "Information about the nature of the food business, the type of food products being dealt with, and the food processing methods used."
        },
        {
            title: "Business Address Proof",
            content: "Valid proof of the business’s physical address, such as a rental agreement, utility bill, or property tax receipt."
        },
        {
            title: "Proprietor/Partner/Director Details",
            content: "Identity proof and address proof of the proprietor, partners, or directors of the business."
        },
        {
            title: "Food Safety Management System Plan",
            content: "A written plan describing the food safety management system in place."
        },
        {
            title: "Equipment List",
            content: "List of equipment and machinery used in the food business."
        },
        {
            title: "Process Flow Diagram",
            content: "A diagram illustrating the flow of food processing and handling."
        },
        {
            title: "Proof of Premises Possession",
            content: "Proof that you have legal possession of the premises where food-related activities are conducted (rental agreement, ownership documents, etc.)."
        },
        {
            title: "No Objection Certificate (NOC)",
            content: "NOC from the local municipality or other relevant authorities."
        },
    ];

    return (
        <div>
            <div className="mb-3 font-bold text-lg">Requirement of FSSAI License:</div>
            {requirement?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg">{item.title}</div>
                    <div className="my-4">{item.content}</div>
                </div>
            ))}
        </div>
    )
}


export const FssaiTypes = () => {

    const types = [
        {
            title: "Basic FSSAI Registration (for small businesses):",
            content: ['Annual turnover of up to Rs. 12 lakhs.','Minimal documentation.','Suitable for small-scale food businesses, such as small eateries, street food vendors, and home-based food businesses.','For 1 year FSSAI Registration License 999+GST ( Our fees +Govt.) Included','For 5 years FSSAI Registration License 1999+GST ( Our fees +Govt.) Included']
        },
        {
            title: "State FSSAI License (for medium-sized businesses):",
            content: ['Annual turnover between Rs. 12 lakhs and Rs. 20 crores.','More documentation compared to basic registration.','Applicable to medium-sized manufacturers, traders, restaurants, etc.','For state Food License our fees 1999+GST+Goverment fees 2000']
        },
        {
            title: "Central FSSAI License (for large businesses):",
            content: ['Annual turnover exceeding Rs. 20 crores.','More stringent documentation requirements.','Required for large manufacturers, importers, 100% export-oriented units, etc.','For Central Food License our fees 2999+GST+Goverment fees 7500']
        },
    ];


    return (
        <div>
            <div className="mb-3 font-bold text-lg">Types of FSSAI Registration :</div>
            {types?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg mt-5">{item.title}</div>
                    <div className="ml-8 mt-4">
                        <ul style={{ listStyle: 'disc' }}>
                            {item.content?.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
        </div>
    )
}

export const FssaiDocumentsRequired = () => {

    const douments_required = [
        {
            title: "Documents for New FSSAI Registration Food License Certificate",
            content: ['Photograph of the Applicant','Government-issued photo identities, such as small eateries, street food vendors, and home-based food businesses.','Proof of Address of Businesses activity']
        },
        {
            title: "Documents for FSSAI Food License State and Central License",
            content: ['Form-B','Address proof for the production site','Production site layout','Proof of premises rent','Business certificate','Factory license (if any)','Power details for the unit','Contacts for partners, directors, members','ID and address proof for managers or signers','Water test report from a certified lab','Form IX (if you’re nominated by the business owner)','NOC from the manufacturer','List of food items to be made','Food safety management plan']
        },
    ];


    return (
        <div>
            {douments_required?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg mt-5">{item.title}</div>
                    <div className="ml-8 mt-4">
                        <ul style={{ listStyle: 'disc' }}>
                            {item.content?.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
        </div>
    )
}