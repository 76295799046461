export const TrademarkOverview = () => {
    return (
        <div>
            <div className="font-bold text-lg">Trademark Registration :
            </div>
            <div className="my-5">
                Trademark Registration is a process that protects distinctive names, symbols, logos, phrases, and other identifiers associated with goods or services. The primary purpose of trademark registration is to prevent others from using similar marks that could lead to confusion among consumers and dilution of the original mark’s distinctiveness.
            </div>
            <div className="my-5">In India, you can register diverse items as “brand registration”. They can include brand names, logos, words, slogans, sounds, smells, colors, or any unique sign. These trademarks should distinguish your goods or services from others. This helps maintain your business identity and protect it from misuse.</div>
            <div className="my-5">The primary purpose of a “logo registration” is to help consumers easily identify and associate goods or services with a specific source, conveying information about the quality and origin of the products or services bearing that mark. It serves as a unique identifier, representing the goodwill and reputation associated with the products or services of the trademark owner.</div>
        </div>
    )
}

export const TrademarkBenefit = () => {

    const trademarkBenefits = [
        {
            title: "Exclusive Rights",
            content: "Trademark registration provides the owner with exclusive rights to use the registered mark in connection with the specified goods or services. This exclusivity helps prevent others from using a confusingly similar mark that could lead to customer confusion."
        },
        {
            title: "Brand Protection",
            content: "Trademarks are crucial for building and protecting your brand’s identity. They help consumers identify the source of products and services, fostering trust and loyalty. Registered trademarks deter others from using similar marks that could damage your brand’s reputation."
        },
        {
            title: "Legal Protection",
            content: "Trademark registration provides stronger legal protection compared to unregistered marks. If someone infringes on your registered trademark, you have the right to take legal action, which may include seeking damages, injunctive relief, and attorney’s fees."
        },
        {
            title: "National and International Protection",
            content: "Trademarks can be registered at both national and international levels, offering protection in specific jurisdictions or multiple countries through mechanisms like the Madrid Protocol. This is particularly useful for businesses that operate globally or plan to expand internationally."
        },
        {
            title: "Presumption of Ownership and Validity",
            content: "Once registered, a trademark owner is presumed to be the rightful owner of the mark and to have exclusive rights to its use. This presumption can make legal proceedings against infringers easier."
        },
        {
            title: "Value and Asset",
            content: "A registered trademark can become a valuable asset for your business. It can increase the overall value of your company, and you can license or sell trademark rights to generate revenue."
        },
        {
            title: "Deterrence",
            content: "A registered trademark can deter potential infringers from using a similar mark due to the legal consequences they could face. This proactive approach helps prevent conflicts before they arise."
        },
        {
            title: "Enforcement",
            content: "Logo registration strengthens your ability to enforce your rights against infringers, counterfeiters, and unauthorized users. Customs authorities and online platforms often recognize registered trademarks, making it easier to prevent the import, sale, or use of counterfeit goods."
        },
        {
            title: "Distinctiveness",
            content: "A registered trademark adds a level of distinctiveness to your products or services, setting them apart from competitors. This distinctiveness can contribute to brand recognition and consumer trust."
        },
        {
            title: "Statutory Damages",
            content: "In some jurisdictions, registering your trademark before a case of infringement occurs can make you eligible for statutory damages, simplifying the process of seeking compensation in case of infringement."
        },
        {
            title: "Long-Term Protection",
            content: "Trademark registration can be renewed indefinitely as long as the mark continues to be used and renewal fees are paid, providing long-term protection for your brand."
        }
    ];
    

    return (
        <div>
            <div className="my-4"><b>Trademark Registration offers several benefits</b> to individuals and businesses. Here are some of the key advantages of brand registration:</div>
            {trademarkBenefits?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg">{item.title}</div>
                    <div className="my-4">{item.content}</div>
                </div>
            ))}
        </div>
    )
}

export const TrademarkRequirement = () => {

    const trademarkRequirements = [
        {
            title: "Distinctiveness",
            content: "The mark must be distinctive and capable of identifying your goods or services from those of others. Generic or highly descriptive terms might face challenges during registration."
        },
        {
            title: "Use in Commerce or Intent to Use",
            content: "Some jurisdictions require that you provide evidence of actual use of the mark in commerce before Trademark registration. In other cases, you might be able to apply based on an intent to use the mark in the future."
        },
        {
            title: "Clear Description of Goods/Services",
            content: "Your application must clearly specify the goods or services that the trademark will be used for. This is typically done using the international Nice Classification system."
        },
        {
            title: "Availability",
            content: "A comprehensive trademark search should be conducted to ensure that the proposed mark is not already in use for similar goods or services by another party. This helps avoid potential conflicts and rejections."
        },
        {
            title: "Proper Representation of the Mark",
            content: "You need to provide a proper representation of the mark. This could be a visual representation of a logo or a stylized wordmark."
        },
        {
            title: "Correct Applicant Information",
            content: "The application should include accurate information about the applicant, which could be an individual or a legal entity. This includes the name, address, and sometimes the nationality."
        },
        {
            title: "Filing Fees",
            content: "There are fees associated with filing a trademark application. The amount varies depending on the jurisdiction and the type of application (e.g., regular application, expedited processing, etc.)."
        },
        {
            title: "Power of Attorney (if applicable)",
            content: "If you’re using a representative, such as a trademark attorney, you might need to provide a power of attorney authorizing them to act on your behalf."
        },
        {
            title: "Consent for Certain Marks",
            content: "In some cases, if your proposed mark is similar to an existing mark, you might need to obtain consent from the owner of the existing mark to proceed with registration."
        },
        {
            title: "Specimens of Use",
            content: "If your application is based on the actual use of the mark, you may need to provide specimens showing how the mark is used on the goods or in connection with the services."
        },
        {
            title: "Statement of Use",
            content: "If you filed based on an intent to use and the mark is approved, you will need to submit a statement of use along with evidence of actual use before the mark can be fully registered."
        },
        {
            title: "Application Form",
            content: "Fill out the required application form accurately and completely, providing all necessary information and documents."
        }
    ];
    
    return (
        <div>
            <div className="my-4"><b>The requirements for Brand registration </b> can vary depending on the jurisdiction you’re applying in:</div>
            {trademarkRequirements?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg">{item.title}</div>
                    <div className="my-4">{item.content}</div>
                </div>
            ))}
        </div>
    )
}

export const TrademarkTypes = () => {

    const trademarkTypes = [
        {
            title: "Standard Character Mark (Word Mark)",
            content: "This type of registration protects the textual representation of a brand name, slogan, or other word-based mark. It typically provides broader protection as it covers various stylizations and formats of the text."
        },
        {
            title: "Stylized Mark (Logo Mark)",
            content: "This type of registration protects a specific stylized or designed representation of a mark, such as a logo, symbol, or artwork. It provides protection for the specific visual design and arrangement."
        },
        {
            title: "Combined Mark (Composite Mark)",
            content: "This type of registration combines both a standard character element and a stylized element. It protects both the specific stylized design and any textual components."
        },
        {
            title: "Service Mark",
            content: "While often used interchangeably with trademarks, service marks specifically protect marks associated with services rather than physical goods. The registration process and requirements are similar to trademarks."
        },
        {
            title: "Collective Mark",
            content: "A collective mark is used by members of an organization or association to indicate membership and a common source of goods or services. It’s used to represent a group rather than an individual entity."
        },
        {
            title: "Certification Mark",
            content: "Certification marks are used to show that goods or services meet certain standards, quality, or characteristics set by a certifying entity. They are typically used to verify compliance or origin."
        },
        {
            title: "Shape Mark",
            content: "In some cases, the shape of a product or packaging can be registered as a trademark if it is distinctive and capable of identifying the source of the goods."
        },
        {
            title: "Sound Mark",
            content: "Sound marks protect unique sound sequences or jingles that can identify a brand. These are relatively less common due to the challenges in representing sounds in trademark applications."
        },
        {
            title: "Color Mark",
            content: "While rare, certain jurisdictions allow the registration of specific colors as trademarks if they have acquired distinctiveness and can serve as identifiers of the source."
        }
    ];
    
    
    return (
        <div>
            <div className="my-4"><b>There are several types of Brand registrations </b>that offer varying degrees of protection and benefits:</div>
            {trademarkTypes?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg">{item.title}</div>
                    <div className="my-4">{item.content}</div>
                </div>
            ))}
        </div>
    )
}

export const TrademarkDocumentRequired = () => {

    const trademarkApplicationRequirements = [
        {
            title: "Trademark Application Form",
            content: "Most jurisdictions provide a specific application form that you need to fill out. This form collects important information about the applicant, the mark, the goods or services, and other relevant details."
        },
        {
            title: "Specimens of Use",
            content: "If you’re applying based on actual use of the mark, you might need to provide specimens showing how the mark is used on the goods or in connection with the services. These could include labels, packaging, product tags, or advertisements."
        },
        {
            title: "Power of Attorney (if applicable)",
            content: "If you’re using a representative, such as a trademark attorney, in the application process, you might need to provide a power of attorney document authorizing them to act on your behalf."
        },
        {
            title: "Consent Letters",
            content: "If your proposed mark is similar to an existing mark, you might need to obtain consent from the owner of the existing mark to proceed with registration. This is common in cases where there could be potential confusion between the marks."
        },
        {
            title: "Priority Claim Documents (if applicable)",
            content: "If you’re claiming priority based on an earlier application filed in another country under an international treaty like the Paris Convention, you’ll need to provide the relevant priority claim documents."
        },
        {
            title: "Foreign Language Translations",
            content: "If your application includes non-English elements, you might need to provide accurate translations of those elements."
        },
        {
            title: "Proof of Ownership of Prior Marks",
            content: "In some cases, if you’re relying on prior marks as a basis for registration, you might need to provide documentation proving your ownership of those marks."
        },
        {
            title: "Assignment Documents",
            content: "If you acquired the rights to the trademark from a previous owner, you might need to provide documents proving the assignment of those rights."
        },
        {
            title: "Statement of Use (if applicable)",
            content: "If you initially applied based on an intent to use the mark and your mark has been in use since then, you might need to provide a statement of use along with evidence of the actual use."
        },
        {
            title: "Notarization or Legalization",
            content: "Some jurisdictions require certain documents to be notarized or legalized to verify their authenticity."
        },
        {
            title: "Identification Documents",
            content: "Depending on the jurisdiction, you might need to provide identification documents for the applicant or individuals associated with the application."
        },
        {
            title: "Application Fees",
            content: "Most applications require payment of fees. The amount can vary depending on the jurisdiction and the type of application."
        },
        {
            title: "Trademark Government Fees",
            content: "Trademark government fees for 10 years are rupees 4500."
        }
    ];
    
    return (
        <div>
            <div className="my-4"><b>The specific documents required for Brand registration :</b></div>
            {trademarkApplicationRequirements?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg">{item.title}</div>
                    <div className="my-4">{item.content}</div>
                </div>
            ))}
        </div>
    )
}

