export const ISOOverview = () => {
    return (
        <div>
            <div className="font-bold text-lg">ISO (International Organization for Standardization)</div>
            <div className="my-3">as the name implies it’s an independent international organization, that develops International Standards, such as ISO 9001 and ISO 14001, but they are not involved in ISO certification and do not issue ISO certificates. External certification bodies in India perform the ISO certification process. In this article, we will look at the ISO Certification process in detail.</div>
        </div>
    )
}

export const ISOBenefit = () => {
    return (
        <div>
            <div className="my-3">A quick and cost-effective approach to becoming ISO certified. We offer different ISO certifications based on your company’s needs. Get ISO certification for your business with Legal Bharat Services</div>
        </div>
    )
}

export const ISORequirement = () => {

    let requirement = ['A certificate of incorporation', 'GST certificate, MSME certificate, or other proof of business is required', 'A letterhead or visiting card for the business', 'A sale and purchase invoice showing the nature of the business activity.']

    let more_req = [
        { title: 'Final audit', content: 'The registration cannot proceed until all significant nonconformities are closed and verified by the Registrar.  This usually involves a re-audit of the affected areas and, of course, the associated costs.\n\n\n Note: Minor nonconformities require a corrective action plan and that will be closed at the first surveillance.' },
        { title: 'Get ISO Certificate', content: 'After all, non-conformities are addressed, and all the findings are updated in the ISO audit report, the registrar will grant the ISO certification.' },
        { title: 'Surveillance Audits', content: 'A surveillance audit will be conducted primarily to ensure that the organization is maintaining ISO quality standards. It will be performed from time to time' },
    ]

    return (
        <div>
            <div className="my-3">The documents required for ISO certification differ based on the category of certification. The following are some of the required documents, which can be submitted online through our portal to get the ISO certificate.  uses the best-in-industry security protocols to ensure your documents are always secure.</div>
            <div className="ml-8 mt-4">
                <ul style={{ listStyle: 'number' }}>
                    {requirement?.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
            {more_req?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg mt-5">{item.title}</div>
                    <div className="my-4">{item.content}</div>
                </div>
            ))}
        </div>
    )
}

export const ISOTypes = () => {

    const isoStandards = [
        "ISO 9001:2008 - Quality Management System",
        "OHSAS 18001 – Occupational Health & Safety Management System",
        "ISO 37001 – Anti-bribery management systems",
        "ISO 31000 – Risk Management",
        "ISO 27001 – Information Security Management System",
        "ISO 10002 – Compliant Management System",
        "ISO 14001:2015 – Environment Management System",
        "ISO 26000 – Social Responsibility",
        "ISO 28000 – Security Management",
        "ISO 22000 – Food Safety Management",
        "SA 8000 – Social Accountability",
        "EnMS EN 16001 ISO 50001 – Energy Management",
        "ISO/IEC 17025 – Testing and calibration laboratories",
        "ISO 13485 – Medical devices",
        "ISO 639 – Language codes",
        "ISO 4217 – Currency codes",
        "ISO 3166 – Country codes",
        "ISO 8601 – Date and time format",
        "ISO 20121 – Sustainable events",
        "ISO/IEC 27001 – Information security management"
    ];
    

    return (
        <div>
            <div className="font-bold text-lg my-3">Selecting the Type of ISO Certification</div>
            <div>First of all, the entrepreneur needs to choose the type of ISO certification required for the business. There are various types of ISO certification available such as listed below:</div>
            <div className="ml-8 mt-4">
                <ul style={{ listStyle: 'number' }}>
                    {isoStandards?.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export const ISODocumentsRequired = () => {

    const organizationFactors = [
        "The nature of the organization’s activities, products, and services",
        "The level of complexity in business processes",
        "The nature of the activities",
        "Competence of persons"
    ];

    return (
        <div>
            <div className="font-bold text-lg my-3">The documentation process for ISO 9001 will vary depending on the size of your firm. Other factors that influence the documentation necessary include:</div>
            <div className="ml-8 mt-4">
                <ul style={{ listStyle: 'number' }}>
                    {organizationFactors?.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
        </div>
    )
}


