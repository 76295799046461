export const OnePersonOverview = () => {
    return (
        <div>
            <div className="font-bold text-lg">Overview of OPC Registration in India</div>
            <div className="my-5">
                Prior to the implementation of the Companies Act of 2013, only two people could form a company. The Companies Act of 2013 supports the formation of Person Companies (OPC) in India. It governs the registration and functioning of one person company in India. In comparison with a public company a private company should have at least two directors and two members however on the contrary, one person company doesn’t need any group of people to be incorporated.
            </div>
            <div>As per Section 262 of the Companies Act of 2013 and official registration of a one-person company in India is legal. Registering an OPC in India requires a single director and a single member representing the whole firm. This corporation type has very few compliance requirements in comparison with a private corporation.</div>
        </div>
    )
}

export const OnePersonBenefit = () => {

    const benefits = [
        { title: 'Legal Standing', content: 'The member grants the OPC a separate legal entity status. The sole person who incorporated the OPC is protected by its distinct legal status. The member is not personally liable for the company’s loss; instead, his or her liability is limited to the value of the shares that he or she owns. Therefore, the OPC and not the member or director may be sued by the creditors.' },
        { title: 'Easy Access to Funding', content: 'One person company in India can easily raise money through venture capital, angel investors, incubators, and other sources because it is a private company. Getting money is now simple.' },
        { title: 'Less Conformity', content: 'The One Person Company (OPC) is given some exemptions from compliance requirements under the Companies Act of 2013. The OPC is not required to prepare the cash flow statement. The secretary of the company is not required to provide any annual reports or maintain any account books.' },
        { title: 'Easy Integration', content: 'And one person company in India can be easily integrated without any legal hassles. A member also serving as a director should provide the approval for integration. There is no minimum paid-up capital requirement.' },
        { title: 'Easy to Manage', content: 'Administration of the OPC can be made simple by allowing a single person to both find and lead it. Making decisions is straightforward, and it happens quickly. The member can easily pass both ordinary and special resolutions by writing them down in the minute’s book and getting just one other member to sign them. Because there won’t be any internal disputes or delays, managing the company will be easy.' },
        { title: 'Constant Repetition', content: 'The OPC has the function of perpetual succession even with only one member. A nominee must be chosen by the single member when incorporating the OPC. The candidate will take over the operation of the company in the event that a member passes away.' },
    ]
    return (
        <div>
            <div className="font-bold text-xl my-5">Advantages Of OPC Company in India</div>
            {benefits?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg">{item.title}</div>
                    <div className="my-4">{item.content}</div>
                </div>
            ))}
        </div>
    )
}

export const OnePersonRequirement = () => {

    const req = ['Maximum and minimum membership requirements must be met','There should be a nominee chosen before incorporation','Use Form INC-3 to request the nominee’s approval','The Companies (Incorporation Rules) 2014 mandate that the OPC name be selected','The minimum authorised capital of ?1 Lakh','DSC of the potential director','Evidence of the OPC’s registered office.']

    return (
        <>
            <div className="font-bold text-xl my-5">Advantages Of OPC Company in India</div>
            <div className="ml-8 mt-4">
                <ul style={{ listStyle: 'number' }}>
                    {req?.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
        </>
    )
}

export const OnePersonTypes = () => {

    const benefits = [
        { title: 'Easy Succession', content: 'Despite having a single person running all the daily activities of the company, OPC provides options for perpetual succession. After the demise of a member of the company, the nominee can run the company.' },
        { title: 'Limited Liability', content: 'The member in a one-person company has limited liability. Since OPC is a registered company it is treated as a separate legal entity providing greater protection to its members. The liability of the member is limited to their shares so they are not liable for any losses conducted in the company. In case of bankruptcy, the creditors can sue the company and not the director of the company for procuring the company’s debt.' },
        { title: 'Sole Directorship and Shareholder', content: 'In a Person Company, a single member acts as a director so they stand liable for managing the company’s day-to-day activities. In this case, there is no need for an executive director to run the daily needs. A single member is more than sufficient and acts as a shareholder with all responsibilities.' },
        { title: 'Ownership in Property', content: 'Since the OPC is treated as a separate legal entity the person has the right to hold property related to business and other assets in their name. The properties including machinery factories, residential property, buildings, and other assets cannot be claimed by another person. As per law, the OPC can acquire property directly under its name.' },
    ]
    return (
        <div>
            <div className="font-bold text-xl my-5">Features of One Person Company</div>
            {benefits?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg">{item.title}</div>
                    <div className="my-4">{item.content}</div>
                </div>
            ))}
        </div>
    )
}

export const OnePersonDocuments = () => {

    const req = ['Address proof','Aadhaar card','PAN card','Photo','Email Id','Phone number']

    return (
        <>
            <div className="font-bold text-xl my-5">Document Required</div>
            <div className="ml-8 mt-4">
                <ul style={{ listStyle: 'disc' }}>
                    {req?.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
        </>
    )
}

