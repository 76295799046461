export const TrademarkObjectionOverview = () => {

    const trademarkObjectionsResponse = [
        {
            title: "Understand the Objections",
            content: "Carefully review the examination report to understand the specific objections raised by the trademark examiner. Common objections may include issues related to distinctiveness, similarity to existing trademarks, incorrect classification of goods/services, etc."
        },
        {
            title: "Legal Basis",
            content: "Craft your reply based on the relevant legal provisions and trademark laws that apply to the specific objections raised. Clearly explain how your trademark meets the required legal criteria for registration."
        },
        {
            title: "Evidence and Arguments",
            content: "Provide evidence and arguments to counter each objection raised. Include any relevant documentation, such as evidence of acquired distinctiveness, market surveys, consumer feedback, etc., to support your claims."
        },
        {
            title: "Amendments (if necessary)",
            content: "If the examiner suggests certain amendments to the application, consider whether they are acceptable and comply with the requirements. Make any necessary amendments to the application as requested."
        }
    ];


    return (
        <div>
            <div className="font-bold text-lg">Trademark Objection in India – an Overview
            </div>
            <div className="my-5">
                Trademarks are important intellectual property for business. In a year multiple trademarks are applied. Not all trademarks are accepted. Some trademarks are rejected and are not registered. Examiners search databases to find older trademarks that are identical to or confusingly similar to the mark under consideration. The trademark examination report summarises the results of the trademark examiner’s investigation and the reason for the trademark objection process.
            </div>
            <div className="my-5">Here are some essential points to consider when preparing a trademark objection reply:</div>
            <div className="my-5">
                {trademarkObjectionsResponse?.map((item, index) => (
                    <div key={index}>
                        <div className="font-bold text-lg">{item.title}</div>
                        <div className="my-4">{item.content}</div>
                    </div>
                ))}</div>
        </div>
    )
}

export const TrademarkObjectionBenefit = () => {

    const trademarkBenifitResponse = [
        {
            title: "Clearance for Registration",
            content: "Overcoming objections raised by the trademark examiner is a crucial step towards obtaining a trademark registration. By addressing the examiner’s concerns adequately, you increase the likelihood of your trademark being cleared for registration."
        },
    ];

    return (
        <div>
            <div className="my-5">
                The benefits of filing a well-prepared trademark objection reply are numerous and can have a significant impact on the successful registration of your trademark. Here are some key advantages:
            </div>
            <div className="my-5">Here are some essential points to consider when preparing a trademark objection reply:</div>
            <div className="my-5">
                {trademarkBenifitResponse?.map((item, index) => (
                    <div key={index}>
                        <div className="font-bold text-lg">{item.title}</div>
                        <div className="my-4">{item.content}</div>
                    </div>
                ))}</div>
        </div>
    )
}

export const TrademarkObjectionRequirement = () => {

    const trademarkApplicationErrors = [
        "Incorrect Name of the Trademark Applicant",
        "Incorrect Address on the Trademark Application",
        "Failure to file Trademark Form TM-48",
        "Filing of Incorrect Trademark Form",
        "Trademark filing under the Wrong Trademark Class",
        "The proposed Trademark already exists",
        "Trademark lacks distinctive character",
        "Vague Specifications of Goods and Services",
        "Deceptive Trademark",
        "User affidavit not attached"
    ];

    return (
        <div>
            <div className="font-bold text-xl my-5">Reasons For Trademark Objection</div>
            <div className="ml-8 mt-4">
                <ul style={{ listStyle: 'number' }}>
                    {trademarkApplicationErrors?.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export const TrademarkObjectionTypes = () => {

    const trademarkObjectionReasons = [
        {
            title: "Likelihood of Confusion",
            content: "The trademark examiner may raise an objection if your proposed mark is considered confusingly similar to an existing registered or pending trademark for similar goods or services. The examiner will assess factors such as the visual, phonetic, and conceptual similarities between the marks."
        },
        {
            title: "Descriptiveness",
            content: "If the proposed mark is considered descriptive, generic, or too common for the goods or services, the examiner may object to the registration. Trademarks need to be distinctive and capable of identifying the source of the goods or services."
        },
        {
            title: "Lack of Distinctiveness",
            content: "The examiner may object if the mark is found to be too ordinary, common, or lacking in distinctive character, making it difficult to distinguish it from other trademarks in the marketplace."
        },
        {
            title: "Deceptiveness or Misleading",
            content: "If the mark has the potential to deceive or mislead consumers about the nature, quality, or origin of the goods or services, the examiner may raise an objection."
        },
        {
            title: "Similarity to Well-Known Marks",
            content: "If the proposed mark is similar to a well-known or famous mark, even for unrelated goods or services, the examiner may object to prevent dilution or tarnishment of the well-known mark’s reputation."
        },
        {
            title: "Geographical Indications",
            content: "The examiner may object if the mark consists of a geographical indication that indicates a specific place of origin for certain goods (e.g., 'Champagne' for sparkling wine)."
        },
        {
            title: "Prohibited Marks",
            content: "Some jurisdictions have specific rules about certain types of marks that are prohibited from registration, such as marks that are offensive, immoral, or against public policy."
        },
        {
            title: "Incorrect Classification",
            content: "The examiner may object if the goods or services listed in the application are not properly classified or if there are errors in the classification."
        },
        {
            title: "Formalities",
            content: "Objections related to procedural or administrative requirements, such as missing documents or incomplete information in the application."
        }
    ];
    

    return (
        <div>
            <div className="my-5">
            However, some common <b>types of trademark objections</b> include:
            </div>
            <div className="my-5">
                {trademarkObjectionReasons?.map((item, index) => (
                    <div key={index}>
                        <div className="font-bold text-lg">{item.title}</div>
                        <div className="my-4">{item.content}</div>
                    </div>
                ))}</div>
        </div>
    )
}

export const TrademarkObjectionDocumentsRequired = () => {

    const trademarkObjectionResponseDocuments = [
        {
            title: "Trademark Objection Letter",
            content: "The objection letter or examination report issued by the trademark office containing the specific objections raised by the examiner."
        },
        {
            title: "Response Cover Letter",
            content: "A cover letter addressing the trademark office, explaining that the documents enclosed are in response to the objections raised in the examination report."
        },
        {
            title: "POA",
            content: "If you are represented by a trademark attorney or agent, a power of attorney authorizing them to act on your behalf in the trademark registration process."
        }
    ];
    
    return (
        <div>
            <div className="my-5">
            However, here are some common documents that may be needed:
            </div>
            <div className="my-5">
                {trademarkObjectionResponseDocuments?.map((item, index) => (
                    <div key={index}>
                        <div className="font-bold text-lg">{item.title}</div>
                        <div className="my-4">{item.content}</div>
                    </div>
                ))}</div>
        </div>
    )
}



