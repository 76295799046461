export const RenewalOverview = () => {

    const overview = [
        {
            title: "Eligibility for Renewal:",
            content: ['FSSAI licenses have a specified validity period, which can range from 1 to 5 years, depending on the type of license and the nature of the food business.', 'Renewal is necessary if you want to continue operating your food business legally and ensure ongoing compliance with food safety regulations']
        },
    ];


    return (
        <div>
            {overview?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg mt-5">{item.title}</div>
                    <div className="ml-8 mt-4">
                        <ul style={{ listStyle: 'disc' }}>
                            {item.content?.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
        </div>
    )
}

export const RenewalBenefit = () => {

    const benefits = [
        { title: 'Legal Compliance', content: 'Renewing your FSSAI license ensures that you continue to operate your food business legally. Operating without a valid FSSAI license can result in legal consequences, including fines and business closure.' },
        { title: 'Consumer Trust', content: 'Having a valid FSSAI license, and renewing it on time, demonstrates your commitment to food safety and quality. This helps build trust among consumers, who are more likely to choose products and services from businesses that adhere to food safety standards.' },
        { title: 'Enhanced Credibility', content: 'A renewed FSSAI license adds credibility to your food business. It reassures customers, suppliers, and partners that you are dedicated to maintaining high food safety and quality standards.' },
        { title: 'Market Expansion', content: 'A valid FSSAI license is often a prerequisite for expanding your business into new markets. Many retailers, distributors, and government procurement agencies require FSSAI-compliant products and services.' },
        { title: 'Business Opportunities', content: 'Renewing your FSSAI license opens up opportunities to participate in various food-related events, trade fairs, and collaborations. This can help you network, gain exposure, and explore new business avenues.' },
    ]

    return (
        <div>
            <div className="font-bold text-lg mb-3">Renewing your Food Safety and Standards Authority of India (FSSAI) license offers several benefits to food business operators (FBOs) and the food industry as a whole:</div>
            {benefits?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg">{item.title}</div>
                    <div className="my-4">{item.content}</div>
                </div>
            ))}
        </div>
    )
}

export const RenewalRequirement = () => {

    const requirement = [
        {
            title: "Legal Compliance",
            content: "Renewing your FSSAI license ensures that you continue to operate your food business legally. Operating without a valid FSSAI license can result in legal consequences, including fines and business closure."
        },
        {
            title: "Consumer Trust",
            content: "Having a valid FSSAI license, and renewing it on time, demonstrates your commitment to food safety and quality. This helps build trust among consumers, who are more likely to choose products and services from businesses that adhere to food safety standards."
        },
        {
            title: "Enhanced Credibility",
            content: " A renewed FSSAI license adds credibility to your food business. It reassures customers, suppliers, and partners that you are dedicated to maintaining high food safety and quality standards."
        },
        {
            title: "Market Expansion",
            content: "A valid FSSAI license is often a prerequisite for expanding your business into new markets. Many retailers, distributors, and government procurement agencies require FSSAI-compliant products and services."
        },
        {
            title: "Business Opportunities",
            content: "Renewing your FSSAI license opens up opportunities to participate in various food-related events, trade fairs, and collaborations. This can help you network, gain exposure, and explore new business avenues."
        },
    ];

    return (
        <div>
            {requirement?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg">{item.title}</div>
                    <div className="my-4">{item.content}</div>
                </div>
            ))}

            <div className="my-3 font-bold text-lg">Timing for Renewal:</div>
            <div>Renewal should be initiated well before your current FSSAI license expires to avoid any disruption in your business operations.
            </div>
        </div>
    )
}

export const RenewalTypes = () => {

    const overview = [
        {
            title: "FSSAI Basic License (Form A):",
            content: ['The FSSAI Basic License is typically for small-scale food businesses.', 'The renewal period for this license is generally 1 to 5 years, as decided by the FBO.']
        },
        {
            title: "FSSAI State License (Form B):",
            content: ['The FSSAI State License is for medium-sized food businesses operating within a single state.', 'The renewal period can vary, typically between 1 to 5 years, as determined by the FBO.']
        },
        {
            title: "FSSAI Central License (Form B):",
            content: ['The FSSAI Central License is for large-scale food businesses that operate in multiple states or have an annual turnover exceeding a specified limit.', 'The renewal period is generally between 1 to 5 years, decided by the FBO.']
        },
        {
            title: "FSSAI Registration Certificate (Form D):",
            content: ['The FSSAI Registration Certificate is for small-scale food businesses with an annual turnover below a certain threshold.', 'The renewal period for this certificate is typically 1 to 5 years, as selected by the FBO.']
        },
        {
            title: "FSSAI State and Central Licenses for Catering Businesses:",
            content: ['Catering businesses, such as those involved in providing meals on trains, in airplanes, etc., can obtain either a State or Central license based on their operations.', 'The renewal period for these licenses varies, typically between 1 to 5 years, as determined by the FBO.']
        },
        {
            title: "Importer FSSAI License:",
            content: ['This license is required for businesses that import food products into India.', 'The renewal period for importer licenses can vary but often ranges from 1 to 5 years, determined by the FBO.']
        },
        {
            title: "Exporter FSSAI License:",
            content: ['Exporter licenses are needed by businesses exporting food products from India to other countries.', 'The renewal period for exporter licenses can vary but is typically between 1 to 5 years, as selected by the FBO.']
        },
    ];


    return (
        <div>
            <div className="my-3 font-bold text-lg">Here are the main types of FSSAI licenses and their renewal categories:</div>
            {overview?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg mt-5">{item.title}</div>
                    <div className="ml-8 mt-4">
                        <ul style={{ listStyle: 'disc' }}>
                            {item.content?.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
        </div>
    )
}

export const RenewalDocument = () => {

    const documents_required = [
        {
            title: "FSSAI Basic License (Form A):",
            content: ['To renew your FSSAI license, you typically need to provide certain documents and information, which may include:', 'A completed and signed renewal application form (Form A/Form B).','A copy of your existing FSSAI license.','Personal identification documents of the food business operator (FBO).','Partnership Deed or Memorandum of Association/Articles of Association (MOA/AOA), if applicable.','A Food Safety Management System (FSMS) plan, if required.','Payment of the prescribed renewal fees.']
        },
    ];


    return (
        <div>
            <div className="my-3 font-bold text-lg">Here are the main types of FSSAI licenses and their renewal categories:</div>
            {documents_required?.map((item, index) => (
                <div key={index}>
                    <div className="font-bold text-lg mt-5">{item.title}</div>
                    <div className="ml-8 mt-4">
                        <ul style={{ listStyle: 'disc' }}>
                            {item.content?.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
        </div>
    )
}
