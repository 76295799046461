import React from 'react';
import { useLocation } from 'react-router-dom';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function transformPath(path) {
    return path
        .replace(/-/g, ' ')
        .split('/')
        .filter(Boolean)
        .map(word => capitalizeFirstLetter(word))
        .join(' ');
}

function OverviewContent({ image, content,OverViewImage }) {
    const location = useLocation();
    const pageTitle = transformPath(location.pathname);

    return (
        <>
            <h2 className="text-center font-bold text-4xl mt-24">{pageTitle} overview</h2>
            <div className="flex items-center flex-col md:flex-row mt-10">
                <div className="md:w-[50%] mb-6 md:mb-0 lg:mx-16 ">
                    <div className="md:m-16 lg:mx-24 lg:my-16 m-10 ">{content}</div>
                </div>
                <div className='md:w-[50%] m-10 flex justify-center'>
                    <img className='rounded-3xl' height={400} width={400} src={OverViewImage} alt={pageTitle} />
                </div>
            </div>

        </>
    );
}

export default OverviewContent;
