import React, { useState, useRef, useEffect } from "react";

function PageFaq({ list }) {
  const [openIndex, setOpenIndex] = useState(null);

  const AccordionItem = ({ index, title, content, isOpen, onClick }) => {
    const [height, setHeight] = useState("0px");
    const contentRef = useRef(null);

    useEffect(() => {
      if (isOpen) {
        setHeight(`${contentRef.current.scrollHeight}px`);
      } else {
        setHeight("0px");
      }
    }, [isOpen]);

    return (
      <div className="border mb-2 hover:bg-gray-50">
        <div
          className="p-4 cursor-pointer flex gap-5 items-center"
          onClick={() => onClick(index)}
        >
          <span className="text-red-600">{isOpen ? "−" : "+"}</span>
          <span className="text-md font-semibold hover:text-red-600">
            {title}
          </span>
        </div>
        <div
          ref={contentRef}
          style={{ maxHeight: height }}
          className="overflow-hidden transition-all duration-300 ease-in-out"
        >
          <div className="p-4 pt-0">
            <p dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      </div>
    );
  };

  const handleClick = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="md:px-10 lg:p-10 md:py-10 p-10">
      <h2 className="md:text-3xl text-2xl font-bold text-black text-center">
        FAQs
      </h2>
      <div className="mt-10 lg:mx-24 gap-4">
        {list?.map((item, index) => (
          <AccordionItem
            key={index}
            index={index}
            title={item.title}
            content={item.content}
            isOpen={openIndex === index}
            onClick={handleClick}
          />
        ))}
      </div>
    </div>
  );
}

export default PageFaq;
