import "./App.css";
import { NavLink, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Faqs from "./Pages/Faq/Faqs";
import ContactUs from "./Pages/ContactUs/ContactUs";
import AboutUs from "./Pages/AboutUs/AboutUs";
import TermsConditions from "./Pages/TermsConditions/TermsConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import RefundPolicy from "./Pages/RefundPolicy/RefundPolicy";
import Disclaimer from "./Pages/Disclaimer/Disclaimer";
import ScrollToTop from "./components/ScrollToTop";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { useEffect, useState } from "react";
import Sidebar from "./components/Sidebar";
import FssaiRegistration from "./Pages/License/FssaiRegistration";
import FssaiRenewal from "./Pages/License/FssaiRenewal";
import PrivateLimitedCompany from "./Pages/StartBusiness/PrivateLimitedCompany";
import LLPRegistration from "./Pages/StartBusiness/LLPRegistration";
import OnePersonCompany from "./Pages/StartBusiness/OnePersonCompany";
import NGORegistration from "./Pages/StartBusiness/NGORegistration";
import ISOCertificate from "./Pages/License/ISOCertificate";
import ImportExport from "./Pages/StartBusiness/ImportExport";
import TrademarkRegistration from "./Pages/Trademark/TrademarkRegistration";
import TrademarkObjection from "./Pages/Trademark/TrademarkObjection";
import TrademarkHearing from "./Pages/Trademark/TrademarkHearing";
import CopyrightRegistration from "./Pages/Trademark/CopyrightRegistration";
import GSTRegistration from "./Pages/GST/GSTRegistration";
import GSTReturnFiling from "./Pages/GST/GSTReturnFiling";
import whatsAppIcon from "./assets/images/whatsapp.png";
import telephoneIcon from "./assets/images/telephone.png";
import { AppendToast } from "./components/ToastMessage/ToastMessage";
import Thankyou from "./Pages/Thankyou";
import { useDispatch } from "react-redux";
import { API_END_POINT } from "./Config/config";
// import pagePaths from './Config/pagePaths';
import ChoosePlan from "./Pages/ChcekOut/ChoosePlan";
import Payment from "./Pages/ChcekOut/Payment";
import Receipt from "./Pages/ChcekOut/Receipt";
import { isPageEnabled, pagePaths } from "./Config/pagePaths";
import TrademarkOpposition from "./Pages/Trademark/TrademarkOpposition";
import IncomeTaxFiling from "./Pages/GST/IncomeTaxFiling";

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const dispatch = useDispatch();
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const getContent = async () => {
    await fetch(`${API_END_POINT}website/getContent.php`)
      .then((res) => res.json())
      .then((resJson) => {
        dispatch({
          type: "webData",
          state: resJson,
        });
      });
  };
  useEffect(() => {
    getContent();
  }, []);
  return (
    <div className="relative">
      <AppendToast />
      <ScrollToTop />
      <Navbar toggleSidebar={toggleSidebar} />
      <Routes>
        <Route path="/" element={<Home />} />
        {isPageEnabled.fssaiRegistration && <Route path={`/${pagePaths.fssaiRegistration}`} element={<FssaiRegistration />} />}
        {isPageEnabled.fssaiRenewal && <Route path={`/${pagePaths.fssaiRenewal}`} element={<FssaiRenewal />} />}
        {isPageEnabled.privateLimitedCompany && <Route path={`/${pagePaths.privateLimitedCompany}`} element={<PrivateLimitedCompany />} />}
        {isPageEnabled.llpRegistration && <Route path={`/${pagePaths.llpRegistration}`} element={<LLPRegistration />} />}
        {isPageEnabled.onePersonCompany && <Route path={`/${pagePaths.onePersonCompany}`} element={<OnePersonCompany />} />}
        {isPageEnabled.ngoRegistration && <Route path={`/${pagePaths.ngoRegistration}`} element={<NGORegistration />} />}
        {isPageEnabled.gstRegistration && <Route path={`/${pagePaths.gstRegistration}`} element={<GSTRegistration />} />}
        {isPageEnabled.gstReturnFiling && <Route path={`/${pagePaths.gstReturnFiling}`} element={<GSTReturnFiling />} />}
        {isPageEnabled.incomeTaxFiling && <Route path={`/${pagePaths.incomeTaxFiling}`} element={<IncomeTaxFiling />} />}
        {isPageEnabled.isoCertificate && <Route path={`/${pagePaths.isoCertificate}`} element={<ISOCertificate />} />}
        {isPageEnabled.importExport && <Route path={`/${pagePaths.importExport}`} element={<ImportExport />} />}
        {isPageEnabled.trademarkRegistration && <Route path={`/${pagePaths.trademarkRegistration}`} element={<TrademarkRegistration />} />}
        {isPageEnabled.trademarkObjection && <Route path={`/${pagePaths.trademarkObjection}`} element={<TrademarkObjection />} />}
        {isPageEnabled.trademarkHearing && <Route path={`/${pagePaths.trademarkHearing}`} element={<TrademarkHearing />} />}
        {isPageEnabled.trademarkOpposition && <Route path={`/${pagePaths.trademarkOpposition}`} element={<TrademarkOpposition />} />}
        {isPageEnabled.copyrightRegistration && <Route path={`/${pagePaths.copyrightRegistration}`} element={<CopyrightRegistration />} />}
        {isPageEnabled.contactUs && <Route path={`/${pagePaths.contactUs}`} element={<ContactUs />} />}
        {isPageEnabled.thankYou && <Route path={`/${pagePaths.thankYou}`} element={<Thankyou />} />}
        {isPageEnabled.choosePlan && <Route path={`/${pagePaths.choosePlan}/:leadId`} element={<ChoosePlan />} />}
        {isPageEnabled.payment && <Route path={`/${pagePaths.payment}/:leadId`} element={<Payment />} />}
        {isPageEnabled.receipt && <Route path={`/${pagePaths.receipt}/:leadId`} element={<Receipt />} />}
        {isPageEnabled.aboutUs && <Route path={`/${pagePaths.aboutUs}`} element={<AboutUs />} />}
        {isPageEnabled.termsConditions && <Route path={`/${pagePaths.termsConditions}`} element={<TermsConditions />} />}
        {isPageEnabled.privacyPolicy && <Route path={`/${pagePaths.privacyPolicy}`} element={<PrivacyPolicy />} />}
        {isPageEnabled.refundPolicy && <Route path={`/${pagePaths.refundPolicy}`} element={<RefundPolicy />} />}
        {isPageEnabled.disclaimer && <Route path={`/${pagePaths.disclaimer}`} element={<Disclaimer />} />}
      </Routes>
      <Footer />

      {sidebarOpen && (
        <div>
          <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
        </div>
      )}
      <div className="fixed bottom-5 right-4">
        <div>
          <a href="https://wa.me/+918240441937?text=Hi%2C%20I%20need%20FSSAI%20License">
            {" "}
            <img className="mb-2" src={whatsAppIcon} height={40} width={40} alt="chat with us" />
          </a>
          <a
            href="tel:+918240441937"
            className="text-white text-sm font-semibold"
          >
            <img src={telephoneIcon} height={40} width={40} alt="call Us" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
