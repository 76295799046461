import React from 'react'
import SampleLicense from '../../assets/images/Trademark_Certificate.jpg'

function TrademarkCertificate() {
  return (
    <div>
      
      <div className='md:p-10 p-10'>
        <h1 className='md:text-3xl text-2xl font-bold text-black text-center'>Sample Certificate</h1>
        <div className='mt-10 flex justify-center flex-wrap'>
            <img className='w-[100%] md:w-[70%] lg:w-[50%]' src={SampleLicense} />
        </div>
      </div>
    </div>
  )
}

export default TrademarkCertificate